import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import DonationForm from '../../donation/DonationForm';

function Donation() {
  return (
    <div>
      <Header></Header>
      <BreadCrumb
        title={'Donate'}
        image={'/uploads/2020/08/1024X569-webpage-banner-bihar-.jpg'}
        sub_title={'Campaigns'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <DonationForm />
              <div className='wpb_wrapper'>
                <h3> Offline Donation </h3>

                <div className='flex-wrapper box-shadow  box-wrapper mt-30 mb-30'>
                  <div className='flex-1'>
                    <h3>For Indian Citizens</h3>
                    <p>
                      <strong>Account Name:</strong> SEEDS
                      <br /> <strong>Bank Name: </strong>Standard Chartered Bank
                      <br /> <strong>Account Number:</strong> 52810165100
                      <br /> <strong>Account Type:</strong> Savings account
                      <br /> <strong>Bank Address: </strong>D-33 Defence Colony,
                      New Delhi-110024
                      <br /> <strong>Swift Code: </strong>SCBLINBBDEL
                      <br /> <strong>IFSC:</strong> SCBL0036032
                      <br /> <strong>Office Address: </strong>15-A,
                      Institutional Area, Sector IV, R.K. Puram, New Delhi –
                      110022
                    </p>
                  </div>
                  <div className='flex-1'>
                    <h3>By Cheque/Draft</h3>
                    <p>
                      Please make your cheque/draft in the name of{' '}
                      <strong> SEEDS </strong> and send it to 15-A,
                      Institutional Area, Sector IV, R.K. Puram, New Delhi –
                      110022
                    </p>
                  </div>
                  <div className='flex-1'>
                    <h3>For Foreign Nationals</h3>
                    <p>
                      <strong>Account Name: </strong>M/S Sustainable Environment
                      &amp; Ecological Development Society
                      <br /> <strong>Bank Name:</strong> State Bank of India
                      <br /> <strong>Account Number:</strong> 39764768555
                      <br /> <strong>Account Type:</strong> Savings account
                      <br /> <strong>Bank Address:</strong> State Bank of India,
                      New Delhi Main Branch, 11, Sansad Marg, New Delhi – 110001
                      <br /> <strong>Swift Code:</strong> SBININBB104
                      <br /> <strong>IFSC:</strong> SBIN0000691
                      <br /> <strong>Office Address:</strong> 15-A,
                      Institutional Area, Sector IV, R.K. Puram, New Delhi –
                      110022, India
                    </p>
                  </div>
                </div>
                <p>
                  {' '}
                  For any queries mail us at{' '}
                  <a href='mailto:partnership@seedsindia.org'>
                    {' '}
                    partnership@seedsindia.org{' '}
                  </a>
                </p>
                <hr />
                <h3>Other ways to support</h3>
                <div
                  className='flex-wrapper '
                  style={{ gap: '30px', alignItems: 'center' }}
                >
                  <div>
                    <a href='https://gpay.app.goo.gl/HGxm3Z'>
                      {' '}
                      <img
                        src='/assets/images/donation/Google-Pay.jpg'
                        style={{
                          width: '200px',
                          height: '70px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </div>
                  <div>
                    <a href='https://fundraisers.giveindia.org/nonprofits/seeds'>
                      {' '}
                      <img
                        src='/assets/images/donation/give-india-logo.png'
                        style={{
                          width: '200px',
                          height: '50px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </div>
                  <div>
                    <a href='https://www.every.org/seeds#/donate/card'>
                      {' '}
                      <img
                        src='/images/every_org.jpg'
                        style={{
                          width: '200px',
                          height: '40px',
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className='mt-30'>
                  <a
                    className='tp-btn tp-btn-shine-effect'
                    href='/past-emergencies/'
                  >
                    Past Emergencies
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Donation;
