import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function Policies() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Policies'}
        sub_title={'Policies'}
        image={'/uploads/2020/08/CRM-BOX.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='policy-section'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='wpb_wrapper'>
                  <p>
                    All of SEEDS’ programmes are governed by a strict set of
                    policies, that apply for our staff and extend to every
                    community with whom we work. These include policies on
                    complaint mechanisms, sexual harassment, core humanitarian
                    standards, finance manuals and more.
                  </p>
                  <p>
                    <a
                      href='/uploads/2020/07/SEEDS-Code-of-Conduct-Policy.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      SEEDS Code of Conduct
                    </a>
                    <br />{' '}
                    <a
                      href='/uploads/2020/08/SEEDS-CHS.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Core Humanitarian Standards
                    </a>
                    <br />{' '}
                    <a
                      href='/uploads/2020/07/SEEDS-Sexual-Harassment-Policy.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Sexual Harassment Policy
                    </a>
                    <br />{' '}
                    <a
                      href='/uploads/2021/07/Privacy-policy-website-updated-on-15th-Jul-2021.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    <strong>Complaint Response Mechanism </strong>
                  </p>
                  <p>
                    SEEDS takes all complaints very seriously. Employee and
                    volunteers, partners, vendors, programme participants, or
                    other outside parties interacting with SEEDS all have access
                    to a CRM system.
                  </p>
                  <p>
                    Any form of misconduct can be reported through CRM boxes in
                    our main offices and all programme locations which are
                    collected in the first week of each month. Members of the
                    committee can also be reached through phone or email if the
                    matter warrants immediate attention.&nbsp; All complaints
                    are investigated.
                  </p>
                  <p>
                    Ms Barsha Poricha, Presiding Officer –{' '}
                    <a href='mailto:bporicha@gmail.com'>bporicha@gmail.com</a> |
                    + 91 9811432088
                  </p>
                  <p>
                    Ms Lalita Menon Daikoku, Member –&nbsp;&nbsp;{' '}
                    <a href='mailto:lalitamenonesl@gmail.com'>
                      lalitamenonesl@gmail.com
                    </a>{' '}
                    | +91 9810011895
                  </p>
                  <p>
                    Mr Manu Gupta, Member –&nbsp;&nbsp;{' '}
                    <a href='mailto:manu@seedsindia.org'>manu@seedsindia.org</a>{' '}
                    | +91 9810211035
                  </p>

                  <p>&nbsp;</p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Policies;
