import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import BlogSection from '../../components/Home/BlogSection';
import { Helmet } from 'react-helmet';

function Blogs() {
  return (
    <div>
      <Helmet>
        <title>Blogs - SEEDS</title>
        <meta
          name='description'
          content='Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.'
        />

        <meta property='og:title' content={`Blogs - SEEDS`} />
        <meta
          property='og:description'
          content={`Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.`}
        />
        <meta
          property='og:image'
          content={`https://www.seedsindia.org/uploads/2020/06/Covidinnercover.jpg`}
        />
        <meta property='og:url' content={window.location.href} />
        <meta name='twitter:title' content={`Home - SEEDS`} />
        <meta
          name='twitter:description'
          content={`Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.`}
        />
        <meta
          name='twitter:image'
          content={`https://www.seedsindia.org/uploads/2020/06/Covidinnercover.jpg`}
        />
      </Helmet>
      <Header />
      <BreadCrumb title={'Blogs'} sub_title={'Blogs'} />
      <BlogSection />
      <Footer />
    </div>
  );
}

export default Blogs;
