import { Formik, Form } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FaXTwitter } from 'react-icons/fa6';

function Footer() {
  return (
    <>
      <footer className='bg-black'>
        <div className='footer-widget-area'>
          <div className='container'>
            <div classNamee='tpborder-bottom pb-35'>
              <div className='row'>
                <div className='col-xl-8 col-lg-8 col-md-8 '>
                  <div className='pt-3'>
                    <div className='tpfooter-widget '>
                      <div className='tpfooter-subscribe p-relative mb-20'>
                        {/* <iframe
                          frameBorder={0}
                          id='iframewin'
                          width='100%'
                          height='70px'
                          src='https://hpfr-zc1.maillist-manage.in/ua/Optin?od=1a1e3dae4a68d&zx=1df8eff5e7&tD=18e77a5a4237af9&sD=18e77a5a4559633'
                          style={{ height: '100px', overflow: 'hidden' }}
                        /> */}

                        {/* <Formik
                          initialValues={{
                            email: '',
                          }}
                          validationSchema={Yup.object({
                            email: Yup.string().email().required('Required'),
                          })}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            setSubmitting(true);

                            resetForm();
                            setSubmitting(false);
                          }}
                        >
                          {(formik) => {
                            console.log(formik);
                            return (
                              <Form>
                                <div className='newsletter-form'>
                                  <input
                                    type='email'
                                    placeholder=' Join our mailing list'
                                  />

                                  <button type='submit'>Subscribe</button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 '>
                  <div className='tpfooter__social   text-start pt-3'>
                    <a
                      href='https://www.facebook.com/SEEDS.IND'
                      target='_blank'
                    >
                      <i className='fa-brands fa-facebook-f' />
                    </a>
                    <a href='https://twitter.com/SeedsIndia' target='_blank'>
                      {/* <i className='fa-brands fa-twitter' /> */}
                      <FaXTwitter />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/seeds-india'
                      target='_blank'
                    >
                      <i className='fa-brands fa-linkedin-in' />
                    </a>
                    <a
                      href='https://www.youtube.com/user/SEEDSLearningMedia'
                      target='_blank'
                    >
                      <i className='fa-brands fa-youtube' />
                    </a>
                    <a
                      href='https://www.instagram.com/seeds_india/'
                      target='_blank'
                    >
                      <i className='fa-brands fa-instagram' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='  mb-20 mt-20'>
              <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4'>
                  <div className='footer-section'>
                    <h3>SEEDS</h3>
                    <p>
                      Born in 1994, SEEDS (Sustainable Environment and
                      Ecological Development Society) has one ultimate goal:
                      protecting the lives and livelihoods of people by
                      partnering with them to build their resilience to
                      disasters and climate change impacts.
                    </p>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 '>
                  <div>
                    <h3>Contact Us</h3>
                    <ul className='footer-contact-us'>
                      <li>
                        <i className='fa fa-map-marker'></i>
                        B-40, Second Floor, Qutub Institutional Area, <br /> New
                        Delhi - 110016, India
                      </li>
                      <li>
                        <i className='fa fa-envelope'></i> info@seedsindia.org
                      </li>
                      <li>
                        <i className='fa fa-phone'></i> +91-11- 26174272
                      </li>
                      {/* <li>
                        <i className='fa fa-fax'></i> +91-11-26174572
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 '>
                  <div>
                    <p>
                      For corporate and partnership related queries:{' '}
                      <a href='mailto:partnership@seedsindia.org'>
                        partnership@seedsindia.org
                      </a>
                    </p>

                    <p>
                      For queries around donation receipts:{' '}
                      <a href='mailto:accounts@seedsindia.org'>
                        accounts@seedsindia.org
                      </a>
                    </p>

                    <p>
                      For communications and media related queries:{' '}
                      <a href='mailto:communications@seedsindia.org'>
                        communications@seedsindia.org
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-copyright-area pb-2'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-8 col-lg-6 col-md-6'>
                <div className='tp-copyright'>
                  <p>
                    © {new Date().getFullYear()} SEEDS ALL RIGHTS RESERVED.{' '}
                  </p>
                </div>
              </div>
              <div className='col-xl-4 col-lg-6 col-md-6'>
                <div className='d-flex justify-content-md-end backtop'>
                  <div className='tp-backtop'>
                    <button
                      type='button'
                      className='back-to-top-btn'
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <span>Scroll Top</span>
                      <span>
                        <i className='fa-thin fa-arrow-up' />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
