import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
function NetajiSubhashBose() {
  return (
    <div>
      <Header></Header>
      <BreadCrumb
        title={'A New Feather In Our Cap'}
        image={'/uploads/2022/01/ambulance.jpg'}
        sub_title={'Campaigns'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div>
                <img
                  src='/uploads/2022/01/anshuawardreceived.jpg'
                  className='w-100'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <div class='wpb_wrapper'>
                <p>
                  SEEDS received the prestigious Subhash Chandra Bose Aapda
                  Prabhandhan Puraskar 2021 – a national award for the
                  excellence in Disaster Management. The award was presented by
                  Honourable Prime Minister of India, Shri Narendra Modi on the
                  125th birth anniversary of Netaji Subhash Chandra Bose in a
                  ceremony held on 23 January, 2022. The award was instituted by
                  Government of India to recognise and honour the invaluable
                  contribution and selfless service rendered by individuals and
                  organisations across India.
                </p>
                <p>
                  Enabling community resilience through practical solutions in
                  the areas of disaster readiness, response and rehabilitation
                  has been the primary focus of SEEDS.&nbsp;Since 1994, the
                  organisation has worked extensively on every major disaster in
                  the Indian subcontinent – grafting innovative technology on to
                  traditional wisdom. It has reached out to families affected by
                  disasters and climate stresses; strengthened and rebuilt
                  schools and homes; and has invariably put its faith in
                  skill-building, planning and communications to foster
                  long-term resilience.
                </p>
                <p>
                  Over last 2 decades, SEEDS reached several milestones in some
                  of the most difficult terrains and environment. We are a
                  humanitarian organisation with rich and diverse experience of
                  working with communities across the country and in the
                  Asia-Pacific region has some unique aspects that make it stand
                  out than others in the sector.
                </p>
                <p>
                  The organisation has developed strong capacity in handling
                  large scale interventions while upholding highest global
                  standards of quality and accountability. We have successfully
                  advocated resilience approaches, influenced the policy
                  environment globally, launched global action agendas, advised
                  inter-governmental agencies, UN, governments and civil
                  society. We have pioneered cutting edge approaches, supported
                  community innovations, created spaces on the interface of
                  high-end technology and community-based approaches. Affected
                  people have been the core of our approach. We have leveraged
                  their strengths, their traditions and their aspirations.
                </p>
                <p>
                  SEEDS is also India’s first agency to be certified for the
                  global Core Humanitarian Standards – an international
                  certification system for quality and accountability in
                  humanitarian response. We have responded to over 35
                  emergencies spanning across the length and breadth of the
                  country from the 2001 Gujarat earthquake, the 2004 India Ocean
                  Tsunami, the 2010 Ladakh flash flood, the 2014 Jammu and
                  Kashmir floods, the 2015 Gorkha earthquake&nbsp; (which took
                  us beyond Indian borders) to the 2018 Kerala floods, the 2019
                  Cyclone Fani, the 2020 Cyclone Amphan in West Bengal and
                  lately our extensive work during the pandemic – our resolution
                  to help create resilience in vulnerable communities has merely
                  grown stronger over the years.
                </p>
              </div>
            </div>
            <div className='col-md-4'>
              <img
                src='/uploads/2022/04/netaji-award-big1.jpg'
                className='w-100'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <iframe
                className='lazy-loaded'
                title='YouTube video player'
                data-lazy-type='iframe'
                data-src='https://www.youtube.com/embed/ir3_HUXZ0f4'
                width={'100%'}
                height={315}
                frameBorder={0}
                allowFullScreen='allowfullscreen'
                src='https://www.youtube.com/embed/ir3_HUXZ0f4'
              />
            </div>
            <div className='col-md-6'>
              <div class='wpb_wrapper'>
                <p>
                  A national award such as this one, not only boosts our morale
                  but also strengthens our resolve to move forward with iron
                  conviction. Our founders, Dr Manu Gupta and Dr Anshu Sharma,
                  have worked extensively on disaster risk and climate emergency
                  issues.&nbsp; They have been at the forefront advocating
                  resilience approaches, helping form environment policy
                  globally, launching global action agendas, advising
                  inter-governmental agencies, UN, governments and civil
                  society.
                </p>
                <p>
                  On receiving the award, our co-founders, Dr Manu Gupta and Dr
                  Anshu Sharma said, “We are humbled and honoured to&nbsp;
                  receive the Subhash Chandra Bose Aapda Prabandhan Puraskar
                  2021 from the Government of India. This award on the eve of
                  our 27th anniversary only strengthens our resolve to continue
                  our work with communities in strengthening disaster
                  resilience. The recognition of SEEDS efforts has underscored
                  the importance of bottom-up approaches ensuring agency of
                  affected communities and ensuring no one get left behind. Our
                  sincere gratitude goes to all the members of the SEEDS family,
                  our co-workers, donors, and friends with whom we share this
                  honour.”
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='wpb_wrapper'>
                <p>
                  <a href='https://pib.gov.in/PressReleasePage.aspx?PRID=1691607'>
                    Government Announcement
                  </a>
                </p>
              </div>
              <div className='wpb_wrapper'>
                <p>
                  <a
                    href='/uploads/2022/01/SEEDS-receives-SCBAPP-2021.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    SEEDS Release
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default NetajiSubhashBose;
