import React from 'react';
import Slider from 'react-slick';
import { TESTIMONIALS } from '../../domain/modals/testimonials';

function TestimonialSection() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    adoptiveHeight: true,
    dots: true,
    fade: true,
    arrows: false,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="far fa-arrow-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="far fa-arrow-right"></i></button>',
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='fix'>
      <div className=''>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10'>
              <div className='testimonial-space'>
                <div>
                  <Slider {...settings} className='testimonial-active '>
                    {TESTIMONIALS &&
                      TESTIMONIALS.map((item) => {
                        return (
                          <div>
                            <div className='tptestimonial__content'>
                              <div className='row'>
                                <div className='col-md-6'>
                                  <div className=' grayscale'>
                                    <img
                                      className='mx-auto d-block'
                                      src={item.image}
                                    />
                                  </div>
                                </div>
                                <div className='col-md-6'>
                                  <div className='tptestimonial__tlitle'>
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.testimonial,
                                        }}
                                      ></div>
                                      <div className='testimonials-author'>
                                        <em>{item.name}</em>
                                        <br />
                                        {item.designation}
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSection;
