import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function BankTransfer() {
  return (
    <div>
      <Header></Header>
      <BreadCrumb
        title={'Donate Through Bank Transfers'}
        image={'/uploads/2018/08/keralaflood7.1.jpg'}
        sub_title={'Campaigns'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='wpb_wrapper'>
                <h3>For Indian Citizens</h3>
                <p>
                  <strong>Account Name:</strong> SEEDS
                  <br /> <strong>Bank Name: </strong>Standard Chartered Bank
                  <br /> <strong>Account Number:</strong> 52810165100
                  <br /> <strong>Account Type:</strong> Savings account
                  <br /> <strong>Bank Address: </strong>D-33 Defence Colony, New
                  Delhi-110024
                  <br /> <strong>Swift Code: </strong>SCBLINBBDEL
                  <br /> <strong>IFSC:</strong> SCBL0036032
                  <br /> <strong>Office Address: </strong>15-A, Institutional
                  Area, Sector IV, R.K. Puram, New Delhi – 110022
                </p>
                <p>————————————————————————————————————–</p>
                <h3>For Foreign Nationals</h3>
                <p>
                  <strong>Account Name: </strong>M/S Sustainable Environment
                  &amp; Ecological Development Society
                  <br /> <strong>Bank Name:</strong> State Bank of India
                  <br /> <strong>Account Number:</strong> 39764768555
                  <br /> <strong>Account Type:</strong> Savings account
                  <br /> <strong>Bank Address:</strong> State Bank of India, New
                  Delhi Main Branch, 11, Sansad Marg, New Delhi – 110001
                  <br /> <strong>Swift Code:</strong> SBININBB104
                  <br /> <strong>IFSC:</strong> SBIN0000691
                  <br /> <strong>Office Address:</strong> 15-A, Institutional
                  Area, Sector IV, R.K. Puram, New Delhi – 110022, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BankTransfer;
