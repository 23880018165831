import React, { useEffect, useState } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import BlogSection from '../../components/Home/BlogSection';
import { useParams } from 'react-router-dom';
import { BLOGS } from './blogs_enums';
import { Helmet } from 'react-helmet';

function SingleBlog() {
  const params = useParams();
  const [activeBlog, setActiveBlog] = useState(null);
  useEffect(() => {
    if (params.slug) {
      const filteredData = BLOGS.filter((blog) => blog.slug === params.slug);
      if (filteredData.length > 0) {
        setActiveBlog(filteredData[0]);
      }
    }
  }, [params.slug]);

  return (
    <div>
      <Helmet>
        <title>{activeBlog?.title}</title>

        <meta property='og:title' content={activeBlog?.title} />
        <meta property='og:description' content={activeBlog?.title} />
        <meta
          property='og:image'
          content={`https://www.seedsindia.org${activeBlog?.featured_image}`}
        />
        <meta property='og:url' content={window.location.href} />
        <meta name='twitter:title' content={activeBlog?.title} />
        <meta name='twitter:description' content={activeBlog?.title} />
        <meta
          name='twitter:image'
          content={`https://www.seedsindia.org${activeBlog?.featured_image}`}
        />
      </Helmet>
      <Header />
      <BreadCrumb
        title={activeBlog?.title}
        sub_title={'Single Blogs'}
        image={activeBlog?.banner}
      />
      {activeBlog && (
        <div>
          <div className='blog-details-area pt-100 pb-130'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='blog-details-items'>
                    <div
                      className='blog-details-thumb'
                      style={{
                        // float: 'left',
                        // marginRight: '20px',
                        width: '100%',
                      }}
                    >
                      <img
                        src={activeBlog.featured_image}
                        style={{ width: '100%' }}
                      />
                    </div>

                    <div className='blog-details-content'>
                      <h3 className='blog-details-title'>{activeBlog.title}</h3>
                      <div className='blog-details-info d-flex mb-3'>
                        <div className='blog-details-name'>
                          <i className='fa-regular fa-circle-user' />
                          <span>{activeBlog.author}</span>
                        </div>
                        <div className='blog-details-date'>
                          <i className='fa-regular fa-clock' />
                          <span>{activeBlog.date}</span>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: activeBlog.content }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default SingleBlog;
