import React, { useState } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import ProjectCard from '../../components/Home/ProjectCard';
import { PRODUCT, TAGS } from '../../domain/modals/product';
import { Link } from 'react-router-dom';

function SeedsInAction() {
  const [activeTag, setActiveTag] = useState('ALL');
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'SEEDS in Action'}
        sub_title={'Seeds in Action'}
        image={'/uploads/2017/07/ski.png'}
      />
      <section
        className='tpblog-area action-page pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='tag-section'>
                <div
                  className='d-flex justify-content-center'
                  style={{ gap: '10px', flexWrap: 'wrap' }}
                >
                  <div>
                    <a
                      className={
                        activeTag === 'ALL'
                          ? 'tp-btn tp-btn-shine-effect'
                          : 'action-button'
                      }
                      onClick={() => setActiveTag('ALL')}
                    >
                      All
                    </a>
                  </div>
                  {TAGS &&
                    TAGS.map((item) => {
                      return (
                        <div>
                          <a
                            onClick={() => setActiveTag(item)}
                            className={
                              activeTag === item
                                ? 'tp-btn tp-btn-shine-effect'
                                : 'action-button'
                            }
                          >
                            {item}{' '}
                          </a>
                        </div>
                      );
                    })}
                  <div></div>
                </div>
              </div>
            </div>
            <div className='col-xl-12 col-lg-12'>
              <div className='tp-volunteers-2  mt-40'>
                <div className='row'>
                  {PRODUCT &&
                    PRODUCT.map((item) => {
                      if (activeTag === 'ALL') {
                        return (
                          <div className='col-md-3'>
                            <div className='mb-3 single-project'>
                              <div
                                className='tp-volunteers-2__img1  wow fadeInLeft grayscale'
                                data-wow-duration='1s'
                                data-wow-delay='.5s'
                              >
                                <img src={item.img} className='project-image' />
                                <div className='hover-overlay'>
                                  <Link to={`/portfolio/${item.slug}`}>
                                    <div
                                      className='d-flex hover-content justify-content-center align-items-end'
                                      style={{ height: '100%' }}
                                    >
                                      <div className='title'> {item.name} </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (
                        activeTag !== 'ALL' &&
                        item.tags &&
                        item.tags.includes(activeTag)
                      ) {
                        return (
                          <div className='col-md-3'>
                            <div className='mb-3 single-project'>
                              <div
                                className='tp-volunteers-2__img1  wow fadeInLeft grayscale'
                                data-wow-duration='1s'
                                data-wow-delay='.5s'
                              >
                                <img src={item.img} className='project-image' />
                                <div className='hover-overlay'>
                                  <Link to={`/portfolio/${item.slug}`}>
                                    <div
                                      className='d-flex hover-content justify-content-center align-items-end'
                                      style={{ height: '100%' }}
                                    >
                                      <div className='title'> {item.name} </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className='tp-volunteers-2 d-flex mt-40'></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SeedsInAction;
