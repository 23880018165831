import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

function Sasakawaaward() {
  return (
    <div id='wrapper'>
      <Header />
      <BreadCrumb title={'A Moment Of Triumph'} sub_title={'Our Story'} />
      <div className='tf-section section-about style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <img src='/uploads/2022/05/anumanusasaka.jpg' className='w-100' />
              <div className='pt-3 pb-3'>
                <p>
                  <em>
                    Masato Seko, Senior Program Director of Global Issues
                    Department, The Nippon Foundation, presents the UN Sasasaka
                    Award to Dr Manu Gupta and Dr Anshu Sharma.
                  </em>
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <img src='/uploads/2022/05/3.jpg' className='w-100' />
            </div>
            <div className='col-md-6'>
              <p>
                SEEDS was honoured with the United Nations Sasakawa Award for
                Disaster Risk Reduction during the 7th session of Global
                Platform for Disaster Risk Reduction, that was convened by the
                United Nations Office for Disaster Risk Reduction (UNDRR)
                between 23-28 May in Bali, Indonesia.
              </p>
              <p>
                The award is global recognition for the impact created through
                SEEDS’s people-centred approach and reinforces the
                organisation’s commitment to accountability towards stakeholders
                and global frameworks such as the Sendai Framework and SDGs.
                This also strengthens SEEDS Strategy 2030 goal to enable 315
                million people across 225 climate hotspots in India to survive,
                adapt, and thrive despite recurring disasters.
              </p>
              <p>
                “28 years ago, as young idealistic town planners sitting in our
                college canteen in a newly liberalised economy, we were
                concerned by the environmental impacts of what it would bring
                along. And it is by listening to the wisdom of the people that
                has helped shape our mission and purpose. I thank my dearest
                colleagues who spend their days and nights on the frontlines,
                engaging with the people. This award really goes to each one of
                them. Taking the opportunity of this platform, at SEEDS, we
                would like to call for stronger partnerships centered around
                agency of people as the driver for accelerated change” said Dr
                Manu Gupta, SEEDS on receiving the award.
              </p>
            </div>
            <div className='col-md-12'>
              <div class='wpb_wrapper'>
                <p>
                  In its journey over the last three decades, SEEDS has
                  continued to build resilience throughout South Asia by
                  partnering with governments as well as UN agencies to advocate
                  for adopting a multi-hazard approach. The organisation has
                  especially focused on promoting localisation agenda and
                  empowering local leaders, pushing for policy changes evidenced
                  by the impact on ground and building homes, safe learning
                  spaces, and healthcare facilities by combined local wisdom and
                  modern technology.
                </p>
                <p>
                  “A key tenet of UNDRR’s work is inclusivity. The Sendai
                  Framework calls for inclusive disaster risk reduction to be
                  integrated into planning, policy and funding. Unless we invest
                  in prevention and building resilience, we will not be able to
                  stop the spiral of disaster-response-recovery,” said Ricardo
                  Mena, Director, UNDRR at the United Nations Sasakawa Award
                  Ceremony on the efforts of the awardees.
                </p>
                <p>
                  The organisation’s core mission comprises helping communities
                  prepare for future risks, investing in community-based early
                  warning systems for first-mile connectivity, strengthening
                  roots with nature-based solutions and leveraging technology
                  for humanitarian action. has have been recognised through this
                  global honour award.
                </p>
                <p>
                  The United Nations Sasakawa Award for Disaster Risk Reduction
                  was created more than 30 years ago and is jointly organised by
                  the United Nations Office for Disaster Risk Reduction and the
                  Nippon Foundation. The central theme for the Sasakawa Award
                  2022 was “Building resilience through a multi-hazard
                  approach.”{' '}
                  <a
                    href='https://www.undrr.org/news/2022-sasakawa-award-winners-promote-inclusion-and-protection-vulnerable-people'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    SEEDS was among the six laureates of the 2022 United Nations
                    Sasakawa Award for Disaster Risk Reduction who have been
                    recognised for initiatives which protect vulnerable
                    communities from disaster risk
                  </a>
                  .
                </p>
                <p>
                  “All nominees had demonstrated great contributions,
                  achievements, and efforts towards a sustainable commitment for
                  Disaster Risk Reduction and Covid-19 recovery. We can clearly
                  see the impressive life practices from all the nominees,
                  missions, and projects, all being immensely relevant yet at
                  the same time grounded,” commented Nuraini Rahma Hanifa,
                  Sasakawa Award Jury Member, researcher at the Indonesian
                  National Research and Innovation Agency, and U-INSPIRE
                  Alliance Secretary-General.
                </p>
                <p>
                  <a
                    href='/uploads/2022/05/UN-Sasakawa-Award-2022-Press-Release.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Download Press Release
                  </a>
                </p>
              </div>
              <div>
                <iframe
                  width={'50%'}
                  height={315}
                  className='video-iframe'
                  src='https://www.youtube.com/embed/fOQJS2MxDk0'
                  title='YouTube video player'
                  frameBorder={0}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Sasakawaaward;
