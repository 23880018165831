export const BANNERS = [
  {
    image: `/images/campaigns/flood-response/banner.png`,
    image_mobile: `/images/campaigns/flood-response/banner.png`,

    title: `India Flood Response 2024`,
    sub_heading: `Urgent Relief Assistance for Vulnerable Communities`,
    link: `/india-flood-response-2024`,
    link_text: `Donate Now`,
  },
  // {
  //   image: `/assets/images/projects/2/banner-1.png`,
  //   image_mobile: `/assets/images/projects/2/banner-1.png`,

  //   title: `Sowing the SEEDS of Resilience: <br /> India Flood Response 2023`,
  //   sub_heading: `Extending hands to the most vulnerable people affected by floods`,
  //   link: `/sowing-the-seeds-of-hope-india-flood-response-2023`,
  //   link_text: `Donate Now`,
  // },
  // {
  //   image: `/assets/images/projects/1/Website-Banners-Rev_black.jpg`,
  //   image_mobile: `/assets/images/projects/1/Website-Banners-Rev_black.jpg`,

  //   title: `Rebuilding Hope: Extend Hands to <br /> Cyclone Biparjoy Affected Families`,
  //   sub_heading: `Standing strong with the people impacted by Cyclone Biparjoy`,
  //   link: `/rebuilding-hope-extend-hands-to-cyclone-biparjoy-affected-families`,
  //   link_text: `Donate Now`,
  // },
  // {
  //   image: `/assets/images/banners/Picture_1.jpg`,
  //   image_mobile: `/assets/images/banners/banner-1-moible.jpg`,

  //   title: ` Help Us make Our Communities <br /> resilient to Heatwaves`,
  //   sub_heading: `Building community resilience to heatwaves requires a comprehensive <br />  strategy that includes preparedness measures and community engagement`,
  //   link: `/making-communities-resilient-during-heatwaves`,
  //   link_text: `Donate Now`,
  // },
  {
    image: `/assets/images/banners/Sasakawa-award-image-touchup.jpg`,
    title: `SEEDS receives prestigious <br /> United Nations Sasakawa Award`,
    sub_heading: `United Nations Sasakawa award recognises SEEDS visionary <br /> leadership and unstoppable commitment to creating a better world. `,
    link: `/sasakawaaward2022`,
    link_text: `Read More`,
    image_mobile: `/assets/images/banners/banner-2-mobile.jpg`,
  },
  {
    image: `/assets/images/banners/Artificial-Intelligence-for-Building-Disaster-Resilient-Communities-1.jpg`,
    title: `Artificial intelligence for building <br /> disaster resilient community`,
    sub_heading: `Empowering communities through artificial intelligence to proactively  <br />mitigate disaster risks and enhance resilience. `,
    link: `/portfolio/ai-for-humanitarian-action-with-microsoft/`,
    link_text: `Know More`,
    image_mobile: `/assets/images/banners/banner-3-mobile.jpg`,
  },
  {
    image: `/assets/images/banners/seedsb.jpg`,
    title: `SEEDS climate change charter`,
    sub_heading: ` Make your voice heard and be a part of the movement towards <br /> a greener tomorrow with SEEDS.`,
    link: `/uploads/2021/11/SEEDS-Climate-Charter-Consultation-ver-101121_V1.pdf`,
    link_text: `Download Now`,
    image_mobile: `/assets/images/banners/banner-4-mobile.jpg`,
  },
];
