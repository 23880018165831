import React from 'react';

function ProjectSection() {
  return (
    <section className='volunteers-area-4 p-relative pb-50 pt-260'>
      <div
        className='volunteers-bg-4'
        style={{ background: 'url(/assets/img/about/map-volunteers.png)' }}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-xl-5 col-lg-5 col-md-12'>
            <div
              className='volunteers-section-4 wow fadeInLeft'
              data-wow-duration='1s'
              data-wow-delay='.5s'
            >
              <div className='tp-section-title-wrapper'>
                <h3 className='section-title '>Leaving a Mark</h3>
              </div>
              <p>
                Our work over the years has reached a broad cross-section of
                people. We’ve sparked hope through innovative buildings,
                nature-based solutions, risk-ready skilling, safer childhood
                across India and Nepal.
              </p>
            </div>
          </div>
          <div className='col-xl-7 col-lg-7'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={15}
                        className='purecounter'
                      >
                        75,35,666
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    {/* <p className='tp-fact__title'>People</p> <br /> */}
                    <p className='tp-fact__subtitle'>
                      Total Lives <br /> Touched
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={15}
                        className='purecounter'
                      >
                        3,16,746
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    {/* <p className='tp-fact__title'>People</p> <br /> */}
                    <p className='tp-fact__subtitle'>
                      School Children <br /> Trained
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={15}
                        className='purecounter'
                      >
                        662
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    {/* <p className='tp-fact__title'>People</p> <br /> */}
                    <p className='tp-fact__subtitle'>
                      Schools & Aanganwadis Built, Repaired & Restored
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={145}
                        className='purecounter'
                      >
                        63,116
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    <p className='tp-fact__subtitle'>
                      Shelters <br /> Built
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={50}
                        className='purecounter'
                      >
                        47
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    <p className='tp-fact__subtitle'>
                      Emergencies <br /> Responded
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
                <div className='tp-fact d-flex flex-column justify-content-center align-items-center'>
                  <div className='tp-fact__visit'>
                    <h3>
                      <span
                        data-purecounter-duration={1}
                        data-purecounter-end={50}
                        className='purecounter'
                      >
                        25
                      </span>
                    </h3>
                  </div>
                  <div className='tp-fact__text'>
                    <p className='tp-fact__subtitle'>
                      Indian States <br /> UTs Covered
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSection;
