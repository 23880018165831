import React from 'react';
import Header from '../../components/common/Header';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Slider from 'react-slick';

function AIForHumantarian() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'‘AI For Humanitarian Action’ With Microsoft'}
        image={'/uploads/2022/05/2-1100x564.png'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='portfolio-image'>
                <img src={'/uploads/2022/05/2-1100x564.png'} />
              </div>
              <div
                className='d-flex '
                style={{ gap: '10px', marginTop: '20px' }}
              >
                <div
                  style={{
                    border: '1px solid #f1f1f1',
                    padding: '10px 10px ',
                    background: '#f1f1f1',
                  }}
                >
                  Reducing Future Risk
                </div>
                <div
                  style={{
                    border: '1px solid #f1f1f1',
                    padding: '10px 10px ',
                    background: '#f1f1f1',
                  }}
                >
                  Research & Advocacy
                </div>
              </div>
              <div></div>
              <div className='portfolio-content'>
                <div className='main-title'>
                  <h3 className='mb-30'>AI powered disaster impact model</h3>
                </div>
                <div className='video-section'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <iframe
                        className='lazy-loaded'
                        title='YouTube video player'
                        data-lazy-type='iframe'
                        data-src='https://www.youtube.com/embed/JiTr6IF5MoE'
                        width='560'
                        height='315'
                        frameborder='0'
                        allowfullscreen='allowfullscreen'
                        src='https://www.youtube.com/embed/JiTr6IF5MoE'
                      ></iframe>
                    </div>
                    <div className='col-md-6'>
                      <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/hdw1dThZzT0'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className='content-section'>
                  <h4>Context</h4>
                  <p>
                    IPCC code red has repeatedly prompted governments at
                    international, national and state levels to urgently develop
                    climate change adaptation and mitigation pathways, making it
                    an important aspect of the current development discourses.
                    There is also an understanding pledged by world leaders at
                    COP28 in Expo City, Dubai, that the financing for climate
                    must be substantially increased. While the science,
                    investment and activism part grow on one side, conversion of
                    these facts, resources, and voices into context specific
                    solutions at scale remains hard. In urban areas, this gap is
                    starker. Urban areas harbour a multitude of complexities in
                    terms of livelihoods, populations, inequalities, governance,
                    thus making it tricky to detect and understand climate
                    induced disaster risks. Deciphering risks is also difficult
                    with the current hazard warnings as they generalise
                    information for complete districts or cities at best. Thus,
                    leaving neighbourhoods with little information about their
                    specific vulnerabilities that is needed in order to adapt to
                    the amplifying risks, making informed pre- emptive action by
                    individuals/local communities almost impossible.
                  </p>
                  <h4>Need</h4>
                  <p>
                    There is a need to collate hazard risk information at a
                    neighbourhood level to get clarity on the driving factors as
                    risk is a culmination of hazard, vulnerability and exposure.
                    Its experience varies with the coping capacity of the
                    family, structural strength of the building and other
                    factors such as slope, elevation and built-up density of the
                    neighbourhood. The available risk information should not
                    only be localised, but needs to be made easily decipherable
                    for at-risk communities and other actors involved in
                    disaster response and planning.
                  </p>
                  <h4>Our Solution</h4>
                  <p>
                    There is a need to collate hazard risk information at a
                    neighbourhood level to get clarity on the driving factors as
                    risk is a culmination of hazard, vulnerability and exposure.
                    Its experience varies with the coping capacity of the
                    family, structural strength of the building and other
                    factors such as slope, elevation and built-up density of the
                    neighbourhood. The available risk information should not
                    only be localised, but needs to be made easily decipherable
                    for at-risk communities and other actors involved in
                    disaster response and planning.
                  </p>
                  <h4>Impact</h4>
                  <p>
                    The model was piloted for risk assessment of cyclone induced
                    flooding during cyclone Nivar and cyclone Burevi in 2020 and
                    showcased promising results. In 2021, we scaled the
                    dissemination to reach out to over 50,000 families
                    vulnerable to heatwaves (Delhi and Nagpur), cyclones (parts
                    of Odisha and Gujarat) and monsoon flooding (Puri and
                    Mumbai). During cyclone Yaas, we deployed the model for
                    coastal parts of Puri and shared advisories and pre-emptive
                    measures with over thousand vulnerable families, helping
                    reduce their losses. Through the years, the model has been
                    refined and deployed to several locales and communities for
                    heat waves, excessive rainfall, and flooding in Delhi,
                    Gujarat, and Bihar.
                  </p>
                  <h4>Way forward</h4>
                  <p>
                    As the model continues to evolve, the deployment will be
                    scaled for multiple cities across the country. We are also
                    looking to collaborate with various city and state
                    governments, to aid them in developing risk informed
                    planning. Our vision for the model is to use it for climate
                    change adaptation and disaster management in a way that the
                    hyper-local risk of the communities is understood and
                    pathways for their protection and resilience are put into
                    practice.
                  </p>
                </div>
                <div className='links-section'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p>
                        <strong>
                          Every roof is a unique fingerprint, SEEDS and Trevor
                          Noah in ThePrompt!
                        </strong>
                      </p>

                      <iframe
                        class='lazy-loaded'
                        title='YouTube video player'
                        data-lazy-type='iframe'
                        data-src='https://www.youtube.com/embed/Cyer0KRaFZo'
                        width='560'
                        height='315'
                        frameborder='0'
                        allowfullscreen='allowfullscreen'
                        src='https://www.youtube.com/embed/Cyer0KRaFZo'
                      ></iframe>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>
                          A short video outlining our journey behind developing
                          the ‘Sunny Lives AI Model’
                        </strong>
                      </p>

                      <iframe
                        class='lazy-loaded'
                        title='YouTube video player'
                        data-lazy-type='iframe'
                        data-src='https://www.youtube.com/embed/yhG6XT_becs'
                        width='560'
                        height='315'
                        frameborder='0'
                        allowfullscreen='allowfullscreen'
                        src='https://www.youtube.com/embed/yhG6XT_becs'
                      ></iframe>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        {' '}
                        <strong>Selected coverage on the project:</strong>{' '}
                      </p>
                      <ul>
                        <li>
                          <a href='https://www.hindustantimes.com/opinion/policies-and-people-how-ai-is-helping-india-s-poor-become-climateresilient-101663847864017.html?fbclid=IwAR15I1N38dVutWYDzi6AHMVl57RpEssDjZODLXdgxOLrJXmQ70V1d02Dgcc'>
                            Policies and People | How AI is helping India’s poor
                            become climate-resilient
                          </a>
                        </li>
                        <li>
                          <a href='https://www.moneycontrol.com/news/trends/features/disaster-management-expert-anshu-sharma-for-us-the-rooftop-is-a-hidden-labelled-data-set-9225601.html?fbclid=IwAR3cnjGwBZrMc9sHtt32PvzOwLy4UwR4PwHeYXDAHiEDx6Xgt0VlO-0CW0M'>
                            Disaster management expert Anshu Sharma: ‘For us the
                            rooftop is a hidden labelled data set’
                          </a>
                        </li>
                        <li>
                          <a href='https://www.youtube.com/watch?v=yJb2yyKwEsw'>
                            Microsoft AI for Humanitarian Action grant: SEEDS
                            India
                          </a>
                        </li>
                        <li>
                          <a href='https://timesofindia.indiatimes.com/business/india-business/how-ai-is-being-used-to-protect-people-from-cyclones/articleshow/87149453.cms'>
                            How AI is being used to protect people from cyclones
                            | Times of India
                          </a>
                        </li>
                        <li>
                          <a href='https://www.thehindu.com/sci-tech/technology/microsoft-along-with-seeds-launch-2nd-phase-of-ai-model-to-predict-heat-waves-risks-in-india/article35555104.ece#:~:text=%22Phase%20II%20begins%20with%20model,warnings%20in%202021%2C%20it%20said'>
                            Microsoft along with SEEDS launch 2nd phase of AI
                            model to predict heat waves risks in India | The
                            Hindu
                          </a>
                        </li>
                        <li>
                          <a href='https://gadgets.ndtv.com/science/features/climate-change-crisis-ai-seeds-sunny-lives-microsoft-salesforce-ibm-cop26-2597041'>
                            Can AI-Based Solutions Help Mitigate the Climate
                            Change Crisis? | NDTV Gadgets
                          </a>
                        </li>
                        <li>
                          <a href='https://unlocked.microsoft.com/seeds-india/'>
                            SEEDS is using Microsoft AI to make climate change
                            threats visible for the most vulnerable
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AIForHumantarian;
