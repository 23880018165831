import React from 'react';

function Accountability() {
  return (
    <section
      className='tpblog-area pt-50 pb-50'
      style={{ backgroundColor: '#fff' }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-7 '>
            <div className='accountability-section'>
              <div className='tp-section-title-wrapper'>
                {/* <span className='section-sub-title has-before'> SEEDS</span> */}
                <h3 className='section-title '>Accountability</h3>
              </div>
              <p>
                Affected people at the centre. A commitment to quality and core
                humanitarian standards. Sustainability at every step. Long-term
                thinking. Local action with global influence. These are the
                values that drive all of our work.
              </p>
              <p>
                We hold ourselves accountable to our supporters, partners and
                the communities that we serve.
              </p>
              <div className='accountability-btn'>
                <a href='/ways-of-working/'>Ways of working</a>
                <a href='/financials/'>Financials</a>
                <a href='/annual-reports/'>Annual Reports</a>
                <a href='/testimonials/'>Testimonials</a>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='grayscale'>
              <img
                src='/assets/img/about-section/about-1.jpg'
                alt='SEEDS'
                style={{ height: '300px', objectFit: 'cover', width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Accountability;
