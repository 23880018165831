export const Board = [
  {
    profile: `/assets/images/teams/vikasGoswami.jpg`,
    name: 'Dr. Vikas Goswami',
    position: 'President',
    linkedin: 'https://www.linkedin.com/in/vikasgosain/',
    details: `  <p>
    Vikas is a voracious reader, thinker and analyst. With
    qualifications in development management, anthropology and
    sustainability studies and a three-decades career split
    across the development sector and the corporate world, Vikas
    has unique insights on resources that can be tapped and
    processes that can be deployed to help those in need. Over
    the last fifteen years she has headed CSR at Microsoft,
    Indus Towers, Vedanta Resources and Godrej. Prior to that
    she has served as Country Director of the Global Alliance of
    Workers and Communities, Director General of the Business
    and Community Foundation, Indian Professional Officer at
    DFID. She was a contributing author of the Business
    Responsibility Report framework for the Securities and
    Exchange Board of India and the National Sustainability
    Guidelines for the Ministry of Corporate Affairs, Government
    of India. She was leading member of the Task Force on
    Business Responsibility instituted by Planning Commission of
    India. She guides SEEDS on organisational development,
    sustainability and the use of technology to create green
    jobs. She brings a very high level of energy and out of the
    box thinking to our leadership team.
  </p>
  <p>
    Vikas is an alumnus of Delhi University, University of Wales
    and Harvard University.
  </p>
  <p>
    She currently heads the Consulting Division at Boundless
    Environment Resource Solutions Private Limited.
  </p>
  <p>Vikas chairs the Governing Board of SEEDS.</p>`,
  },
  {
    profile: `/assets/images/teams/DRMANU.jpg`,
    name: 'Dr. Manu Gupta',
    position: 'Vice President',
    linkedin: 'https://www.linkedin.com/in/manu-seeds/',
    twitter: 'https://twitter.com/manu_seeds',
    details: `  <p>
    Manu’s fight in life is for an ‘all of society’ approach in disaster management. In his over two and a half decades of experience he has worked hands-on in several disaster situations, aiding affected communities recover, rebuild and mitigate future risks. A Senior Ashoka Fellow, he is known to be at the forefront of championing citizen-led climate and disaster resilience. He sits on the boards of several global and regional organisations, including the Asian Disaster Reduction and Response Network (ADRRN), an active network of NGOs from over 20 countries in Asia and NEAR, a global NGO network. Manu has served on various national committees of the Government of India, is currently a Member of the Standing Committee on Civil Society Organisations at the NITI Aayog, and has co-founded the Owner Driven Reconstruction Collaborative, which has worked on several large-scale post disaster reconstruction programmes in India and Nepal. On a normal day you will find him engaged in intense deliberations with sector leaders on how to strengthen community resilience, or in a deep dive with the SEEDS leadership on how to make it a better organisation.

</p>
<p>Manu is an alumnus of the School of Planning and Architecture, Delhi, and Kyoto University, Japan.

</p>
<p>He is a Co-founder of SEEDS.

</p>
`,
  },
  {
    profile: `/assets/images/teams/drbarsha.jpg`,
    name: 'Dr. Barsha Poricha',
    position: 'Director',
    linkedin: 'https://www.linkedin.com/in/barsha-poricha-93ab2137/',

    details: `<p>Barsha champions the cause of deepening democracy, particularly in underserved urban communities. She defines this as a process driven approach to enabling the underprivileged for not just participating in, but also taking decisions on matters related to their development. With qualifications in sociology, social security, and urban and regional planning, and an experience of two and a half decades in the urban community development space, she brings a unique wealth of knowledge and experience. Barsha has served as Director, National Foundation for India, where in an intermediate grant making role, she has seen both fundraising and grant making functions at close quarters. With a specific focus on supporting small grassroots organisations, she has honed her skills on spotting invisible needs as well as local potential and catalysing small change that makes deep impact. Barsha is highly passionate about books and can hold riveting conversations that provoke thoughts on equality, development and the civil society space. She helps SEEDS understand this landscape and find a way through it that identifies nuanced needs and helps fulfil them with sensitivity.
    </p>
    <p>Barsha is an alumnus of Delhi School of Economics, Centre of Environmental Planning and Technology Ahmedabad, Erasmus University Rotterdam and Ambedkar University.
    </p>
    <p>She is currently Deputy Technical Cell Head at the Centre for Urban and Regional Excellence.
    </p>
    `,
  },
  {
    profile: `/assets/images/teams/AsnhuSharma.jpg`,
    name: 'Dr. Anshu Sharma',
    position: 'Secretary',
    linkedin: 'https://www.linkedin.com/in/anshu-seeds/',
    twitter: 'https://twitter.com/anshu_seeds',
    details: `<p>Anshu a technology geek at heart, with a social mission in life. He believes that technology must be made to work for the vulnerable and needy and must serve and enhance their traditional wisdom. Since joining the profession in the mid-nineties, he has worked in over ten countries responding to emergencies, training practitioners, coordinating inter-agency efforts, conducting research, advocating with governments and international agencies, and evaluating disaster management programmes. He is most comfortable in academic environments, or tinkering with community tech, and hence will be found in his elements in a partner university campus or in some remote community figuring out how to make their vernacular buildings perform better. He has been a consultant to various UN agencies, the World Bank, INGOs and has lectured at universities across India and at Kyoto, Tohuku, Harvard, MIT, Oxford Brookes and the University of New South Wales. He has served as Advisor to the Government of Nepal, on deputation from Government of India for post disaster assessment and recovery planning. He is currently on the NITI Aayog Committee of Experts for Planning and Architectural Framework for Hill Regions.
    </p> 
    <p>Anshu is an alumnus of the School of Planning and Architecture, Delhi, and Kyoto University, Japan.
    </p>
    <p>He is a Co-founder of SEEDS.
    </p>
    `,
  },

  {
    profile: `/assets/images/teams/Drrajesh.jpg`,
    name: 'Mr. Rajesh Bheda',
    position: 'Member',
    linkedin: 'https://www.linkedin.com/in/dr-rajesh-bheda-273782a/',

    details: `<p>Rajesh is globally renowned productivity guru in the garment industry. He served as a Professor at the National Institute of Fashion Technology, Delhi, where he was Chairperson of the Garment Manufacturing Technology department. His book, Managing Productivity in the Apparel Industry, is an internationally acclaimed publication that inspires factories to make small changes that bring about big savings and bump up profits. His firm, Rajesh Bheda Consulting (RBC), is management consulting organisation focused on enhancing competitiveness of fashion and lifestyle industry organisations.
    </p>
  <p>The best names in the fashion business internationally partnering with RBC to promote performance improvement strategies in their organisations and supply chains. These include leading apparel manufacturers, sourcing companies, brands and retailers, industry associations, Government and UN Agencies. The consulting interventions of RBC have resulted in significant performance enhancement among over 300 organisations internationally. Rajesh brings the value of productivity and efficiency to the working of SEEDS and mentors our programme team to establish supply chains and deliver aid with zero wastage and maximum impact.
  </p>
  <p>Rajesh is an alumnus of National Institute of Fashion Technology Delhi, The Manchester Polytechnic University, and Faculty of Management Studies Delhi.
  </p>
  <p>
  He is the Principal and CEO of Rajesh Bheda Consulting (RBC).
  </p>
    `,
  },
  {
    profile: `/assets/images/teams/AslamPerwai.jpg`,
    name: 'Mr Aslam Perwaiz',
    position: 'Member',
    linkedin: 'https://www.linkedin.com/in/aslam-perwaiz-44793032/',

    details: `<p>Aslam Perwaiz is an idea-driven strategist known for his leadership and technical expertise in sustainable development, disaster risk reduction, and climate resilience. He has over 30 years of experience across more than 20 countries, particularly in the Asia-Pacific region.

</p>
<p>He excels in institutional strengthening, research, and knowledge management.



</p>
<p>With practical understanding of the disaster risk management systems and climate change issues in international, regional with hands-on experience in strengthening institutions, and individuals, Aslam represents and contribute regularly to the global and regional disaster and climate discourses, particularly the post-2015 international frameworks such as SDG, SFDRR, UNFCCC. He is Member of the Asia Pacific Scientific and Technical Advisory Group (AP-STAG) and also serves as the President of the Executive Committee of L'Oréal Climate Emergency Funds.    



</p>
<p>Aslam is an alumnus of Aligarh Muslim University, India.
</p>
<p>He is currently the Deputy Executive Director of the Asian Disaster Preparedness Center (ADPC).
</p>
    `,
  },
  {
    profile: `/assets/images/teams/lalita.jpg`,
    name: 'Dr. Lalita Menon Daikoku',
    position: 'Member',
    details: `<p>Lalita is a person of peace and values. For close to two decades, she has been a keen advocate of spreading the message of peace, culture and education globally. With a Masters in Gandhian Thought and a PhD in Peace Studies, she has been working with organisations such as Sarvodya International Trust to promote the ideals of truth, non-violence and humanitarian service. As a guest lecturer at Delhi University, she has delivered talks on the life-transforming philosophy of Mahatma Gandhi, Dr. Daisaku Ikeda and Martin Luther King Jr. She practices and advocates the application of this philosophy in daily life. She is an accomplished English Language Trainer, enabling people from diverse global backgrounds to master the English language, ace effective communication and nurture a genuine passion for learning. Mentoring, teaching and empowering people to bring out their innate potential aptly sum up Lalita’s mission and work. Lalita is the conscience keeper of SEEDS and helps the leadership team uphold our value system across all facets of our work and all levels of our partnerships.</p>
    `,
  },
];
