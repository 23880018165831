import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { REPORTS } from '../../domain/modals/reports';

function AnnualReports() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Annual Reports'}
        sub_title={'Annual Reports'}
        image={'/uploads/2021/02/Annual-Report-page-cover-image.jpg'}
      />
      <section
        className='tpblog-area policy-section  pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='report-box'>
            <div className='row'>
              <div className='col-md-6'>
                <a href='/assets/img/reports/report2021-2022.pdf'>
                  <img
                    src='/assets/img/reports/2021-2022.jpg'
                    className='w-100'
                  />
                </a>
              </div>
              <div className='col-md-6'>
                <h3>Annual Report 2021 - 2022</h3>
                <p>
                  While 2021, started with a ray of hope as new vaccines to
                  check the spread and fatality of the COVID-19 virus were made
                  readily available by the Government of India, newer
                  coronavirus variants stretched one of the worst pandemics in
                  history into a second deadly year. Like organisations across
                  the world, we suffered the twin impacts of the pandemic as
                  well as disruptions to our programmes in 2021.
                </p>
                <div>
                  <a href='/assets/img/reports/report2021-2022.pdf'>Download</a>
                </div>
              </div>
            </div>
          </div>
          <div className='report-box'>
            <div className='row'>
              <div className='col-md-6'>
                <a href='/assets/images/reports/AnnualReport-2020-2021.pdf'>
                  <img
                    src='/assets/images/reports/20-21-1.jpg'
                    className='w-100'
                  />
                </a>
              </div>
              <div className='col-md-6'>
                <h3>Annual Report 2020 - 2021</h3>
                <p>
                  The year 2020 was marked by overlapping crises, as the same
                  restrictions which kept us safe from COVID-19 pushed millions
                  into poverty and displacement. The strongest cyclonic storm
                  ever recorded in the Bay of Bengal, Cyclone Amphan, hit the
                  eastern regions, while the longest flood inundated the
                  southern regions. Health, economy and education all stood
                  upended by uncertainties. The ongoing pandemic, climate change
                  and rising inequalities have marked the start of a new decade,
                  but SEEDS armed with the strength it derives from the people
                  it has reached, the unbending determination of its workforce
                  and unwavering support of its partners, aims to tackle the
                  challenges that emerge in the future.
                </p>
                <div>
                  <a href='/assets/images/reports/AnnualReport-2020-2021.pdf'>
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='report-box'>
            <div className='row'>
              <div className='col-md-6'>
                <a href='/uploads/2021/01/Annual-Report-2019-20.pdf'>
                  <img
                    src='/assets/images/reports/annualreport20.jpg'
                    className='w-100'
                  />
                </a>
              </div>
              <div className='col-md-6'>
                <h3>Annual Report 2019 – 2020</h3>
                <p>
                  As we enter a new decade, SEEDS has been evolving its
                  strategic direction for 2030. Building on our work over the
                  last 25 years, we seek to serve the bottom 1% of those most
                  vulnerable to climate emergencies. To carry out this urgent
                  task, we realise we cannot do it alone. In the last one year,
                  SEEDS has invested in building partnerships with Government,
                  expert institutions and grassroots organisations across the
                  country aligned to our shared objective. The challenges only
                  grow increasingly vast. As this year draws to a close, we face
                  the danger of COVID-19; a national lockdown; and the
                  imperative to swiftly pivot our programmes to continue serving
                  those most in need.
                </p>
                <div>
                  <a href='/uploads/2021/01/Annual-Report-2019-20.pdf'>
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='report-box'>
            <div className='row'>
              <div className='col-md-6'>
                <a href='/uploads/2020/Annual-Report-2018-19.pdf'>
                  <img
                    src='/assets/images/reports/annualreport-1.jpg'
                    className='w-100'
                  />
                </a>
              </div>
              <div className='col-md-6'>
                <h3>Annual Report 2018 – 2019</h3>
                <p>
                  This year we reached our 25th year milestone of an
                  unforgettable journey spent in developing purpose and
                  corresponding actions for an idea that was born in a college
                  canteen. In these twenty five years, as we turned the page to
                  a new century, the challenges of natural environment and
                  disasters have taken centre-stage in national and global
                  discourse. 2018-19 has been perhaps among the worst years in
                  India witnessing far more climate extreme events than ever
                  before…
                </p>
                <div>
                  <a href='/uploads/2020/Annual-Report-2018-19.pdf'>Download</a>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            {REPORTS &&
              REPORTS.map((item) => {
                return (
                  <div className='col-md-4'>
                    <div
                      className='report-tab'
                      style={{
                        borderBottom: '1px solid #f1f1f1',
                        marginBottom: '20px',
                      }}
                    >
                      <div className='row'>
                        <div className='col-md-4'>
                          <a href={item.link}>
                            {' '}
                            <img src={item.image} alt='' />
                          </a>
                        </div>
                        <div className='col-md-8'>
                          <h4>{item.title}</h4>
                          <div>
                            <a href={item.link}> Download </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AnnualReports;
