import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function FaceOfDisaster() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'The Face Of Disasters 2020'}
        sub_title={'Face Of Disaster'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='disaster-section'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/events/THE-FACE-OF-DISASTERS-2020-High.jpg'
                  alt=''
                />
              </div>
              <div className='col-md-8'>
                <h3>
                  Building a resilient and sustainable future amid changing
                  realities in Asia-Pacific
                </h3>
                <p>Current realities mean change is the only constant!</p>
                <p>
                  Asia-Pacific is the world’s most hazard-prone continent and
                  the impacts of climate change are only exacerbating this
                  complexity. Disasters are increasingly unpredictable;
                  intensities are growing and ‘unprecedented’ events have become
                  the new norm.
                </p>
                <p>
                  It’s not just the profile of hazard risks that are changing.
                  For at-risk communities and affected families, the interplay
                  between dealing with poverty, climate stresses and natural
                  hazards doesn’t have clear distinctions. Each reinforces the
                  other. The numbers of people affected continue to rise, with
                  disaster induced migration adding to conflict migration.
                </p>
                <p>
                  New hotspots are evolving and rapid demographic changes mean
                  the elderly require concerted attention. There is also a clear
                  impact on development gains, with Asia-Pacific currently not
                  on track to meet its Sustainable Development Goals targets.
                </p>
                <p>
                  At the same time, restrictions to civil society and risks to
                  humanitarians are on the rise across the region.
                </p>
                <p>
                  Against this backdrop, how can civil society best bring value
                  to at-risk communities?{' '}
                  <strong>
                    The Face of Disasters 2020 Practice Brief explores these
                    myriad changing realities and suggests eight emerging areas
                    of engagement for civil society and humanitarian agencies.
                  </strong>{' '}
                </p>
                <div>
                  <a href='/uploads/2019/11/THE-FACE-OF-DISASTERS-Asia-Pacific-2020.pdf'>
                    Download the Practice Brief
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='disaster-section mt-20'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/events/faceofdisastercover.jpg'
                  alt=''
                />
              </div>
              <div className='col-md-8'>
                <h3>THE FACE OF DISASTERS 2019</h3>
                <p>
                  {' '}
                  <strong>
                    Beyond response to build a sustainable future
                  </strong>{' '}
                </p>
                <p>
                  Current trends are reinforcing that disasters have multiple
                  facets. Our approach must as well.
                </p>
                <p>
                  India saw almost every type of hazard, except for an
                  earthquake, in 2018. From flood to drought, heat and cold
                  waves, lightning strikes, cyclones and even hailstorms, the
                  wide range of disasters impacted most of the country. This
                  multiplicity meant not a month went by without a disaster and
                  their impact is growing more complex.
                </p>
                <p>
                  While this multiple event pattern is repeated every year, only
                  a few really capture the public attention. Other risks
                  continue to intensify under the radar. For at-risk communities
                  and affected families, the interplay between dealing with
                  poverty, climate stresses and natural hazards doesn’t have
                  clear distinctions. Yet the discourse of disasters is still
                  too often seen in isolation.
                </p>
                <p>
                  <strong> The Face of Disasters 2019</strong> therefore takes a
                  broader view to the issue, building on risk trends, interviews
                  and experiences from the ground to capture its multiple faces.
                  For the risks of the future will be different from the risks
                  of the past. Recognising this and preparing for the unknown
                  will be key to building a safer and more sustainable world.
                </p>
                <div>
                  <a href='/assets/images/THE-FACE-OF-DISASTERS-2019.pdf'>
                    Download the Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default FaceOfDisaster;
