export const PROGRAMME_LEARNING = [
  {
    name: 'Kerala Floods Recovery',
    image: '/uploads/2018/09/teste-1.jpg',
    download_link: '/uploads/2020/09/SEEDS-KERALA-REPORT.pdf',

    content: `An overview of our humanitarian response and rebuilding initiatives in the aftermath of the 2018 floods in Kerala

    `,
  },
  {
    name: 'Building a resilient ecosystem in Buddhi village, Kathua    ',
    image:
      '/uploads/2018/09/Building-a-resilient-ecosystem-in-Buddhi-village-2.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Building%20a%20Resilient%20Ecosystem%20in%20Budhi%20village-Kathua-Jammu%20&%20Kashmir.pdf',

    content: `A report on our programme in Kathua, Jammu & Kashmir which used the local school as the fulcrum for broader sustainability interventions in the community.
    `,
  },
  {
    name: 'Rebuilding Chennai',
    image: '/uploads/2018/09/Rebuilding-Chennai.jpg',
    download_link: '/uploads/2020/Programme-learning/REBUILDING%20CHENNAI.pdf',

    content: `An overview of our response and housing ecosystem recovery programme in Chennai, Tamil Nadu, after the floods in 2015. How did we address the address the unique challenges that arose in this peri-urban environment?

    `,
  },
  {
    name: 'Support to Training and Employment Programme for Women (STEP) report',
    image: '/uploads/2018/09/STEP.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Seeds%20Mason%20Academy-STEP%20TRAINING-flyer%20Low%20Resolution.pdf',

    content: `An overview of our Support to Training and Employment Programme for Women (STEP) initiative that provided technical training to women on masonry and plumbing.
    `,
  },
  {
    name: 'Transcending the Tragedy Together',
    image:
      '/uploads/2018/09/Transcending-the-Tragedy-Together-JK-Floods-2014.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Transcending%20the%20Tragedy%20Together-%20J&K%20Floods%202014.pdf',

    content: `An overview of our response and early recovery work in the aftermath of the 2014 floods in Jammu & Kashmir which ranged from distribution of essentials to giving affected families back a home.    `,
  },
  {
    name: 'Nowdal school documentation',
    image: '/uploads/2020/07/nowdalschool.jpg',
    download_link:
      '/uploads/2020/07/THE-GOVERNMENT-PRIMARY-SCHOOL-in-Nowdal-Village-Pulwama-JK-1.pdf',

    content: `Re-built after the Kashmir floods of 2014, this is a view of our process in designing and constructing the Nowdal school. A design that has left safety, simplicity and smiles.    `,
  },
  {
    name: 'Ganjam Aashray Yojana',
    image: '/uploads/2018/09/Ganjam-Aashray-Yojana.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Ganjam%20Aashray%20Yojana%20report%20081014.pdf',

    content: `This report documents our response and recovery work for families across Ganjam in Odisha, who were hit by Cyclone Phailin in 2013.`,
  },
  {
    name: 'Adapting with FREEDM ',
    image: '/uploads/2018/09/Adapting-with-FREEDM.jpg',
    download_link:
      '/uploads/2020/Programme-learning/ADAPTING%20WITH%20FREEDMFlood%20Resilient%20Environmentally%20Enhanced%20Disaster%20Management%20In%20Bihar.pdf',

    content: `Learn about our multi-faceted approach to building resilience in Saharsa, Bihar through our Flood Resilient Environmentally Enhanced Disaster Management (FREEDM) initiative. It was a programme where risk reduction and climate adaptation initiatives were intertwined.    `,
  },
  {
    name: 'Voices: Catalysts of Change',
    image: '/uploads/2018/09/Voices-Catalysts-of-Change.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Voices-%20%20Catalysts%20of%20Change.pdf',

    content: `Stories from our research programme on catalysing multi-stakeholder action for policy change in the wake of disasters. The stories cover our research work and pilot projects in Barmer in Rajasthan and Ladakh.
    `,
  },
  {
    name: 'Voices – Life at Biratpur Health Centre',
    image: '/uploads/2018/09/voice.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Voices-%20Biratpur%20Health%20Centre.pdf',

    content: `Stakeholder voices and stories from our initiative of running a remote health centre in Biratpur, Bihar.
    `,
  },
  {
    name: 'Voices: Localizing HFA',
    image: '/uploads/2020/07/localising.jpg',
    download_link: '/uploads/2020/07/Voices-Localising-HFA.pdf',

    content: `Stakeholder voices from our joint action plan on disaster risk reduction that included schools, hospitals and communities. It was piloted in Dehradun district, Uttarakhand.    `,
  },
  {
    name: 'Barmer Aashray Yojana',
    image: '/uploads/2018/09/Barmer-Aashray-Yojana.jpg',
    download_link:
      '/uploads/2020/Programme-learning/barmer%20shelter%20report.pdf',

    content: `An overview of our shelter restoration programme in Barmer, Rajasthan after the 2006 flash floods. The approach was to innovatively strengthen the

    `,
  },
  {
    name: 'Balasore Aashray Yojana',
    image: '/uploads/2018/09/Balasore-Aashray-Yojana.jpg',
    download_link:
      '/uploads/2020/Programme-learning/balasore%20shelter%20report-08-09.pdf',

    content: `An overview of shelter programme in Balasore, Odisha that aimed to strengthen housing systems to cope with perennial flooding.
    `,
  },
  {
    name: 'Balasore Ankur    ',
    image: '/uploads/2018/09/Balasore-Ankur.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Ankur%20final%20report.pdf',

    content: `A report on our school restoration initiative in Balasore, Odisha after the 2005 flash floods.
     `,
  },
  {
    name: 'Patanka Navjivan Yojana',
    image: '/uploads/2018/09/patanka.jpg',
    download_link:
      '/uploads/2020/Programme-learning/Patanka%20Navjivan%20Yojana%20Report.pdf',

    content: `A report on our shelter restoration programme in Patanka, Gujarat, after the massive 2001 earthquake. The initiative was the start of SEEDS’ ‘build back better’ approach that has continued to evolve over the years.
     `,
  },
];

export const RESEARCH_ADVOCACY = [
  {
    name: 'Collaboration for Humanitarian Action',
    image: '/uploads/2020/10/PWC_Small.jpg',
    download_link:
      '/uploads/2020/08/PwCIF-and-SEEDS_Collaboration-for-Humanitarian-Action_July-2020_L.pdf',
    content: `Collaboration for Humanitarian Action traces the journey of SEEDS’ work in partnership with PwC India Foundation in diverse disaster contexts and geographies over the last 5 years and draws lessons from this experience to inspire others in the sector to nurture more such collaborative efforts.
    `,
  },
  {
    name: 'Decoding the Monsoon floods',
    image: '/uploads/2020/07/Decoding-the-Monsoon-floods.jpg',
    download_link:
      '/uploads/2020/Research-advocacy/Decoding%20the%20monsoon%20floods%20report.pdf',
    content: `Floods are amongst the most damaging and recurrent of all disasters in the Asian region. Additionally, they are morphing into new and even more devastating forms in recent years. Uncovering these nuances, ‘Decoding the monsoon floods in Bangladesh, India, Myanmar and Nepal’ is a regional analysis report based on disaster data between 2000 and 2017.
    `,
  },
  {
    name: 'Boiling Point – Climate Change Impacts on the Frontlines in India',
    image: '/uploads/2020/07/boilingpoint.jpg',
    download_link: '/uploads/2020/Research-advocacy/Boiling%20Point%20.pdf',
    content: `
    Boiling Point presented case studies from different parts of India; where local communities are already under the assault of climate change. Ten broad areas of intervention were identified in the given national context.

    `,
  },
  {
    name: 'Let’s Make Schools Safer',
    image: '/uploads/2020/07/lets-ake.jpg',
    download_link: `/uploads/2020/Research-advocacy/Let's%20make%20school%20safer-SEEDS.pdf`,
    content: `A compendium highlighting different methodologies on school safety and the testimonies of those who have benefited. We hoped it would urge decision makers and policy planners to make school safety a part of their policy and influence parents to play an active role in this change process.
    `,
  },
  {
    name: 'Towards Permanence – A brief guide to designing post-disaster shelters in India',
    image: '/uploads/2020/07/towardsoper.jpg',
    download_link: `/uploads/2020/07/Towards-permanence-a-brief-guide-for-post-disaster-shelter-in-India.pdf`,
    content: `A pocket guide that explores materials and technologies for housing reconstruction, based on geo-climactic zones in India. Instead of prescribing fixed designs, the guide identifies best-practice components and features.
    `,
  },
  {
    name: 'Lessons that matter',
    image: '/uploads/2020/07/lessonthatmatter.jpg',
    download_link: `/research-advocacy/#`,
    content: `Lessons that matter is an effort to put experiences of the 2001 Indian Ocean Tsunami into words, ensuring lessons learnt are put into practice in future recovery processes.
    `,
  },
  {
    name: 'Building resilience through community lifelines',
    image: '/uploads/2020/07/building-resilance-through-community.jpg',
    download_link: `/uploads/2021/03/building-resiliance-report.pdf`,
    content: `This report explores the seminal role that public infrastructure can play in disasters; the urgent need to strengthen schools and hospitals; and the steps that can be taken to build resilience through these community lifelines.
    `,
  },
  {
    name: 'Pan Himalayan Study',
    image: '/uploads/2020/07/panhimalaya.jpg',
    download_link: `/uploads/2020/Research-advocacy/ACTION%20AGENDA%20ON%20SAFE%20SCHOOLS%20AND%20HOSPITALS%20SAFETY.pdf`,
    content: `The traditional architecture of the Himalayan region was uniquely crafted to suit its diverse geo-climactic needs and disaster risks. This study explores the Himalayan built environment.
    `,
  },
  {
    name: 'Securing our shores',
    image: '/uploads/2020/07/securingandsources.jpg',
    download_link: `/uploads/2020/Research-advocacy/Securing%20our%20shores-%20SEEDS.pdf`,
    content: `Our work on promoting a multi-layered and multi-species bio-shield in Tamil Nadu, with the learning after the Indian Ocean Tsunami.
    `,
  },
];
