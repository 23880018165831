import React from 'react';

function GalleryArea() {
  return (
    <section className='gallery-area-2 p-relative pt-60 pb-130'>
      <div
        className='tpgallery-bg'
        data-background='assets/img/gallery/home-1/Map.png'
      />
      <div className='tpgallery-shape'>
        <span className='tpgallery-shape-1 d-none d-md-block' />
        <span className='tpgallery-shape-2' />
        <span className='tpgallery-shape-3' />
        <span className='tpgallery-shape-4' />
        <span className='tpgallery-shape-5' />
        <span className='tpgallery-shape-6' />
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div
              className='tpgallery-2-img wow fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='.5s'
            >
              <img src='assets/img/gallery/home-1/gallery.png' alt />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GalleryArea;
