import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import VideoBreadCrumb from '../../components/common/VideoBreadCrumb';

function OurStory() {
  return (
    <div id='wrapper'>
      <Header />
      {/* <VideoBreadCrumb title={'Our Story'} sub_title={'Our Story'} /> */}
      <BreadCrumb
        title={'Our Story'}
        sub_title={'Our Story'}
        image={'/uploads/2017/07/IMG_0417-1.jpg'}
      />
      <div className='tf-section section-about style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='main-about'>
                <div className='content-right-about'>
                  <div className='info-about'>
                    <p className='text'>
                      Founded in 1994, SEEDS had one ultimate goal: building the
                      resilience of people exposed to disasters and climate
                      change impacts. For the last 30 years, we have been
                      enabling this through practical solutions for disaster
                      readiness, response and rehabilitation. Our approach has
                      involved grafting innovative technology on to traditional
                      wisdom.
                    </p>
                    <p>
                      Over this time, our dedicated team has partnered families
                      affected by disasters and climate emergencies in a variety
                      of ways. Disaster survivors have had schools, homes and
                      community infrastructure restored and upgraded.
                      Marginalised communities have gained access to clean
                      water, sanitation and health facilities. Nature-based
                      solutions have been designed to help adapt to climate
                      change in ecologically-sensitive areas. We have also
                      invested in skill building, partnerships and advocacy to
                      build the long-term resilience of at-risk communities.
                    </p>
                    <p>Our work has reached families across India and Nepal.</p>
                    <p>
                      SEEDS continues to advocate for and work with communities
                      across Asia to build a safer and more sustainable world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <img
                src='/assets/images/about/staff2-540x500.jpg'
                className='img-fluid'
              />
            </div>
          </div>
        </div>
      </div>
      <section
        className='tf-section section-services style-2 pt-50 pb-50'
        style={{ background: '#f1f1f1' }}
      >
        <div className='container'>
          <div className='main-services'>
            <div className='list-services-box'>
              <div className='row'>
                <div className='col-md-4'>
                  <div
                    className='box-item wow fadeInDown'
                    data-wow-delay='400ms'
                  >
                    <div className='icon-box'>
                      <span className='icon-icon-02' />
                    </div>
                    <h3 className='title'>Vision</h3>
                    <p className='text'>
                      Transforming the vulnerable into resilient and thriving
                      communities
                    </p>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='box-item wow fadeInUp' data-wow-delay='400ms'>
                    <div className='icon-box'>
                      <span className='icon-icon-03' />
                    </div>
                    <h3 className='title'>Mission</h3>
                    <p className='text'>
                      Equipping the most vulnerable with appropriate tools and
                      technologies, sharing knowledge and skills and promoting
                      linkages among stakeholders to prevent loss of life and
                      suffering.
                    </p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='box-item wow fadeInUp' data-wow-delay='400ms'>
                    <div className='icon-box'>
                      <span className='icon-icon-01' />
                    </div>
                    <h3 className='title'>Logo</h3>
                    <p className='text'>
                      The bamboo in our logo signifies resilience and the
                      ability to bounce back even after a crisis. Extremely
                      flexible but also extremely strong, bamboo regenerates
                      even in the face of disasters. The families we work with
                      show the same spirit and are the inspiration to continue
                      building resilient communities through innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default OurStory;
