import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function Events() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'Events'} sub_title={'Events'} />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div>
                <img
                  src='/assets/images/events/Resilience-Cafe_Conversation-2.jpg'
                  alt=''
                />
              </div>
              <div className='wpb_wrapper'>
                <p>
                  We are happy to announce our latest initiative, ‘Resilience
                  Café – Conversations with SEEDS’. We aim to shape our
                  worldview and that of others to arrive at actionable
                  humanitarian interventions to save lives and prevent losses
                  from disasters, now and in the future, through these
                  conversations. Through this platform, we will be talking about
                  marginalised communities, micro and small business
                  enterprises, and migrants but not limit ourselves to just
                  them. We shall engage with affected and at-risk communities,
                  civil society partners, donors, children, and government
                  officials.
                </p>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <iframe
                width={'100%'}
                height={300}
                src='https://www.youtube.com/embed/3CMUa_lvdj8'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
            </div>
            <div className='col-md-6'>
              <h3>
                Edition 3 : Navigating the Sustainable Energy-Disaster
                Resilience Nexus
              </h3>
              <p>
                The level of loss during a disaster depends upon the coping
                capacity of the affected community. Ones with low capacities are
                often affected by disasters in cycles, getting hit by the next
                one before they have recovered from one. India loses over one
                million houses to disasters each year, along with other public
                infrastructure, assets, and livelihoods that form part of the
                ecosystem. While the inter-linkages between sustainable energy
                and disaster resilience cannot be overstated, it is often
                lacking in practice. SEEDS and SELCO Foundation came together to
                demonstrate the use of sustainable energy as an enabler for
                faster recovery and building resilience, among affected
                communities in Puri district of Odisha post-Cyclone Fani.
              </p>
            </div>
            <div className='col-md-12'>
              <p>
                Basis this experience, this conversation will aim to arrive at
                actionable suggestions on how to build a robust sustainable
                energy-disaster resilience nexus in practice. It will also draw
                from the speakers’ expertise and experience around the world to
                arrive at possible strategies to create an enabling environment
                for it.
              </p>
            </div>
          </div>
          <hr className='mb-30' />
          <div className='row'>
            <div className='col-md-6'>
              <iframe
                width={'100%'}
                height={315}
                src='https://www.youtube.com/embed/oNg9X_huhBM'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
            </div>
            <div className='col-md-6'>
              <h3>Edition 2 : Local Leadership for Disaster Resilience</h3>
              <p>
                The Sendai framework puts a great emphasis on the need for
                action at local levels to achieve its set of targets by 2030. It
                calls for a coordination mechanism within and across sectors and
                with relevant stakeholders at all levels while recognising that
                risks have local and specific characteristics. An empowered
                local leadership is best positioned to ensure the frameworks’
                effective implementation on the ground. Local leaders can
                integrate the societal understanding of risks, which are
                critical for effective disaster risk management at the grassroot
                and local levels.
              </p>
              <p>
                In that light, SEEDS Co-founder Manu Gupta had a detailed
                conversation with Krishna Vatsa, Member, National Disaster
                Management Authority (NDMA), India; Animesh Kumar,
                Officer-in-Charge and Deputy Chief, UNDRR Regional Office for
                Asia and the Pacific and Deicy Silvia Wenas, Founder, The
                Unspoken Ministry, Indonesia; aiming to arrive at measures to
                enable local leaders to be the drivers for lasting change in
                their communities. The conversation also drew lessons from the
                experience of the pandemic for other local leaders to learn
                from.
              </p>
            </div>
          </div>
          <hr className='mb-30' />
          <div className='row'>
            <div className='col-md-6'>
              <iframe
                width={'100%'}
                height={315}
                src='https://www.youtube.com/embed/LJxRknLO42A'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
            </div>
            <div className='col-md-6'>
              <h3>
                Edition 1 : Collaborations for Humanitarian Action beyond
                Funding
              </h3>
              <p>
                In the recent years, humanitarian partnerships involving private
                sector companies are on the rise. While the primary role of
                these companies as funders is well known and acknowledged, there
                is much more to these collaborations for effective humanitarian
                action on the ground. During this conversation, we looked at our
                own experience of working with one of our partners as well as
                examples of such collaborations from around the world in diverse
                disaster contexts and geographies to draw values, principles,
                and lessons which nurture these effective partnerships. SEEDS
                Co-Founder Manu Gupta takes on a detailed conversation with
                Jaivir Singh- Vice Chairman, PwC India Foundation and Leader,
                PwC Global Office for Humanitarian Affairs and Juhi Gupta- Head
                of Sustainability, PepsiCo India, talking about marginalized
                communities and engaging with affected and at-risk communities,
                civil society partners, donors, children, and government
                officials. We hope this conversation inspire others, both from
                civil society and the private sector, to forge more such
                collaborations for humanitarian action.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Events;
