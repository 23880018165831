import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function Financials() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'Financials'} sub_title={'Financials'} />
      <section
        className='tpblog-area policy-section  pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='wpb_wrapper'>
            <p>
              SEEDS is registered under the{' '}
              <strong>
                Societies Registration Act XXI of 1860. All donations made to
                SEEDS are eligible for Tax Deduction under section 80G of the
                Income Tax Act 1961.{' '}
              </strong>
            </p>
            <p>
              We are grateful for the support by various stakeholders that allow
              us to continue building more resilient communties. We ensure that
              the contributions received are used in the most effective manner
              to futher this work.
            </p>
          </div>
          <div className='wpb_wrapper'>
            <p>
              <a href='/uploads/2021/06/Approval-Letter-for-form-CSR1-15.pdf'>
                CSR 1 Registration Approval Letter
              </a>
              <br />{' '}
              <a href='/uploads/2021/06/ChangeDetail-Certificate.pdf'>
                New FCRA Account Activation 2021
              </a>
            </p>
          </div>
          <div className='wpb_wrapper'>
            <p>
              <strong>
                The following figures represent grants and donations received
                across different financial years.
              </strong>
            </p>
            <table className='table table-bordered table-striped'>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <strong>Year </strong>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <strong>Consolidated Financial Statement</strong>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <strong>FCRA </strong>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2022-23
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/images/reports/SEEDS-Audited-Report-FY-2022-2023.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/images/reports/SEEDS-FCRA-Certification-FY-2022-2023.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2021-22
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2022/12/SEEDS-Audited-Report-FY-2021-2022.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2022/12/SEEDS-FCRA-Certification-FY-2021-2022.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2020-21
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2022/03/SEEDS-Audit-Report_31-3-2021_.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2022/03/SEEDS-FCRA-Certification-31-3-2021_0001_.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2019-20
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2021/04/SEEDS-Statutory-audit-for-the-year-ended-31st-March-2020.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2021/04/SEEDS-FCRA-Certification-for-the-year-ended-31st-March-2020.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2018-19
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-Statutory-audit-for-the-year-ended-31st-March-2019.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-FCRA-certification-for-the-year-ended-31st-March-2019.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2017-18
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-Statutory-audit-for-the-year-ended-31st-March-2018.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-FCRA-Certification-for-the-year-ended-31st-March-2018.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }} width={200}>
                    Financial Year 2016-17
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-Statutory-audit-for-the-year-ended-31st-March-2017.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }} width={200}>
                    <a
                      href='/uploads/2020/07/SEEDS-FCRA-Certification-for-the-year-ended-31st-March-2017.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='wpb_wrapper'>
            <h4>
              <strong>FCRA Quarter Financial Reports</strong>
            </h4>
            <p>
              <strong>2021-22</strong>
              <br /> Q1: 1 Apr-30 Jun 2021{' '}
              <a
                href='/uploads/2021/07/SEEDS-FCRA-2021-22-Q1-report.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q2: 1 July-30 Sept 2021{' '}
              <a
                href='/uploads/2021/10/FCRA-Detail-July-to-Sept21-Q2.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q3: 1 Oct-31 Dec 2021{' '}
              <a
                href='/uploads/2022/01/FCRA-Detail-oct-to-Dec-21.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 March 2022{' '}
              <a
                href='/uploads/2022/04/FCRA-Quarterly-Report-1st-Jan-22-to-March-2022.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
            <p>
              <strong>2020-21</strong>
              <br /> Q1: 1 Apr-30 Jun 2020{' '}
              <a
                href='/uploads/2020/07/FCRA-Report-Q1.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q2: 1 Jul-30 Sep 2020{' '}
              <a
                href='/uploads/2020/10/FCRA-Q2-Report.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q3: 1 Oct-31 Dec 2020{' '}
              <a
                href='/uploads/2021/01/FCRA-Quarterly-Report-1st-Oct-20-to-31st-Dec-20.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 March 2021{' '}
              <a
                href='/uploads/2021/04/SEEDS-FCRA-Q-4.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
            <p>
              <strong>2019-20</strong>
              <br /> Q1: 1 Apr-30 Jun 2019{' '}
              <a
                href='/uploads/2019/07/FCRA-Quarterly-Report-1st-April-to-30th-June-19.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q2: 1 Jul-30 Sep 2019{' '}
              <a
                href='/uploads/2019/10/FCRA-Quarterly-Report-1st-July-to-30th-Sept-19.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q3: 1 Oct-31 Dec 2019{' '}
              <a
                href='/uploads/2020/01/FCRA-Quarterly-Report-1st-Oct-to-31st-Dec-19.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 Mar 2020
              <a
                href='/uploads/2020/04/FCRA-Quarterly-Report-1st-Jan-20-to-31st-March-20.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                {' '}
                Download report
              </a>
            </p>
            <p>
              <strong>2018-19</strong>
              <br /> Q1: 1 Apr-30 Jun 2018
              <a
                href='/uploads/2018/07/FCRA-Quarterly-Report-1st-April-to-30th-June-2018.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                {' '}
                Download report
              </a>
              <br /> Q2: 1 Jul-30 Sep 2018{' '}
              <a
                href='/uploads/2018/10/FCRA-Quarterly-Report-1st-July-to-30th-September-2018.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q3: 1 Oct-31 Dec 2018{' '}
              <a
                href='/uploads/2019/01/FCRA-Quarterly-Report-1st-Oct-to-31th-December-2018.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 Mar 2019{' '}
              <a
                href='/uploads/2019/04/FCRA-Quarterly-Report-1st-Jan-19-to-31st-March-2019.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
            <p>
              <strong>2017-18</strong>
              <br /> Q1: 1 Apr-30 Jun 2017{' '}
              <a
                href='/uploads/2017/07/FCRA-Quarterly-Report-1st-April-2017-to-June-2017.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q2: 1 Jul-30 Sep 2017 Download report
              <br /> Q3: 1 Oct-31 Dec 2017{' '}
              <a
                href='/uploads/2018/01/FCRA-Quarterly-Report-1st-October-to-31st-December-2017.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 Mar 2018{' '}
              <a
                href='/uploads/2018/04/FCRA-Quarterly-Report-1st-January-to-31st-March-2018.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
            <p>
              <strong>2016-17</strong>
              <br /> Q1: 1 Apr-30 Jun 2016{' '}
              <a
                href='/uploads/2017/07/FCRA-Quarterly-report1st-April201-to-June-2016.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q2: 1 Jul-30 Sep 2016{' '}
              <a
                href='/uploads/2017/07/FCRAQuarterlyreport1stJuly-top-30th-September2016.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q3: 1 Oct-31 Dec 2016{' '}
              <a
                href='/uploads/2017/07/FCRA-Quarterly-Report-1st-October-to-31st-December-2016.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 Mar 2017{' '}
              <a
                href='/uploads/2017/07/FCRA-Quarterly-Report-1st-January-2017-to-March-2017.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
            <p>
              <strong>2016-15</strong>
              <br /> Q3: 1 Oct-31 Dec 2015{' '}
              <a
                href='/uploads/2017/07/FCRA-Quarterly-Financial-Report-Oct-Dec_2015.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
              <br /> Q4: 1 Jan-31 Mar 2016{' '}
              <a
                href='/uploads/2017/07/FCRA-Qtr-Report-Jan-16-March-2016.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                Download report
              </a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Financials;
