import React from 'react';
import { Link } from 'react-router-dom';

function AboutSection() {
  return (
    <>
      <section className='features-area-2 ' style={{ paddingTop: '50px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-7 col-lg-7 col-md-7'>
              <div className='tpfeatures-section__wrapper mb-40'>
                <h3
                  className='section-title wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='.5s'
                >
                  Building resilient communities through a holistic and
                  inclusive approach
                </h3>
              </div>
            </div>
          </div>
          <div className='d-flex ' style={{ gap: '10px' }}>
            <div className='tpfeatures mb-60 '>
              <Link to={'/what-we-do#emergency-response'}>
                <div className='tpfeatures__content'>
                  <img
                    src='/assets/images/home/Humanitarian-ResponseHome.jpg'
                    style={{ width: '100%', height: 'auto' }}
                    className='grayscale'
                  />
                  <h3 className='tpfeatures__title'>
                    <Link to={'/what-we-do'}>
                      Emergency Response <br /> and Relief
                    </Link>
                  </h3>
                  <p>
                    Swift action and coordinated efforts bring hope and aid
                    during times of crisis.
                  </p>
                </div>
              </Link>
            </div>
            <div className='tpfeatures mb-60 '>
              <Link to={'/what-we-do'}>
                <div className='tpfeatures__content'>
                  <img
                    src='/assets/images/home/DisasterRiskandResilience.JPG'
                    style={{ width: '100%', height: 'auto' }}
                    className='grayscale'
                  />
                  <h3 className='tpfeatures__title'>
                    <Link to={'/what-we-do'}>
                      Disaster Risk <br /> and Resilience​
                    </Link>
                  </h3>
                  <p>
                    Turning big challenges into small victories through
                    preparedness and adaptability.
                  </p>
                </div>
              </Link>
            </div>
            <div className='tpfeatures mb-60 '>
              <Link to={'/what-we-do'}>
                <div className='tpfeatures__content'>
                  <img
                    src='/assets/images/home/SustainableEnvironmentAndClimate.JPG'
                    style={{ width: '100%', height: 'auto' }}
                    className='grayscale'
                  />
                  <h3 className='tpfeatures__title'>
                    <Link to={'/what-we-do'}>
                      Sustainable Environment <br /> and Climate​
                    </Link>
                  </h3>
                  <p>
                    Preserving our planet's future through sustainable practices
                    and combating climate change
                  </p>
                </div>
              </Link>
            </div>
            <div className='tpfeatures mb-60 '>
              <Link to={'/what-we-do'}>
                <div className='tpfeatures__content'>
                  <img
                    src='/assets/images/home/DisasterInnovation.jpg'
                    style={{ width: '100%', height: 'auto' }}
                    className='grayscale'
                  />
                  <h3 className='tpfeatures__title'>
                    <Link to={'/what-we-do'}>
                      Disaster <br /> Innovation
                    </Link>
                  </h3>
                  <p>
                    From devastation springs evolution: Embracing the resilience
                    of disaster innovation
                  </p>
                </div>
              </Link>
            </div>
            <div className='tpfeatures mb-60 '>
              <Link to={'/what-we-do'}>
                <div className='tpfeatures__content'>
                  <img
                    src='/assets/images/home/CommunitySkilling.jpg'
                    className='grayscale'
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <h3 className='tpfeatures__title'>
                    <Link to={'/what-we-do'}>
                      Community <br /> Skilling​
                    </Link>
                  </h3>
                  <p>
                    Empowering individuals, strengthening communities through
                    the power of skill-building
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {/* <div className='row'>
            <div
              className='col-xl-12 col-lg-12 col-md-12 wow fadeInRight'
              data-wow-duration='1s'
              data-wow-delay='.5s'
            >
              <div className='row '>
                <div className='col-xl-2 col-lg-2 col-md-2'>
                  <div className='tpfeatures mb-60 '>
                    <div className='tpfeatures__icon'>
                      <img src='assets/img/icon/features-1.png' alt />
                    </div>
                    <div className='tpfeatures__content'>
                      <h3 className='tpfeatures__title'>Food and water</h3>
                      <p>All our content marketing service packages</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-2'>
                  <div className='tpfeatures  mb-60 ml-15'>
                    <div className='tpfeatures__icon'>
                      <img src='assets/img/icon/features-2.png' alt />
                    </div>
                    <div className='tpfeatures__content'>
                      <h3 className='tpfeatures__title'>Environment</h3>
                      <p>All our content marketing service packages</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-2'>
                  <div className='tpfeatures  mb-60 ml-36'>
                    <div className='tpfeatures__icon'>
                      <img src='assets/img/icon/features-3.png' alt />
                    </div>
                    <div className='tpfeatures__content'>
                      <h3 className='tpfeatures__title'>Build and repair</h3>
                      <p>All our content marketing service packages</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-2'>
                  <div className='tpfeatures  mb-60 ml-15'>
                    <div className='tpfeatures__icon'>
                      <img src='assets/img/icon/features-4.png' alt />
                    </div>
                    <div className='tpfeatures__content'>
                      <h3 className='tpfeatures__title'>Education</h3>
                      <p>All our content marketing service packages</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-2'>
                  <div className='tpfeatures  mb-60 ml-15'>
                    <div className='tpfeatures__icon'>
                      <img src='assets/img/icon/features-4.png' alt />
                    </div>
                    <div className='tpfeatures__content'>
                      <h3 className='tpfeatures__title'>Education</h3>
                      <p>All our content marketing service packages</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default AboutSection;
