import React from 'react';
import { Link } from 'react-router-dom';

function CauseCard({ item }) {
  return (
    <div className='causes-item'>
      <div className='tpcard__wrapper tpcard__wrappr-2 white-bg'>
        <div className='tpcard__img grayscale'>
          <Link to={`/${item.slug}`}>
            <img
              src={item.image}
              style={{ width: '100%', height: '315px', objectFit: 'cover' }}
            />
          </Link>
        </div>
        <div className='tpcard__content'>
          <h4 className='tpcard__title'>
            <Link to={`/${item.slug}`}>{item.name}</Link>
          </h4>

          <div>
            <p className='cause-short-description'>{item.short_description}</p>
          </div>
          {/* <Link
            to={`/campaigns/${item.slug}`}
            className='tp-btn tp-btn-shine-effect'
          >
            Donate
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default CauseCard;
