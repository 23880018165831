import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function WorkWithUs() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Work With Us'}
        sub_title={'Work With Us'}
        image={'/uploads/2017/07/vo.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <img src='/assets/images/get-involved/career-1.jpg' alt='' />
            </div>
            <div className='col-md-6'>
              <p>
                Being a SEEDER brings new experiences every day. From helping
                save lives in the aftermath of an emergency to working with
                communities across India to reduce their disaster risk. In ways
                big and small, you’ll be doing work that makes a difference.
              </p>
              <p>
                You’ll be joining a dynamic team with colleagues from diverse
                professional backgrounds. This mix allows for immense learning –
                a focus of every employee’s overall development here. SEEDS also
                offers a professional environment and the best compensation in
                the sector.
              </p>
              <p>
                If you have the skills and drive to join the humanitarian
                sector, check the vacancies or write to us at{' '}
                <a href='mailto:joinus@seedsindia.org'>joinus@seedsindia.org</a>{' '}
                . We will contact you for any future requirements that arise.
                SEEDS is an equal opportunity employer and evaluates all
                applicants purely on the basis of merit and performance.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='wpb_wrapper'>
                {/* <p>
                  Manager, Donor Marketing (North and East region), Delhi{' '}
                  <a
                    href='/assets/img/work-with-us/JD-Manager-Donor-Marketing-02-06-2023.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Product Manager, Humanitarian Innovation, Delhi{' '}
                  <a
                    href='/assets/img/work-with-us/JD_ProductManagerHumanitarianInnovation.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Product Manager, Delhi{' '}
                  <a
                    href='/uploads/2023/03/JD_Product-Manager.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Product Marketing Manager, Delhi{' '}
                  <a
                    href='/uploads/2023/03/JD_Product-Marketing-Manager.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Product Planning and Program Manager, Delhi{' '}
                  <a
                    href='/uploads/2023/03/JD_Product-Planning-and-Program-Manager.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}

                {/* <p>
                  Manager, Climate and Disaster Planning, Delhi{' '}
                  <a
                    href='/uploads/2023/02/JD_Manager_CDP.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Senior Manager, Design Research and Development, Delhi{' '}
                  <a
                    href='/uploads/2023/02/JD_Snr-Manager.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
                {/* <p>
                  Associate Director, Fund Raising and Donor Relationship,
                  Bengaluru{' '}
                  <a
                    href='/uploads/2023/01/Job-Description.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View Details
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default WorkWithUs;
