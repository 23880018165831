import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function Founders() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Founders'}
        sub_title={'Founders'}
        image={'/uploads/2020/08/Co-Founders-Header-Image-1.jpg'}
      />
      <section className='tf-section section-team s1 background-section pt-50 pb-50'>
        <div className='container'>
          <div className='team-section'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='team-image'>
                  <div className='img-box'>
                    <img
                      src='/assets/images/teams/Dr-Anshu.jpg'
                      className='img-fluid'
                    />
                  </div>
                  <div className='team-wrapper'>
                    <h5 className='name'>Dr. Anshu Sharma</h5>
                    <p className='category'>Co-Founder</p>
                    <div className='d-flex social-links-gap'>
                      <div className='linkedin-url'>
                        <a
                          className='link-main'
                          href='https://www.linkedin.com/in/anshu-seeds/'
                          target='_blank'
                        >
                          {' '}
                          Linkedin{' '}
                        </a>
                      </div>
                      <div className='linkedin-url'>
                        <a
                          className='link-main'
                          href='https://twitter.com/anshu_seeds'
                          target='_blank'
                        >
                          {' '}
                          Twitter{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='team-content'>
                  <div>
                    {' '}
                    <p>
                      Dr. Anshu Sharma is a Co-Founder of SEEDS. Anshu trained
                      as an urban planner at the School of Planning and
                      Architecture, New Delhi, and did his doctoral research in
                      global environmental studies at Kyoto University, Japan.
                    </p>
                    <p>
                      Over the last 30 years, Anshu has worked extensively on
                      disaster risk and climate emergency issues. He has
                      coordinated community-based action projects, trained
                      practitioners, led inter-agency efforts and conducted
                      research. He has also served as an advisor and consultant
                      to numerous Government, non-profit, UN and funding
                      agencies, helping them to plan, implement and evaluate
                      their programmes.
                    </p>
                    <p>
                      Anshu is known for his abundance of ideas. He has a keen
                      interest in systems thinking, innovations and capacity
                      building within the humanitarian sector. A professional
                      trainer, he has been a driving force behind SEEDS’
                      frontline disaster trainings and is a global tutor for
                      Oxford Brookes University. Anshu has also pioneered
                      initiatives such as a disaster preparedness innovation lab
                      in Bangladesh and climate schools in Sikkim.
                    </p>
                    <p>
                      He has co-edited books on Indigenous Knowledge and
                      Disaster Risk Reduction: From Practice to Policy (Nova,
                      2009), Urban Risk Reduction: An Asian Perspective
                      (Emerald, 2009) and Climate and Disaster Resilience in
                      Cities (Emerald, 2011). He co-edited the IFRC World
                      Disaster Report 2016, and authored a chapter on Urban
                      Resilience for the UNESCAP Asia Pacific Disaster Report
                      2017.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='team-section'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='team-image'>
                  <div className='img-box'>
                    <img
                      src='/assets/images/teams/Dr-Manu.jpg'
                      className='img-fluid'
                    />
                  </div>
                  <div className='team-wrapper'>
                    <h5 className='name'>Dr. Manu Gupta</h5>
                    <p className='category'>Co-Founder</p>
                    <div className='d-flex social-links-gap'>
                      <div className='linkedin-url'>
                        <a
                          className='link-main'
                          href='https://www.linkedin.com/in/manu-gupta-76b09919'
                          target='_blank'
                        >
                          {' '}
                          Linkedin{' '}
                        </a>
                      </div>
                      <div className='linkedin-url'>
                        <a
                          className='link-main'
                          href='https://twitter.com/manugupta_seeds'
                          target='_blank'
                        >
                          {' '}
                          Twitter{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='team-content'>
                  <div>
                    {' '}
                    <p>
                      Dr. Manu Gupta is a Co-Founder of SEEDS. Manu holds
                      degrees in Physical Planning and Urban Planning from the
                      School of Planning and Architecture, New Delhi, and a
                      doctorate in community-based disaster management from
                      Kyoto University, Japan.
                    </p>
                    <p>
                      Over 30 years at the helm, he has mobilised community-led
                      efforts in recovery, risk reduction and climate change
                      adaptation, working with a wide spectrum of communities
                      across Asia.
                    </p>
                    <p>
                      Manu has been at the forefront of championing and
                      advocating for citizen-led initiatives. He brings ground
                      realities to global perspectives and is actively involved
                      in global conversations on humanitarian issues. In 2019,
                      Manu was selected as an Ashoka Fellow, joining a group of
                      leading social entrepreneurs finding solutions to the
                      world’s greatest problems. He also sits on the boards of
                      multiple organisations, including the Asian Disaster
                      Reduction and Response Network, an active network of NGOs
                      from over 20 countries in Asia; and the Network for
                      Empowered Aid Response. Manu’s member of the Global
                      Steering Committee for UNISDR on the Making Cities
                      Resilient campaign. At the national level, Manu co-founded
                      the Alliance for Adaptation & Disaster Risk Reduction and
                      the Owner Driven Reconstruction Collaborative in India. He
                      continues to serve on various national committees of the
                      Government of India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='video-section pt-50'>
            <div>
              <iframe
                width={'100%'}
                height={500}
                className='video-iframe'
                src='https://www.youtube.com/embed/0iThBFXwKqE'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
              <p>
                Experience the inspirational lives of our co-founders, Anshu
                Sharma and Manu Gupta, as they sow the “SEEDS of RECOVERY” by{' '}
                instilling <br /> self-reliance and disaster resilience in
                people. We thank National Geographic India for celebrating their
                journey in the #ONEFORCHANGE series.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Founders;
