import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { PROJECTS } from '../../domain/modals/projects';
import CauseCard from '../../components/campaigns/CauseCard';
function AllCampaigns() {
  return (
    <div>
      <div>
        <Header />
        <BreadCrumb title={'All Campaigns'} sub_title={'All Campaigns'} />
        <section className='card-area card-area-2 pt-130 pb-130'>
          <div className='container'>
            <div className='row'>
              {PROJECTS &&
                PROJECTS.map((item) => {
                  if (item.type === 'donation' && !item.hide) {
                    return (
                      <div className='col-md-4'>
                        <div>
                          <div style={{ marginBottom: '10px' }}>
                            <CauseCard item={item} />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default AllCampaigns;
