import React, { useEffect, useRef } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { useSearchParams } from 'react-router-dom';

function ContactUs() {
  const scriptTag = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const successMessage = searchParams.get('success');
  useEffect(() => {
    if (successMessage) {
      alert("Thank you for reaching out! We'll be in touch with you shortly.");
    }
  }, []);

  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Contact Us'}
        sub_title={'Contact Us'}
        image={'/uploads/2017/07/contact.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div style={{ position: 'sticky', top: '80px' }}>
                {' '}
                <img src='/assets/images/get-involved/contactmnew.jpg' />
              </div>
            </div>
            <div className='col-md-6'>
              <h3>SEEDS</h3>
              <p>
                B-40, Second Floor, Qutub Institutional Area, <br />
                New Delhi - 110016, India
                <br /> Phone: +91-11- 26174272
                {/* <br /> Fax: +91-11-26174572 */}
                <br /> Email: info@seedsindia.org
              </p>
              <h3>
                For corporate and partnership related queries please contact
              </h3>
              <p>
                {' '}
                <a href='mailto:partnership@seedsindia.org'>
                  {' '}
                  partnership@seedsindia.org{' '}
                </a>{' '}
              </p>
              <h3>For volunteering opportunity and queries email</h3>
              <p>
                {' '}
                <a href='mailto:volunteer@seedsindia.org'>
                  {' '}
                  volunteer@seedsindia.org{' '}
                </a>{' '}
              </p>
              <div ref={scriptTag}></div>
              {/* <iframe
                width='100%'
                height='360px'
                src='https://crm.zoho.in/crm/WebFormServeServlet?rid=ec2251d8b1d3b68b5bbb80a755f98f6fabfdb89291fcafd5b3e24dd937c10bf7gid02acad8cb935f1946ec2e902ee85d938d575af7652021a31f94c266c602562cf'
              ></iframe> */}
              {successMessage && (
                <h3>
                  {' '}
                  Thank you for reaching out! We'll be in touch with you
                  shortly.{' '}
                </h3>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ContactUs;
