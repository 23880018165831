import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

function BusinessWorld() {
  return (
    <div id='wrapper'>
      <Header />
      <BreadCrumb
        title={
          'SEEDS Designs Solutions Honoured At Future Of Design Awards 2021'
        }
        sub_title={'Our Story'}
      />
      <div className='tf-section section-about style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <img
                src='/uploads/2022/04/designbig.jpg'
                className='w-100 mb-30'
              />
            </div>
            <div className='col-md-6'>
              <iframe
                className='lazy-loaded'
                title='YouTube video player'
                data-lazy-type='iframe'
                data-src='https://www.youtube.com/embed/xG43hHSs9oI'
                width={'100%'}
                height={450}
                frameBorder={0}
                allowFullScreen='allowfullscreen'
                src='https://www.youtube.com/embed/xG43hHSs9oI'
              />
            </div>
            <div className='col-md-6'>
              <div class='wpb_wrapper'>
                <p>
                  Our focus of creating and designing a sustainable world was
                  recognised with a prize in the third edition of Business
                  World’s Future of Design Award in the ‘Space Category’.&nbsp;
                  The award was institured keeping in mind the massive
                  disruption caused by the pandemic, and the need-of-the-hour
                  design solutions created for problems that plague mankind.
                </p>
                <p>
                  The award recognised and felicitate the best prototype and
                  finished designs by designers, architects, innovators and
                  businesses.&nbsp; The acknowledgement recognised SEEDS efforts
                  towards its{' '}
                  <a
                    href='/portfolio-categories/build-back-better'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <strong>build back better </strong>
                  </a>
                  approach that is helping communities become resilient to
                  disasters.
                </p>
                <p>
                  Especially in the context of working with the most vulnerable
                  communities in Assam that are hit by floods almost every year
                  as the monsoon brings heavy rain, the award is a badge of
                  honour that highlights SEEDS’s journey of touching human lives
                  and making them future ready. The devastating floods of 2017
                  in Assam led to loss of lives and livelihood, displacing
                  thousands. Partnerships formulated a collaborative effort to
                  design and build 81 core houses. Architects and artisans
                  worked together to develop disaster-resilient construction
                  techniques. These houses have already faced three floods after
                  construction and have successfully survived. This clearly
                  demonstrates the relevance of integrating indigenous knowledge
                  with contemporary building technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BusinessWorld;
