import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function WhatWeDo() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'What  We  Do'}
        sub_title={'What We Do'}
        image={'/assets/images/breadcrumb/What-we-do-Header-Image.jpg'}
      />
      <section
        className='tpblog-area  pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h3>
                SEEDS partners with the vulnerable communities to build their
                resilience to disasters and climate change impacts. We use
                innovative approaches and technologies that work for these
                communities.
              </h3>
              <p>
                Asia-Pacific region overall is the most disaster-prone and
                climate vulnerable continent in the world. India, in particular,
                is highly vulnerable to a range of natural hazards such as
                earthquakes, landslides; weather-related disasters – floods,
                cyclones, droughts and forest fires, which have now –
                exacerbated due to impacts of climate change. Asian countries
                are also facing a range of slow-onset climate impacts, including
                sea-level rise, ocean acidification, loss of biodiversity and
                increasing pace of desertification.
              </p>
              <p>
                Unplanned development and lack of disaster preparedness is
                amplifying the magnitude of devastation putting the most
                vulnerable communities at even greater risk. While
                weather-related emergencies are growing more intense, frequent
                and unpredictable, secondary impacts on health, safety and
                overall well-being are increasing. Loss of lives, assets and
                livelihoods is severe enough to force affected people to leave
                their natives and migrate in distress. Forced migration is now
                becoming a norm in such disaster-prone regions across the
                country.
              </p>
              <p>
                SEEDS works with both disaster-affected and at-risk communities.
                We do this in a long-term and interconnected manner – in
                peacetime, before, during and after a disaster event. Our
                attention is on the most vulnerable and marginalised families
                within these areas.
              </p>
              <p>
                To build resilience of affected communities, we focus on safe
                housing, schools & community infrastructure; water & sanitation;
                environment; and health – all with a sustainable and
                ecosystem-based approach.
              </p>
            </div>
          </div>
          <div className='what-we-do-section' id='emergency-response'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/uploads/2017/07/Humanitarian-ResponseHome.jpg'
                  className='grayscale'
                />
              </div>
              <div className='col-md-8'>
                <h3>Emergency Response and Relief</h3>
                <p>
                  When disasters strike, affected communities require immediate
                  assistance. SEEDS tailors every response to the need on the
                  ground. We help with emergency tents, early recovery housing
                  and kits of essential items. In cases where schools are
                  damaged, temporary learning centres are established to help
                  continue education. Major water sources are cleaned and
                  repaired. SEEDS also sets up roving medical camps to provide
                  remote populations with immediate health services.
                </p>
              </div>
            </div>
          </div>
          <div className='what-we-do-section'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/home/DisasterRiskandResilience.JPG'
                  className='grayscale'
                />
              </div>
              <div className='col-md-8'>
                <h3> Disaster Risk and Resilience​</h3>
                <p>
                  The reconstruction process is not just to restore what was
                  lost, but to create a better and safer life than before. SEEDS
                  partners with survivors to rebuild their homes, schools and
                  community infrastructure; and addresses ecosystems around
                  these.
                </p>
                <p>
                  The quality of these buildings is upgraded through relevant
                  disaster reduction techniques and making them climate proof
                  for the future. Sustainability lies at the heart of our design
                  process using cultural norms, environmentally friendly
                  features and local material. Wherever possible, buffer
                  plantations and nature-based solutions are incorporated into
                  the larger programme.
                </p>
                <p>
                  We strive to leave behind skillsets, not just infrastructure.
                  Our programmes incorporate training of local construction
                  workers on retrofitting and safe construction practices.
                  School safety or community preparedness trainings are added.
                  Awareness campaigns on safe water, sanitation and hygiene
                  practices are run.
                </p>
                <p>
                  SEEDS also works closely with local governments on larger
                  reconstruction planning processes. Together, these varied
                  strands embody the spirit of building back better.
                </p>
              </div>
            </div>
          </div>
          <div className='what-we-do-section'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/home/SustainableEnvironmentAndClimate.JPG'
                  className='grayscale'
                />
              </div>
              <div className='col-md-8'>
                <h3> Sustainable Environment and Climate​​</h3>
                <p>
                  Disaster risk is not always seen or acknowledged. India has
                  communities where disasters are an annual occurrence and
                  others who have no collective memory of the last emergency.
                  SEEDS works with both sets of communities to be better
                  prepared for future disasters, including range of climate
                  impacts, and reduce overall risk.
                </p>
                <p>
                  It starts with assessments, planning and training. Schools
                  play a pivotal role, working with them on holistic school
                  safety plans. We also support governments and communities on
                  their contingency and early warning plans.
                </p>
                <p>
                  Reducing risk also means acknowledging that a ‘disaster’
                  cannot be looked at in isolation. People face multi-faceted
                  problems in their neighbourhood. Each silent risk (e.g. air
                  pollution) or stresses such as access to clean water adds to
                  the vulnerability. We help communities find ways to solve or
                  better withstand their daily risks.
                </p>
                <p>
                  Finally, dealing with climate emergencies requires changes in
                  how we interact with nature. We work on practical
                  environmental and adaptation initiatives. From nature-based
                  solutions to managing financial risk; from mitigation
                  strategies to adapting livelihoods, building resilience and
                  supporting those who face displacement. Each of these is
                  tailored to the area’s ecology, needs and strengths.
                </p>
              </div>
            </div>
          </div>
          <div className='what-we-do-section'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/home/DisasterInnovation.jpg'
                  className='grayscale'
                />
              </div>
              <div className='col-md-8'>
                <h3>Resilience Partnership</h3>
                <p>
                  We use science to leave no one behind Disaster innovation
                  refers to the development and implementation of creative,
                  affordable and sustainable solutions and technologies to
                  mitigate the impact of disasters, enhance response & recovery
                  efforts and build resilience amongst vulnerable communities.
                  It involves leveraging existing knowledge, resources and
                  emerging technologies to address the challenges posed by
                  disasters and climate-induced risks.
                </p>
                <p>Some key aspects and examples of disaster innovation are:</p>
                <p>
                  Early Warning Systems that can detect and predict disasters
                  such as earthquakes, hurricanes, floods, cyclones and
                  tsunamis. These systems help to alert communities in advance,
                  allowing for timely evacuation and preparation.
                </p>
                <p>
                  Remote Sensing and GIS: Remote sensing technologies, including
                  satellite imagery and aerial drones, provide critical data for
                  disaster management. Geographic Information Systems (GIS) help
                  in mapping and analysing disaster-prone areas, aiding in
                  effective preparedness, response and recovery strategies.
                  SEEDS’ Sunny Lives model is one such example that leverages
                  satellite imagery to perform risk assessment and micro
                  forecasting of disasters in order to disseminate targeted
                  warnings. Besides saving lives, this technology enables
                  vulnerable communities to thrive despite climate and disaster
                  risks.
                </p>
                <p>
                  Communication and Information Management: During disasters,
                  communication is vital for coordinating response efforts and
                  disseminating timely information to affected communities.
                  Innovations such as mobile applications, social media
                  platforms, and emergency communication systems have improved
                  communication channels, facilitating real-time updates and
                  enabling individuals to seek help or report emergencies.
                </p>
                <p>
                  There are many other innovation examples that have the
                  potential to save lives and mitigates the risk of disasters.
                </p>
              </div>
            </div>
          </div>
          <div className='what-we-do-section'>
            <div className='row'>
              <div className='col-md-4'>
                <img
                  src='/assets/images/home/CommunitySkilling.jpg'
                  className='grayscale'
                />
              </div>
              <div className='col-md-8'>
                <h3> Tech for Resilience</h3>
                <p>
                  We use science to leave no one behind Disaster innovation
                  refers to the development and implementation of creative,
                  affordable and sustainable solutions and technologies to
                  mitigate the impact of disasters, enhance response & recovery
                  efforts and build resilience amongst vulnerable communities.
                  It involves leveraging existing knowledge, resources and
                  emerging technologies to address the challenges posed by
                  disasters and climate-induced risks.
                </p>

                <p>
                  We believe in participatory approach, contextual relevance,
                  holistic skill development, knowledge sharing & peer learning,
                  practical & experiential learning, sustainability & ownership
                  and multi-stakeholder collaboration that can help enhances
                  self-reliance, fosters resilience and promotes sustainable
                  development from within the community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default WhatWeDo;
