import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import VideoBreadCrumb from '../../components/common/VideoBreadCrumb';
import { QuoteCard } from './QuoteCard';

function GujratEarthquake() {
  return (
    <div id='wrapper'>
      <Header />
      {/* <VideoBreadCrumb title={'Our Story'} sub_title={'Our Story'} /> */}
      <BreadCrumb
        title={'Celebrating Resilience 20 Years of Gujrat Earthquake'}
        sub_title={'Our Story'}
        image={'/images/gujrat.webp'}
      />
      <div className='tf-section section-about style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <img src='/images/gujrat.webp' alt='Gujrat Earthquake 2001' />
            </div>
            <div className='col-md-12'>
              <div className='main-about'>
                <div className='content-right-about'>
                  <div className='info-about'>
                    <p className='text mt-4'>
                      On January 26th, 2001, a 6.9 magnitude earthquake
                      devastated the state of Gujarat causing extensive damage
                      to life and property. Over 13,881 lives were lost, 300,000
                      buildings collapsed and twice that number were damaged. As
                      we look back at the disaster since its occurrence,
                      flashback of different experiences twirl over us and those
                      who were a prima facie of the Great Gujarat Earthquake in
                      Bhuj.
                    </p>
                    <p>
                      At the time the region was already suffering from drought
                      conditions and the result of 2 cyclones in 3 years. 220
                      out of 276 houses in the village of Patanka were
                      completely destroyed by the earthquake and the rest were
                      severely damaged. The damage to building stock in the
                      region revealed the lack of adequately trained masons in
                      the traditional technology. Irrevocable scars of loss are
                      still etched in our hearts as we mark the 20th anniversary
                      of the devastating Gujarat earthquake. Here’s us
                      remembering one of India's most disastrous event that has
                      changed our lives forever.
                    </p>
                    <p>
                      It resulted in a paradigm shift in the policy of the
                      Government from relief and humanitarian assistance
                      oriented post-disaster intervention to a pro-active
                      prevention, mitigation and pre-disaster preparedness.
                      Comprehensive Gujarat State Disaster Management Policy was
                      declared in November 2002. Legal and Regulatory
                      requirement for effective disaster management resulted in
                      enactment of the Gujarat State Disaster Management Act in
                      March 2003. Gujarat is the first State in India to enact
                      an act for disaster management.
                    </p>
                    <p>
                      Today, we recall and remember the tragedy that will always
                      be in our memories that taught not just one but many
                      lessons. We also took this opportunity to hear from those
                      who have been directly impacted by the earthquake.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QuoteCard
            quotes={[
              `“I was with my fellow teachers when earthquake came in 2001.
                While the tremor subsided after sometime and we all were safe
                but we still can’t forget the feeling of experiencing it live”.`,
            ]}
            author={'Gadhavi Balavantdan K'}
            designation={'Govt. School Teacher, Panvi, Gujarat, India.'}
            video={'uPF7EajPjJk'}
          />
          <QuoteCard
            quotes={[
              `On 26 January 2001, the campus students and staff had gathered
              on the roof of the College for flag hoisting program. At that
              moment, we suddenly felt the earthquake tremor and our feet
              started vibrating. When I looked around, I felt as if all the
              buildings were swinging. We all rushed down quickly and
              experienced an atmosphere of fear! .`,
              ` We were completely unaware of what had happened! Gradually after
              things settled, we came to know that there has been an
              earthquake in Bhuj and its impact was seen all over Gujarat.
              With less preparedness measures, we were unaware of our do’s and
              don’ts! I was worried about my family and children. That
              atmosphere of fear I can never forget.`,
            ]}
            author={'Rajeshbhai Naranbhai Akhani'}
            video={'oe1q7GEKm2Q'}
            designation={'Govt. School Teacher, Radhanpur, Gujarat, India.'}
          />
          <QuoteCard
            quotes={[
              `“I was in Patanka when the earthquake came and was preparing for
              republic day celebration at my school. Before we could think of
              anything, the ground was strongly shaking making most of us
              fall. We later realised the damaged it ultimately caused making
              all the katcha houses collapse and damaging concrete buildings.
              We had never seen any such a disaster in our entire lifetime”.`,
            ]}
            author={'Dineshkumar Prajapati'}
            designation={'Govt. School Teacher,Kungher, Gujarat, India.'}
            video={'nNT_wtXpa10'}
          />
          <QuoteCard
            quotes={[
              `“We were getting ready to hoist the national flag to celebrate
              Republic Day that day. There was no house left standing
              everything was shattered because of the tremor. People were so
              scared that they kept running in panic. The incident did cause a
              great deal of mental trauma to all of us. It kept going on for a
              while – even the aftershocks kept coming one after the other”.`,
            ]}
            author={'Thakor Rameshbhai Dharsibhai'}
            designation={'Farmer and Mason, Patanka, Gujarat, India.'}
            video={'QlivMtpZtP0'}
          />
          <QuoteCard
            quotes={[
              `“I was in the first grade when earthquake hit 20 years back. It
              was republic day celebration going on in my school and we
              children thought may be some big fighter jet crashed on our
              school building. I still can't forget the disastrous scene I saw
              around me at that time”.`,
            ]}
            author={'Pritesh Babulal Thakor'}
            designation={'Support staff at SSNNL, Aabiyana, Gujarat, India.'}
            video={'imzJMUfxYjI'}
          />
          <QuoteCard
            quotes={[
              `“The moment earthquake hit, we saw electricity poles and school building falling. The scenario was such that for a moment, we thought there was a terrorist attack. Everybody ran out of the school as fast as they could. I am thankful to SEEDS team who reached out to us post disaster and built earthquake resilient houses. Not only did they build houses for us and the community, but they also trained us and further gave an opportunity to pass our learnings in different other states of the country”.`,
            ]}
            author={'Ahir Rana Bhai Manabhai'}
            designation={'Mason, Patanka, Gujarat, India.'}
            video={'AHCFw5-hTCE'}
          />
          <QuoteCard
            quotes={[
              `“People were crying under the rubble and we could not do anything! Gujarat Earthquake was a huge learning. School safety, hospital preparedness and complete reconstruction drive were important learnings that we developed from this experience!”`,
            ]}
            author={'Prof. Vinod Sharma'}
            designation={''}
            video={'5PhGhx7Do0o'}
          />
          <QuoteCard
            quotes={[
              `“After the Gujarat earthquake, shake table experiments were eye opener for local masons, engineers, communities and govt. officials. My main lesson from Gujarat earthquake… try to bring the lab to the fail!”`,
            ]}
            author={'Prof. Rajib Shaw'}
            designation={''}
            video={'wb5_MmDxY_Q'}
          />
          <QuoteCard
            quotes={[
              `“We were not prepared from the point of view of a disaster! Decisions were taken very fast to send senior officers from govt. of India to reiki the area and mount an immediate response. Crisis management in such situations plays an important role”.`,
            ]}
            author={'Anil Kumar Sinha'}
            designation={'IAS (rtd.)'}
            video={'QfdQoFndHuE'}
          />
          <QuoteCard
            quotes={[
              `“2001 Earthquake was the largest earthquake ever experienced by us. Entire Patanka village was under rubble. We were later given jobs by SEEDS too because of which we got the opportunity to travel to different states of the country and train other masons on how to build earthquake resilient houses”.`,
            ]}
            author={'Thakor Maganbhai Dharsibhai'}
            designation={'Farmer, Patanka, Gujarat, India'}
            video={'NGxMM5-DwJE'}
          />
          <QuoteCard
            quotes={[
              `“On January 26, at around 8:00 am morning we all were all sitting in the house when we felt the shock. We were scared and ran out of the house. Some people were also hurt and were later taken to the hospital. At that time our houses were raw, fragile and we were not very familiar with safe construction. Our house had also collapsed. We were living in a tent for two months. We had nothing as all our belongings were buried under the house. After about a month and a half later, institution SEEDS came and rebuilt many houses including ours as well”.`,
            ]}
            author={'Rabari Chenabhai Punabhai'}
            designation={'Daily wages Labour, Patanka, Gujarat, India.'}
            video={'68-YaAHUZVQ'}
          />
          <QuoteCard
            quotes={[
              `“We felt the tremors. We knew it was an earthquake- a powerful one. We remember that time every day and can’t believe that we witnessed such a disaster in our lives. We had to sleep outside for 15 days open in that cold. Following the earthquake aftershocks which were felt in the night. All the homes had collapsed. I recall that vision with my blur memory, when an officer came to the village, I saw him walking through the collapsed village. Government and many organisations supported us throughout and we also put our savings to re build a new house. Good or bad, everyone had to kneel before the God in the form of this natural calamity!”`,
            ]}
            author={'Aahir Karabhai Ravabhai'}
            designation={'Farmer, Patanka, Gujarat, India.'}
            video={'fn4TWIGhlSw'}
          />
          <QuoteCard
            quotes={[
              `"I was just five years old when the Great Gujarat earthquake hit parts of the state. I still remember, we were all gathered in our school’s assembly area ready for flag hoisting and then suddenly we saw our old school building collapsing in front of our eyes. Some new buildings got cracked too. We were so scared that for about a month we did not even enter our houses fearing that it may collapse over us. As I stand here an speak, I look back and see the kind of change has happened over the years. Our schools, communities, all have started giving the due importance to learning on disasters and how to keep ourselves safe when an incident like that occurs!"`,
            ]}
            author={'Gautam Thakor'}
            designation={'Site Supervisor with SEEDS.'}
            video={'hTKEydmWNYo'}
          />
          <QuoteCard
            quotes={[
              `In last 20 years from our experiences in Patanka, I have realized things are destined for a better future. If people can work together with sacrifice, love and charity, it’s the best time to do so, because that’s all the time we have!`,
            ]}
            author={'Chandra Bhakuni'}
            designation={'Structural Engineer, Ahmedabad, Gujarat, India.'}
            video={'DkYF4RuxMNc'}
          />
          <QuoteCard
            quotes={[
              `Being an earthquake survivor and then stepping into the humanitarian sector for the first time, the mission was to rise the people from the rubble. Engagement with communities was full of experiences and anecdotes!`,
            ]}
            author={'Shivangi Chavda'}
            designation={
              'Project Coordinator, Global Network of Civil Society Organisations for Disaster Reduction (GNDR)'
            }
            video={'9QqcfY6t_6o'}
          />
          <QuoteCard
            quotes={[
              `“The further we are away from the last earthquake, the closer we are to the next one! My learnings from Gujarat reshaped my views and practical wisdom!"`,
            ]}
            author={'Loy Rego'}
            designation={
              'Facilitator and learning practitioner at MARS Practitioners.'
            }
            video={'6bmA89X4N2k'}
          />
          <QuoteCard
            quotes={[
              `"The tectonic shift that occurred during Bhuj earthquake not only released devastating energy, it unlocked positive human energy that directed us in strengthening risk management in India".`,
            ]}
            author={'Vivek Rawal'}
            designation={'Director, People in Centre.'}
            video={'q9yzsos5nuY'}
          />
          <QuoteCard
            quotes={[
              `"I have seen #earthquakes destroying lives of people! Reconstruction project in Patanka was a learning curve for me; working with communities & telling them about disaster resilient buildings".`,
            ]}
            author={'Mihir Joshi'}
            designation={'Chief Convener, SEEDS.'}
            video={'JxnWrmZvNJY'}
          />
        </div>
        <div className='p-4'></div>
        <div className='row'>
          <div className='col-md-4'>
            <img src='/images/rekha.webp' alt='Rekha' />
          </div>
          <div className='col-md-8'>
            <p>
              The Kutch earthquake rehabilitation, in a small way, is a part of
              my story. I feel extremely fortunate to be part of this story
              where I have spent substantial period of my productive career. As
              a development worker, it was my first stint in disaster recovery,
              rehabilitation, and development. I was a student in the classroom
              of Kutch
            </p>
            <p>
              I witnessed astonishing rise of Kutch from rubble. Within one
              year, the rural rehabilitation was complete. I watched the speed
              in which four earthquake affected towns were rebuild with improved
              town planning and infrastructure. I watched in awe the revival of
              arts and crafts as well as restoration of architectural heritage.
              I saw the emergence of brand-new Kutch and I left with a sense of
              pride after seven eventful years of my life.
            </p>
            <p>
              There are many ways to narrate the story of Kutch. It is an
              extraordinary story of effective reconstruction and rehabilitation
              policy framework of Government of Gujarat which responded with
              great sense of urgency and sensitivity to the challenges of
              rehabilitation. It is a story of vibrant civil society which
              helped the communities to make an informed choice of recovery and
              rehabilitation through dissemination of technology and best
              practices. It an amazing story of whole nation and diaspora
              reaching out to Kutch. Last but not the least- It is a remarkable
              story of spirit and resilience of Kutchis.
            </p>
            <p>
              The fundamental to this story is relentless work of local
              visionaries NGOs and their commitment to the region. Experts say,
              it was one of the fastest and smoothest rehabilitation in the
              world. As local civil society leader rightly says- “Kutch has set
              a precedent for rehabilitation”.
            </p>
            <p>
              <strong>Ms. Rekha Shenoy</strong>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GujratEarthquake;
