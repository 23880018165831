import React from 'react';
import Slider from 'react-slick';
import { BANNERS } from '../../domain/modals/banners';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    />
  );
}

function BannerComponent() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    fade: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      className='hero-area hero-area-2'
      data-background='assets/img/slider/home2/home2.jpg'
    >
      <Slider {...settings} className='hero-slider hero-style-2'>
        {BANNERS &&
          BANNERS.map((item) => {
            return (
              <div className='hero-item hero-height p-relative'>
                <div className='tpslider__thumb '>
                  <img src={item.image} alt='SEEDS' className='desktop-only' />
                  <img
                    src={item.image_mobile}
                    alt='SEEDS'
                    className='mobile-only'
                  />
                </div>
                <div className='hero-item-box'>
                  <div className='hero-item-inner'>
                    <div className='container-fluid'>
                      <div className='row hero-plr-225'>
                        <div className='col-xl-12'>
                          <div className='tpslider__content mb-90'>
                            <h2 className='tphero__title'>
                              <div
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></div>
                            </h2>
                            <div
                              style={{
                                color: 'rgba(255,255,255,0.7)',
                                marginBottom: '20px',
                                fontSize: '18px',
                                animationDelay: '0.5s',
                                animationDuration: '1s',
                              }}
                              className='tpslider__content'
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.sub_heading,
                                }}
                              ></div>
                            </div>
                            <div className='hero-btn'>
                              <a
                                className='tp-btn tp-btn-shine-effect'
                                href={item.link}
                              >
                                {item.link_text}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </section>
  );
}

export default BannerComponent;
