import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

function Strategy2030() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Strategy 2030'}
        sub_title={'Strategy 2030'}
        image={'/uploads/2020/08/SEEDS-Strategy-2030-banner-image-1.jpg'}
      />
      <section className='tf-section section-services style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h4>ABOUT SEEDS</h4>
              <p>
                SEEDS (Sustainable Environment and Ecological Development
                Society) enables community resilience through practical
                solutions for disaster readiness, response and rehabilitation -
                grafting innovative technology on to traditional wisdom.
              </p>
              <h4>OUR VISION</h4>
              <p>
                Transforming the vulnerable into resilient and thriving
                communities.
              </p>
              <h4>CONTEXT</h4>
              <p>
                We have now done more damage to the environment since the United
                Nations established its climate change framework in 1992 than we
                ever managed in ignorance in all the millennia that preceded it.
                Thus, when a young Greta Thunberg is asking us to panic and act,
                it is for good reasons. Already at 1.1 degrees of average
                warming, we are seeing a lot of really extreme climate events.
              </p>
              <p>
                While India’s current carbon emissions per capita is little
                because of the sere size of the population, of all the most
                polluting nations, which includes US, China, Russia, Japan and
                the EU bloc; only India’s emissions are rising. It is especially
                worrying when you juxtapose it with the fact that it is a
                developing nation with two-thirds yet to be built. The worst
                affected by this climate emergency would be the vulnerable
                communities, including migrant population, who more often than
                not do not have the knowledge and resources to deal with it. The
                severity of this emergency can be elucidated by a recent
                research study which claims that climate change may have
                contributed to the suicides of nearly 60,000 Indian farmers and
                farm workers over the past three decades. Thus, the time to act
                is now or never.
              </p>
              <h4>WE ARE COMMITTED TO</h4>
              <p>
                <strong>
                  Enabling the bottom 1% of vulnerable to climate emergency, to
                  withstand it and secure their future potential and thereby
                  that of the larger community.
                </strong>
              </p>
              <p>
                We will work in 100 identified districts with a network of
                partners and young ambassadors and leverage our collective
                influence to achieve:
              </p>
              <ol>
                <li>
                  Communities in India’s high-risk zones are aware of their
                  risks, and know of actions to manage them
                </li>
                <li>
                  Enhanced response capacity to better anticipate and rapidly
                  respond
                </li>
                <li>Children go to schools resilient to climate change</li>
                <li>
                  Communities, vulnerable to disasters, are living in safer
                  homes with access to water and sanitation, and health
                  facilities
                </li>
                <li>Build capacities and resources at the local level </li>
                <li>
                  Promotion of investments in local innovation for resilience
                </li>
                <li>
                  Communities, affected by disasters, build back better rising
                  above the threshold of the next disaster
                </li>
                <li>
                  Tracking of losses avoided by vulnerable communities, as a
                  result of our actions
                </li>
              </ol>
              <div className='text-center'>
                <img
                  src='/assets/images/strategy/TheoryofChange.jpg'
                  style={{ width: '85%', marginBottom: '20px' }}
                />
                <img
                  src='/assets/images/strategy/ThematicFocusdesign.jpg'
                  style={{ width: '85%', marginBottom: '20px' }}
                />
              </div>

              <h4 className='mt-3'>
                WE ARE COMMITTED TO, AND WOULD VOLUNTARILY CONTRIBUTE TO
              </h4>
              <p>
                The goals set for 2030 in the Sendai Framework for Disaster Risk
                Reduction (SFDRR), Sustainable Development Goals (SDG) and the
                Paris Agreement.
              </p>
              <div className='text-center'>
                <img
                  src='/assets/images/strategy/roadmapgraphic.JPG'
                  style={{ width: '85%', marginBottom: '20px' }}
                />
              </div>

              <h4 className='mt-3'>THREE PILLARS</h4>
              <p>
                The Strategy will be implemented with the strength derived from
                three pillars:
              </p>
              <ol>
                <li>
                  Digital Platform: To achieve scale, SEEDS will build an
                  inclusive digital platform to enable exchange of knowledge,
                  practices and promote learning.
                </li>
                <li>
                  Network of Networks: SEEDS will build a network of
                  individuals, organisations, volunteers, staff to ensure
                  implementation capacity in all 100 districts.
                </li>
                <li>
                  Funding System: A robust funding system will be set in place
                  to power ten year programme; funding partnerships will be
                  established with partners organisations both local and
                  international, who share the same vision. New forms of funding
                  would be explored to enable access to the bottom 1%.
                </li>
              </ol>
              <h4>OUR CAPABILITY</h4>
              <p>
                Our confidence in designing this strategy comes from our 25
                years of ‘hands-on’ experience in the sector. SEEDS has worked
                with communities in India’s most remote and vulnerable
                communities – rebuilding their lives after disasters. The
                organization has developed strong capacity in handling large
                scale interventions while upholding highest global standards of
                quality and accountability. We have successfully advocated
                resilience approaches, influenced the policy environment
                globally, launched global action agendas, advised
                inter-governmental agencies, UN, governments and civil society.
                We have pioneered cutting edge approaches, supported community
                innovations, created spaces on the interface of high-end
                technology and community-based approaches. Affected people have
                been the core of our approach. We have leveraged their
                strengths, their traditions and their aspirations.
              </p>
              <p>
                SEEDS is now at a threshold of entering the next frame of
                action, with a larger agenda and a lean approach for deeper and
                longer lasting impact. A new organisational structure is in
                place. New planning and monitoring systems, a partnership
                network, digital technology as a driver for efficiency, a hybrid
                organisational approach deploying charity and social enterprise
                principles, is now in place.
              </p>

              <div>
                <a
                  className='link-main'
                  href='/assets/images/about/SEEDS-Strategy-2030.pdf'
                  target={'_blank'}
                >
                  {' '}
                  Download{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Strategy2030;
