import React, { useEffect, useState } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { PROJECTS } from '../../domain/modals/projects';
import { Link, useParams } from 'react-router-dom';
import DonationForm from '../../donation/DonationForm';
import { Helmet } from 'react-helmet';

function SingleCampaign({}) {
  const params = useParams();
  console.log('PARMAS', params);
  const [activeProject, setActiveProject] = useState(null);
  useEffect(() => {
    if (params.slug) {
      const filterData = PROJECTS.filter((item) => item.slug === params.slug);
      if (filterData) {
        setActiveProject(filterData[0]);
      }
    }
  }, [params.slug]);
  return (
    <div>
      {activeProject && (
        <div>
          <Helmet>
            <title>{activeProject.title ? activeProject.title : 'SEEDS'}</title>
            <meta
              name='description'
              content={activeProject.short_description}
            />
          </Helmet>
          <Header />
          <BreadCrumb
            title={activeProject.name}
            sub_title={'Campaigns'}
            image={
              activeProject.banner_image
                ? activeProject.banner_image
                : activeProject.image
            }
          />
          <div className='portfolio-details-area pt-70'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  {activeProject.type === 'donation' && (
                    <h3 className='mb-3'> {activeProject.title} </h3>
                  )}

                  <div className='campaign-wrapper'>
                    {activeProject.type === 'donation' && (
                      <>
                        <div
                          className='campaign-image-section'
                          style={{
                            width: activeProject.custom_image ? '50%' : '45%',
                          }}
                        >
                          <img
                            src={activeProject.image}
                            // className='banner-main-image'
                            style={{
                              width: '100%',
                              paddingRight: '20px',
                              marginBottom: '20px',
                            }}
                          />
                          {activeProject.image_caption && (
                            <p>
                              <em>{activeProject.image_caption}</em>
                            </p>
                          )}
                        </div>

                        <div>
                          <a
                            class='tp-btn tp-btn-shine-effect'
                            href='/donation'
                          >
                            DONATE VIA ONLINE PAYMENT GATEWAY
                          </a>
                        </div>
                        <div>
                          <Link
                            class='tp-btn tp-btn-shine-effect'
                            to='/banktransfers'
                          >
                            DO A DIRECT BANK TRANSFER
                          </Link>
                        </div>
                      </>
                    )}

                    <div
                      dangerouslySetInnerHTML={{
                        __html: activeProject.content,
                      }}
                    ></div>
                  </div>
                </div>
                {/* <div
                  className='col-xl-7 wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='.5s'
                >
                  <div className='service-section portfolio-section mt-30'>
                    <h3 className='service-title'>{activeProject.name}</h3>
                    <div className='portfolio-details mt-30'>
                      <span className='portfolio-details-title'>
                        Project Overview
                      </span>
                      <p>{activeProject.short_description}</p>
                      <span className='portfolio-details-title'>
                        Goal Achieved
                      </span>
                      <div className='tpcard__progresss'>
                        <div className='progress donation-details'>
                          <div
                            className='progress-bar donation-details'
                            role='progressbar'
                            aria-label='Example with label'
                            style={{ width: '65%' }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span>80%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='col-xl-5 wow fadeInRight'
                  data-wow-duration='1s'
                  data-wow-delay='.5s'
                >
                  <div>
                    <DonationForm />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div
            className='portfolio-details-thumb-video wow fadeInUp'
            data-wow-duration='1s'
            data-wow-delay='.5s'
          >
            <div className='container'>
              <div className='row'>
                <div className='col-xl-6'></div>
                <div className='col-md-12'>
                  <div className='service-text'>
                    <img
                      src='/assets/img/portfolio/portfolio-details/details1.jpg'
                      style={{
                        width: '50%',
                        float: 'left',
                        marginRight: '10px',
                        marginBottom: '10px',
                      }}
                    />
                    <p>
                      “I drive an auto to provide for my family. During the
                      summer, I drive around the city in extreme heat and feel
                      even worse by the time I get home at night. I can’t think
                      of any other disaster like earthquakes or floods; summers
                      are itself a disaster for me, affecting both my health and
                      livelihood costs,” says Deep Chand Kumar, a community
                      member from East Delhi.{' '}
                    </p>

                    <p>
                      Each year, heatwaves in India makes life miserable for the
                      vulnerable communities, by threatening and challenging
                      each stage of their day. They fight hard but unfortunately
                      health conditions deteriorate for which they succumb to
                      the extreme conditions.
                    </p>
                    <p>
                      March 2022 turned out to be India’s hottest ever March in
                      122 years reported by the India meteorological department
                      (IMD) and this year as the country recorded its highest
                      ever maximum temperature in February in most of the
                      states, it is likely to witness relentless and lethal heat
                      waves between April and June according to the National
                      Weather Bureau.
                    </p>
                    <p>
                      Heat waves are disrupting weather patterns, leading to
                      extreme weather events, unpredictable water availability,
                      exacerbating water scarcity and contaminating water
                      supplies. Such impacts can drastically affect the quantity
                      and quality of water that people need to survive. Last
                      year between March and May, Delhi and many other states
                      experienced heat waves with record-breaking temperatures
                      reaching up to 49.2 degrees Celsius, leading to
                      devastating socioeconomic and public health impacts.
                    </p>
                    <p>
                      {' '}
                      <strong>Our Solution:</strong>{' '}
                    </p>
                    <p>
                      Being proactive to such initiative shows your passion to
                      protect and fulfil not a mere goal but create impact with
                      examples. That is how SEEDS team on the ground has
                      identified areas with multiple risk factors in Delhi and
                      several other Heat Alert states focusing on the most
                      marginalised individuals and communities that are in
                      urgent need of interventions to cope with the dire
                      situation. Elderly, children and women are among the most
                      vulnerable amid the hot weather and we need to take extra
                      care of their health to prevent heat related illnesses
                      such as heat stress and heat stroke. Individuals such as
                      migrant workers working outside at peak hours, for
                      example, and continuous exposure to heat/cold waves
                      inflict severe health impacts, as well as the economic
                      loss that follows with health loss.
                    </p>
                    <p>
                      Through solutions such as cool roofing, which are designed
                      to reflect more sunlight than a conventional roof,
                      absorbing less solar energy; this will support in reducing
                      the local ambient temperatures. The other solutions
                      include restoration of water resources, water stations at
                      major crowded locations, engaging with school communities
                      on the effects of heatwaves, among several others – we
                      will reach out to over 14000 people providing them with
                      shelters, relocation to other houses during the peak
                      summers, water filters and street shading.
                    </p>
                    <p>
                      The IMD expects that after March, the country will only
                      get hotter. Heat waves will be more likely, and the
                      country will need to prepare for them. With your
                      assistance and motivation, our team will be able to
                      provide heat relief all summer long. Your support can be a
                      life saver for the vulnerable during the hot sweltering
                      months.
                    </p>
                    <p>
                      {' '}
                      <strong>Targeted Geography:</strong>{' '}
                    </p>
                    <p>
                      SEEDS intervention will focus on the areas falling under
                      the Core Heatwave Zone (CHZ), covering states like Delhi,
                      Haryana, Bihar, Himachal Pradesh, Uttarakhand, Uttar
                      Pradesh, Gujarat, Maharashtra and West Bengal.
                    </p>
                    <p>
                      We urge you to spare a moment and lend your support to the
                      individuals in need and the communities who are vulnerable
                      to these rising temperatures across these targeted states.
                    </p>
                    <p>
                      We seek your support to ensure we reach out to the
                      communities till last miles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className='portfolio-details-area-2 pt-90 '>
            <div className='container'>
              <div className='row'>
                <div
                  className='col-xl-12 wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='.5s'
                >
                  <div className='service-section portfolio-section'>
                    <h3 className='section-title '>How you can contribute:</h3>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center'>
                <div className='col-md-4'>
                  <div className='contribute-card'>
                    <div className='contribute-title'>
                      Conduct heatwave awareness campaigns
                    </div>
                    <div className='contribute-content'>
                      To support 10 women-led volunteer groups
                    </div>
                    <div className='contribute-amount'>
                      Rs. 10,000 per group
                    </div>
                    <div className='contribute-donation'>
                      <div>
                        <a
                          className='tp-btn tp-btn-shine-effect'
                          href='/campaigns/making-communities-resilient-during-heatwaves'
                        >
                          Donate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='contribute-card'>
                    <div className='contribute-title'>
                      Conduct heatwave awareness campaigns
                    </div>
                    <div className='contribute-content'>
                      To support 10 women-led volunteer groups
                    </div>
                    <div className='contribute-amount'>
                      Rs. 10,000 per group
                    </div>
                    <div className='contribute-donation'>
                      <div>
                        <a
                          className='tp-btn tp-btn-shine-effect'
                          href='/campaigns/making-communities-resilient-during-heatwaves'
                        >
                          Donate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='contribute-card'>
                    <div className='contribute-title'>
                      Assist with cool roofs
                    </div>
                    <div className='contribute-content'>
                      To help reduce the local ambient temperature for a home
                      and family
                    </div>
                    <div className='contribute-amount'>Rs. 5000</div>
                    <div className='contribute-donation'>
                      <div>
                        <a
                          className='tp-btn tp-btn-shine-effect'
                          href='/campaigns/making-communities-resilient-during-heatwaves'
                        >
                          Donate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='contribute-card'>
                    <div className='contribute-title'>
                      Restoration / installation of water resources
                    </div>
                    <div className='contribute-content'>
                      To assist 16 families with safe drinking water
                    </div>
                    <div className='contribute-amount'>Rs. 2000</div>
                    <div className='contribute-donation'>
                      <div>
                        <a
                          className='tp-btn tp-btn-shine-effect'
                          href='/campaigns/making-communities-resilient-during-heatwaves'
                        >
                          Donate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <Footer />
        </div>
      )}
    </div>
  );
}

export default SingleCampaign;
