import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function MediaRelease() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Media Release'}
        sub_title={'What We Do'}
        image={'/uploads/2020/12/DSC_2909.jpg'}
      />
      <section
        className='tpblog-area media-release pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/blogs/2/media-release.jpg' alt='' />
              </div>
              <p>
                SEEDS&#39; Project Ashraya India Flood Relief Rebuilds Lives and
                Brings Hope to Cachar, Assam
              </p>
              <div>
                <a href='/assets/images/blogs/2/media-release.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/Wazirabad-Lake.jpg' alt='' />
              </div>
              <p>
                SEEDS restores Wazirabad lake in Gurugram through nature-based
                solutions
              </p>
              <div>
                <a href='/uploads/2023/04/Press-Release-SEEDS-Wazirabad-Lake-Restoration.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img
                  src='/assets/images/resources/SEEDS-Chegg-Digital-Bus-scaled.jpg'
                  alt=''
                />
              </div>
              <p>
                Chegg collaborates with SEEDS to launch a Digital Mobile Bus
                Library in Delhi
              </p>
              <div>
                <a href='/uploads/2023/04/Press-Release-SEEDS-Chegg-Digital-Mobile-Bus-Library-Launch.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/CDP_SEEDS.jpg' alt='' />
              </div>
              <p>
                SEEDS collaborates with the Center for Disaster Philanthropy to
                rebuild lives in Assam
              </p>
              <div>
                <a href='/uploads/2022/12/Press-Release-SEEDS-CDP-collaboration-for-Cachar-flood-relief.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img
                  src='/assets/images/resources/Chegg-and-SEEDS.jpg'
                  alt=''
                />
              </div>
              <p>
                Chegg and SEEDS encourage healthy-eating behavior in schools
                with nutrition kits
              </p>
              <div>
                <a href='/uploads/2022/11/Press-Release-SEEDS-Chegg-Delhi-Vizag-Nutrition-Kit-Event.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/pressrelase.jpg' alt='' />
              </div>
              <p>SEEDS receives prestigious United Nations Sasakawa Award</p>
              <div>
                <a href='/uploads/2022/04/Press-Release-SEEDS-Chegg-East-Delhi-School-Event.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/chegg.jpg' alt='' />
              </div>
              <p>
                Chegg India partners with SEEDS to improve learning environment
                for children with school furniture
              </p>
              <div>
                <a href='/uploads/2022/04/Press-Release-SEEDS-Chegg-East-Delhi-School-Event.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img
                  src='/assets/images/resources/A-Roof-for-Education.jpg'
                  alt=''
                />
              </div>
              <p>
                A Roof for Education: Climate-friendly community school built
                under flyover without brick or mortar
              </p>
              <div>
                <a href='/uploads/2022/04/SEEDS-Better-Shelter-School-Press-Release.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/Anshu_Award.jpg' alt='' />
              </div>
              <p>
                The Subhash Chandra Bose Aapda Prabandhan Puraskar 2021 awarded
                to SEEDS by the Prime Minister of India
              </p>
              <div>
                <a href='/uploads/2022/01/SEEDS-receives-SCBAPP-2021.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/assets/images/resources/seedsmicro.jpg' alt='' />
              </div>
              <p>
                SEEDS partners with Microsoft India to launch Phase II of Sunny
                Lives
              </p>
              <div>
                <a href='/uploads/2021/07/Press-Release-Sunny-Lives-_July_23_2021-approved-by-MS-Comms.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/uploads/2021/04/Covidpressrelease.jpg' alt='' />
              </div>
              <p>Press Release on Covid-19 Second Wave Response 2021</p>
              <div>
                <a href='/uploads/2021/05/Final-Press-Release-on-Covid-19-2nd-Wave-AS-MG-AS-050521V2.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img
                  src='/uploads/2021/01/subhash_Chandra_Award_media.jpg'
                  alt=''
                />
              </div>
              <p>Subhash Chandra Bose Aapda Prabandhan Puraskar 2021</p>
              <div>
                <a href='/uploads/2021/01/Press-Release_SEEDS-recognised-with-Subhash-Chandra-Bose-Aapda-Prabandhan-Puraskar-2021-AS-MG-AS-25.01.2021.pdf'>
                  Download Release
                </a>
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <img src='/uploads/2020/12/honeywellsaf.jpg' alt='' />
              </div>
              <p>
                Unveiling of renovated and retrofitted schools in Uttarakhand by
                Hon’ble CM Shri Trivendra Singh Rawat at the ‘Making Schools
                Safer’ event on 17 December 2020, Dehradun.
              </p>
              <div>
                <a href='/uploads/2020/12/Press-Release-Honeywell-and-SEEDS-Unvieiling-schools-Uttarkhand-171220.pdf'>
                  Download Release
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default MediaRelease;
