export const REPORTS = [
  {
    title: 'Annual Report 2017 – 2018',
    image: '/assets/images/reports/report-2017-18.jpg',
    link: '/uploads/2018/12/Annual-Report-17-18.pdf',
  },
  {
    title: 'Annual Report 2016 – 2017',
    image: '/assets/images/reports/report-2006-1-1.jpg',
    link: '/uploads/2018/11/Annual-Report-16-17.pdf',
  },
  {
    title: 'Annual Report 2015 – 2016',
    image: '/assets/images/reports/anualreport15.jpg',
    link: '/uploads/2018/01/Annual-Report-15-16.pdf',
  },
  {
    title: 'Annual Report 2014 – 2015',
    image: '/assets/images/reports/report14-15.jpg',
    link: '/uploads/2018/07/Annual_Report_2014-2015_final_MC_230216.pdf',
  },
  {
    title: 'Annual Report 2013 – 2014',
    image: '/assets/images/reports/report13-14.jpg',
    link: '/uploads/2020/Annual-Report-2013-2014.pdf',
  },
  {
    title: 'Annual Report 2012 – 2013',
    image: '/assets/images/reports/1213-1.jpg',
    link: '/uploads/2017/07/ANNUAL-REPORT-2012-13-low.pdf',
  },
  {
    title: 'Annual Report 2011 – 2012',
    image: '/assets/images/reports/1112-1.jpg',
    link: '/uploads/2018/09/Annual-report-2011-12-low.pdf',
  },
  {
    title: 'Annual Report 2010 – 2011',
    image: '/assets/images/reports/10-11-1.jpg',
    link: '/uploads/2018/09/annualreport-2010-2011.pdf',
  },
  {
    title: 'Annual Report 2009 – 2010',
    image: '/assets/images/reports/Pic-AR-09-10-1-1.jpg',
    link: '/uploads/2018/09/Annual-Report-09-10.pdf',
  },
  {
    title: 'Annual Report 2008 – 2009',
    image: '/assets/images/reports/Pic-AR-08-09-1-1.jpg',
    link: '/uploads/2018/09/annual-report-2008-2009.pdf',
  },
  {
    title: 'Annual Report 2007 – 2008',
    image: '/assets/images/reports/report-2007-1.jpg',
    link: '/uploads/2018/09/annualreport2007-2008.pdf',
  },
  {
    title: 'Annual Report 2006 – 2007',
    image: '/assets/images/reports/report-2006-1.jpg',
    link: '/uploads/2018/09/annualreport2006-2007.pdf',
  },
  {
    title: 'Annual Report 2005 – 2006',
    image: '/assets/images/reports/report-2005-1.jpg',
    link: '/uploads/2018/09/annualreport2005-2006.pdf',
  },
  {
    title: 'Annual Report 2003 – 2004',
    image: '/assets/images/reports/report2003-1.jpg',
    link: '/uploads/2018/09/annual-report-2003-2004.pdf',
  },
];
