import React from 'react';
import Slider from 'react-slick';
import { PROJECTS } from '../../domain/modals/projects';
import CasueCard from '../campaigns/CauseCard';
import CauseCard from '../campaigns/CauseCard';
import { Link } from 'react-router-dom';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    />
  );
}
function Campaigns() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <section className='card-area card-area-2 pt-130 pb-130'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-9 col-lg-9 col-md-8'>
            <div className='section-title-wrapper'>
              {/* <span className='section-sub-title has-before'>
                We help around the world
              </span> */}
              <h3 className='section-title'>Bring a Transformation </h3>
            </div>
          </div>
          <div className='col-xl-3 col-lg-3 col-md-4 desktop-only'>
            <div
              className='tpcard-btn d-flex justify-content-end  align-items-center'
              style={{ height: '100%' }}
            >
              <Link className='custom-button' to='/campaigns'>
                All Campaigns
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Slider {...settings} className='slick-20'>
            {PROJECTS &&
              PROJECTS.map((item) => {
                if (item.type === 'donation' && !item.hide) {
                  return (
                    <div>
                      <CauseCard item={item} />
                    </div>
                  );
                }
              })}
          </Slider>
        </div>
        <div className='text-center mt-2 mobile-only'>
          <Link className='custom-button' to='/campaigns'>
            All Campaigns
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Campaigns;
