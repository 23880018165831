export const BRANDS = [
  // '/assets/img/brands/australian_gov_revised.jpg',
  // '/assets/img/brands/IMC.jpg',
  '/assets/img/brands/near.jpg',
  // '/assets/img/brands/asianpains_revised.jpg',
  // '/assets/img/brands/schneider_logo.jpg',
  // '/assets/img/brands/nationaldisastermanagement.jpg',
  '/assets/img/brands/adrrn2_revised.jpg',
  // '/assets/img/brands/dxc.jpg',
  // '/assets/img/brands/ministeryofwoen.jpg',
  // '/assets/img/brands/adrrn_revised.jpg',
  '/assets/img/brands/credibilityalliacen.jpg',
  // '/assets/img/brands/wetlands.jpg',
  // '/assets/img/brands/ibm.jpg',
  // '/assets/img/brands/unitedwaymumbai.jpg',
  // '/assets/img/brands/grofers_revised.jpg',
  '/assets/img/brands/unitedwayindia.jpg',
  '/assets/img/brands/gpay_revised.jpg',
  // '/assets/img/brands/unicef.jpg',
  // '/assets/img/brands/google.jpg',
  '/assets/img/brands/Undrr.jpg',
  // '/assets/img/brands/godrej.jpg',
  // '/assets/img/brands/tata.jpg',
  '/assets/img/brands/gndr_revised.jpg',
  '/assets/img/brands/startnetwork.jpg',
  // '/assets/img/brands/give2asia.jpg',
  '/assets/img/brands/every.jpg',
  '/assets/img/brands/myramidusa.png',
  '/assets/img/brands/sphereindia.jpg',
  '/assets/img/brands/ericssion.png',
  '/assets/img/brands/ey.png',
  '/assets/img/brands/caf-america.png',
  // '/assets/img/brands/cognigent.jpg',
  // '/assets/img/brands/phonepe.jpg',
  '/assets/img/brands/bhimupi_revised.jpg',
  // '/assets/img/brands/pepsico.jpg',
  // '/assets/img/brands/ciifoundationrevised.jpg',
  // '/assets/img/brands/partnersofresileance.jpg',
  // '/assets/img/brands/bata_revised.jpg',
  '/assets/img/brands/near.jpg',
  '/images/donors/mercy.png',
  '/images/donors/imc.png',
  '/images/donors/paulhamlyn.png',
  '/images/donors/heifer.png.webp',
  '/images/donors/savethechildren.jpg',
  '/images/donors/med-global.png',
  '/images/donors/cdp.png',
  '/images/donors/chegg.webp',
  '/images/donors/pwc.svg.png',
  '/images/donors/capco.svg.png',
  '/images/donors/microsoft.avif',
  '/images/donors/interglobe.jpg',
  '/images/donors/ingersoll.jpg',
  '/images/donors/hinduja.png',
  '/images/donors/care-today.png',
  '/images/donors/nvidia.avif',
  '/images/donors/nike.jpg',
  '/rnp-logo.png',
  // '/images/donors/tnpcb.jpeg',
];
