import React from 'react';
import Header from '../../components/common/Header';
import BannerComponent from '../../components/Home/BannerComponent';
import AboutSection from '../../components/Home/AboutSection';
import GalleryArea from '../../components/Home/GalleryArea';
import Campaigns from '../../components/Home/Campaigns';
import FeaturedCampaign from '../../components/Home/FeaturedCampaign';
import VideoSection from '../../components/Home/VideoSection';
import VolunteerSection from '../../components/Home/VolunteerSection';
import ProjectSection from '../../components/Home/ProjectSection';
import TestimonialSection from '../../components/Home/TestimonialSection';
import BrandSection from '../../components/Home/BrandSection';
import BlogSection from '../../components/Home/BlogSection';
import Footer from '../../components/common/Footer';
import ProjectCard from '../../components/Home/ProjectCard';
import Accountability from '../../components/Home/Accountability';
import { Helmet } from 'react-helmet';

export const Home = (props) => {
  // useImportScript('/javascript/jquery.min.js');
  return (
    <div>
      <Helmet>
        <title>Home - SEEDS</title>
        <meta
          name='description'
          content='Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.'
        />

        <meta property='og:title' content={`Home - SEEDS`} />
        <meta
          property='og:description'
          content={`Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.`}
        />
        <meta
          property='og:image'
          content={`https://www.seedsindia.org/assets/images/banners/Sasakawa-award-image-touchup.jpg`}
        />
        <meta property='og:url' content={window.location.href} />
        <meta name='twitter:title' content={`Home - SEEDS`} />
        <meta
          name='twitter:description'
          content={`Founded in 1994, SEEDS had one ultimate goal: building the resilience of people exposed to disasters and climate change impacts. For the last 30 years, we have been enabling this through practical solutions for disaster readiness, response and rehabilitation. Our approach has involved grafting innovative technology on to traditional wisdom.`}
        />
        <meta
          name='twitter:image'
          content={`https://www.seedsindia.org/assets/images/banners/Sasakawa-award-image-touchup.jpg`}
        />
      </Helmet>
      <div>
        <Header />
        <main>
          {/* hero area start */}
          <BannerComponent />
          {/* hero area end */}
          {/* features-2 area start */}
          <AboutSection />
          {/* features-2 area end */}
          <VideoSection />
          {/* gallery area 2 start */}

          {/* gallery area 2 end */}
          {/* card area start */}
          <Campaigns />
          {/* card area end */}
          <ProjectSection />
          {/* Volunteers area start */}
          <VolunteerSection />
          <Accountability />
          <ProjectCard />
          <BrandSection />
          {/* <FeaturedCampaign /> */}
          {/* volunteers area end */}
          {/* about area 2 start */}

          {/* about area 2 end */}
          {/* volunteers area 3 start */}

          {/* volunteers area 3 end */}
          {/* our project area start */}

          {/* our project area end */}
          {/* testimonial area start */}

          {/* <TestimonialSection /> */}
          {/* testimonial area end */}
          {/* brand area start */}

          {/* brand area end */}
          {/* blog area start */}
          {/* <BlogSection /> */}
          {/* <GalleryArea /> */}
          {/* blog area end */}
        </main>
        <Footer />
      </div>
    </div>
  );
};
