import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { RESEARCH_ADVOCACY } from '../../domain/modals/resources';

function ResearchAdvocacy() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'Research & Advocacy'} sub_title={'What We Do'} />
      <section
        className='tpblog-area research pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <a href="/uploads/2020/10/Local-Leadership-for-Disaster-Resilience-Profiles-from-Asia-and-the-Pacific.pdf'">
                <img src='/uploads/2020/10/localleadership3-1.jpg' />
              </a>
            </div>
            <div className='col-md-8'>
              <h3>
                Local Leadership for Disaster Resilience – Profiles from Asia
                and the Pacific
              </h3>
              <p>
                As disaster risks are growing more intense, complex, and
                unpredictable, it calls for a new approach to resilience
                building and planning. This is a challenge that cannot be
                addressed by formal institutions alone. Local leaders often
                possess the capacity to innovate and have a strong understanding
                of local systems, politics, and culture. Recognising the
                importance of such local leaders, SEEDS through the course of
                disaster risk reduction and response work, actively engages in
                the identification of and capacity building of such leaders to
                cope with disaster risks and aid them to reduce the
                vulnerabilities in their communities.
              </p>
              <div>
                <a href='/uploads/2020/10/Local-Leadership-for-Disaster-Resilience-Profiles-from-Asia-and-the-Pacific.pdf'>
                  Download
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className='row'>
            {RESEARCH_ADVOCACY &&
              RESEARCH_ADVOCACY.map((item) => {
                return (
                  <div
                    className='col-md-4 mb-5'
                    style={{
                      borderBottom: '1px solid #f1f1f1',
                      paddingBottom: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <a href={item.download_link}>
                      <img src={item.image} />
                    </a>
                    <h3> {item.name} </h3>
                    <p> {item.content} </p>
                    <div>
                      <a href={item.download_link}> Download </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ResearchAdvocacy;
