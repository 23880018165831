import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function EducationLearning() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Education and Training'}
        image={'/uploads/2017/07/IMG_0417-1-1.jpg'}
      />
      <section
        className='tpblog-area research pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <a href='/uploads/2020/08/Earthquake-Safety-Handbook.pdf'>
                <img src='/uploads/2020/08/earthquake.jpg' alt='SEEDS' />
              </a>
            </div>
            <div className='col-md-6'>
              <h3>Earthquake Safety during COVID-19</h3>
              <p>A guide for your family</p>
              <p>
                How will you keep your family safe if an earthquake strikes
                during COVID-19? Here is an interactive guide book with
                information on earthquake risk, safety measures and how to be
                prepared, not scared!
              </p>
              <div>
                <a href='/uploads/2020/08/Earthquake-Safety-Handbook.pdf'>
                  Download
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-4 mb-30'>
              <a href='/uploads/2020/07/Task-force-handbook-English.pdf'>
                <img src='/uploads/2020/07/communityfirst.jpg' />
              </a>
              <h4>Community as First Responder</h4>
              <p>
                What are key lessons that are required to serve as a first
                responder within your community? This offers basic tips that are
                broadly applicable.
              </p>
              <div>
                <a href='/uploads/2020/07/Task-force-handbook-English.pdf'>
                  {' '}
                  Download{' '}
                </a>
              </div>
            </div>
            <div className='col-md-4 mb-30'>
              <a href='/uploads/2020/Education-training/Kerala%20Safe%20Return%20Home%20Guidelines.pdf'>
                <img src='/uploads/2020/07/kerela-safte.jpg' />
              </a>
              <h4>Kerala Safe Return Home Guidelines</h4>
              <p>
                A set of guidelines developed after the Kerala Floods in 2018,
                which showed how to evaluate if your home was safe enough for
                your return. Available in English and Malayalam.
              </p>
              <div>
                <a href='/uploads/2020/Education-training/Kerala%20Safe%20Return%20Home%20Guidelines.pdf'>
                  {' '}
                  Download{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default EducationLearning;
