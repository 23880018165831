import React, { useEffect, useState } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { PRODUCT } from '../../domain/modals/product';
import { Link, useParams } from 'react-router-dom';
import DonationForm from '../../donation/DonationForm';

function SinglePortfolio({}) {
  const params = useParams();
  console.log('PARMAS', params);
  const [activeProject, setActiveProject] = useState(null);
  useEffect(() => {
    if (params.slug) {
      const filterData = PRODUCT.filter((item) => item.slug === params.slug);
      if (filterData) {
        setActiveProject(filterData[0]);
      }
    }
  }, [params.slug]);
  return (
    <div>
      {activeProject && (
        <div>
          <Header />
          <BreadCrumb
            title={activeProject.name}
            image={activeProject.img}
            sub_title={'Campaigns'}
          />
          <div className='portfolio-details-area pt-70'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div
                    className='portfolio-image'
                    style={{ textAlign: 'center' }}
                  >
                    <img src={activeProject.img} />
                  </div>
                  <p className='text-center'>
                    <em> {activeProject.image_caption} </em>
                  </p>
                  <div
                    className='d-flex '
                    style={{ gap: '10px', marginTop: '20px', flexWrap: 'wrap' }}
                  >
                    {activeProject.tags &&
                      activeProject.tags.map((item) => {
                        return (
                          <div
                            style={{
                              border: '1px solid #f1f1f1',
                              padding: '10px 10px ',
                              background: '#f1f1f1',
                            }}
                          >
                            {' '}
                            {item}{' '}
                          </div>
                        );
                      })}
                  </div>
                  <div className='portfolio-content'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: activeProject.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
}

export default SinglePortfolio;
