import React from 'react';

export const SeedsReflections = () => {
  return (
    <div>
      <iframe
        src='https://hpfr-zc1.campaign-view.in/ua/viewinbrowser?od=3zc0f6fff6232fb430aaecd22486e7f6289fa0928f208b38e550506feb58dd7f3b&rd=18e77a5a472b145&sd=18e77a5a472c7e3&n=11699e4c0aa8294&mrd=18e77a5a472c7c7&m=1'
        frameborder='0'
      ></iframe>
    </div>
  );
};
