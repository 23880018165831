import React from 'react';
import { BLOGS } from '../../containers/blogs/blogs_enums';
import { Link } from 'react-router-dom';

function BlogSection() {
  return (
    <section className='tpblog-area pb-130 pt-125'>
      <div className='container'>
        {/* <div className='row'>
          <div className='col-xl-12'>
            <div className='tpblog'>
              <div className='section-title-wrapper'>
                <span className='section-sub-title has-before has-after text-center'>
                  Our Article and Blog
                </span>
                <h3 className='section-title text-center'>
                  Check latest blog post
                </h3>
              </div>
            </div>
          </div>
        </div> */}
        <div className='row tp-gx-40'>
          {BLOGS &&
            BLOGS.map((blog) => {
              return (
                <div className='col-xl-6 col-lg-6'>
                  <div
                    className='tpcard__wrapper mt-30 wow fadeInLeft'
                    data-wow-duration='1s'
                    data-wow-delay='.3s'
                  >
                    <div className='tpblog__thumb'>
                      <Link to={`/blogs/${blog.slug}`}>
                        <img
                          className='grayscale'
                          src={blog.featured_image}
                          style={{ height: '350px', objectFit: 'cover' }}
                        />
                      </Link>
                    </div>
                    <div className='tpblog__content'>
                      <h3 className='tpblog__title'>
                        <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                      </h3>
                      <div className='blog-details-info d-flex mb-3'>
                        <div className='blog-details-name'>
                          <i className='fa-regular fa-circle-user' />
                          <span>{blog.author}</span>
                        </div>
                        <div className='blog-details-date'>
                          <i className='fa-regular fa-clock' />
                          <span>{blog.date}</span>
                        </div>
                      </div>
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          height: '80px',
                        }}
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                      ></div>
                      <p></p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
