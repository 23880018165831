import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <header id='header-sticky' className='transparent-header header__sticky'>
        <div className='header-area header-area-2 header__space '>
          <div className='container-fluid'>
            <div className='row align-items-center justify-content-between'>
              <div className='col-xl-1 col-lg-1 col-md-6 col-6'>
                <div className='logo'>
                  <Link to='/'>
                    <img alt='logo' src='/assets/img/logo/seedslogo.png' />
                  </Link>
                </div>
              </div>
              <div className={'d-none d-xl-block col-xl-9 col-lg-9'}>
                <div className='main-menu main-menu-2 menu__space'>
                  <nav id='tp-mobile-menu'>
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li className='has-dropdown'>
                        <a href='#'>About Us</a>
                        <ul className='submenu'>
                          <li>
                            <Link to='/our-story'>Our Story</Link>
                          </li>
                          <li>
                            <Link to='/strategy-2030'>Strategy 2030</Link>
                          </li>
                          <li>
                            <Link to='/our-board'>Our Board</Link>
                          </li>
                          <li>
                            <Link to='/founders'>Founders</Link>
                          </li>
                          <li>
                            <Link to='/leadership-team'>Leadership Team</Link>
                          </li>
                          <li>
                            <Link to='/awards'>Awards & Honours</Link>
                          </li>
                        </ul>
                      </li>
                      <li className='has-dropdown'>
                        <a href='#'>Our Work</a>
                        <ul className='submenu'>
                          <li>
                            <Link to='/what-we-do'>What We Do</Link>
                          </li>
                          <li>
                            <Link to='/seeds-in-action'>SEEDS in Action</Link>
                          </li>
                        </ul>
                      </li>
                      <li className='has-dropdown'>
                        <a href='#'>Accountability</a>
                        <ul className='submenu'>
                          <li>
                            <Link to='/certifications-alliances'>
                              Certification & Alliances
                            </Link>
                          </li>
                          <li>
                            <Link to='/ways-of-working/'>Ways of working</Link>
                          </li>
                          <li>
                            <Link to='/annual-reports'>Annual Reports</Link>
                          </li>
                          <li>
                            <Link to='/financials'>Financials</Link>
                          </li>
                          <li>
                            <Link to='/testimonials'>Testimonials</Link>
                          </li>
                          <li>
                            <Link to='/policies'>Policies</Link>
                          </li>
                        </ul>
                      </li>

                      <li className='has-dropdown'>
                        <a href='#"'>Get Involved</a>
                        <ul className='submenu'>
                          <li>
                            <Link to='/volunteer'>Volunteer</Link>
                          </li>
                          <li>
                            <Link to='/collaborate-with-us/'>
                              Collaborate With Us
                            </Link>
                          </li>
                          <li>
                            <Link to='/work-with-us/'>Work With Us</Link>
                          </li>
                          <li>
                            <Link to='/contact-us/'>Contact Us</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to='/resilience-cafe/'>Events</Link>
                      </li>
                      <li className='has-dropdown'>
                        <a href='#"'>Resources</a>
                        <ul className='submenu'>
                          <li>
                            <a href='#'>Reports</a>
                            <ul>
                              <li>
                                <Link to='/programme-learning'>
                                  Programme Learning
                                </Link>
                              </li>
                              <li>
                                <Link to='/education-and-training'>
                                  Education and Training
                                </Link>
                              </li>
                              <li>
                                <Link to='/research-advocacy'>
                                  Research & Advocacy
                                </Link>
                              </li>
                              <li>
                                <Link to='/resources-for-children'>
                                  Resources for Children
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to='/faceofdisasters/'>
                              Face of disasters
                            </Link>
                          </li>
                          <li>
                            <Link to='/blogs/'>Blogs</Link>
                          </li>
                          <li>
                            <Link href='#'>Media</Link>
                            <ul>
                              <li>
                                <Link to={'/seeds-in-the-media/'}>
                                  SEEDS in Media
                                </Link>
                              </li>
                              <li>
                                <Link to={'/media-release/'}>
                                  Media Release
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className='col-xl-2 col-lg-2 col-md-2 col-2'>
                <div className='header-right d-none d-lg-flex align-items-center justify-content-end'>
                  <div className='header-btn'>
                    <Link
                      className='tp-btn tp-btn-shine-effect'
                      to={'/donation'}
                    >
                      <span>Donate now</span>
                    </Link>
                  </div>
                </div>
                <div className='mobile-menu d-xl-none'>
                  <button
                    className='tp-side-action tp-toogle hamburger-btn'
                    onClick={() => setOpenMenu(true)}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>{' '}
      <div className='fix'>
        <div
          className={
            openMenu ? 'tp-side-info tp-side-info-open' : 'tp-side-info '
          }
        >
          <div className='tp-side-logo'>
            <a href='index.html'>
              <img src='/assets/img/logo/seedslogo.png' alt='logo' />
            </a>
          </div>
          <div className='tp-side-close'>
            <button onClick={() => setOpenMenu(false)}>
              <i className='fa-solid fa-xmark' />
            </button>
          </div>
          <div className='tp-mobile-menu-pos mean-container'>
            <nav className='mean-nav'>
              <ul>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li className='has-dropdown'>
                  <a href='#'>About Us</a>
                  <ul className='submenu'>
                    <li>
                      <Link to='/our-story'>Our Story</Link>
                    </li>
                    <li>
                      <Link to='/strategy-2030'>Strategy 2030</Link>
                    </li>
                    <li>
                      <Link to='/our-board'>Our Board</Link>
                    </li>
                    <li>
                      <Link to='/founders'>Founders</Link>
                    </li>
                    <li>
                      <Link to='/leadership-team'>Leadership Team</Link>
                    </li>
                    <li>
                      <Link to='/awards'>Awards & Honours</Link>
                    </li>
                  </ul>
                </li>
                <li className='has-dropdown'>
                  <a href='#'>Our Work</a>
                  <ul className='submenu'>
                    <li>
                      <Link to='/what-we-do'>What We Do</Link>
                    </li>
                    <li>
                      <Link to='/seeds-in-action'>SEEDS in Action</Link>
                    </li>
                  </ul>
                </li>
                <li className='has-dropdown'>
                  <a href='#'>Accountability</a>
                  <ul className='submenu'>
                    <li>
                      <Link to='/certifications-alliances'>
                        Certification & Alliances
                      </Link>
                    </li>
                    <li>
                      <Link to='/ways-of-working/'>Ways of working</Link>
                    </li>
                    <li>
                      <Link to='/annual-reports'>Annual Reports</Link>
                    </li>
                    <li>
                      <Link to='/financials'>Financials</Link>
                    </li>
                    <li>
                      <Link to='/testimonials'>Testimonials</Link>
                    </li>
                    <li>
                      <Link to='/policies'>Policies</Link>
                    </li>
                  </ul>
                </li>

                <li className='has-dropdown'>
                  <a href='#"'>Get Involved</a>
                  <ul className='submenu'>
                    <li>
                      <Link to='/volunteer'>Volunteer</Link>
                    </li>
                    <li>
                      <Link to='/collaborate-with-us/'>
                        Collaborate With Us
                      </Link>
                    </li>
                    <li>
                      <Link to='/work-with-us/'>Work With Us</Link>
                    </li>
                    <li>
                      <Link to='/contact-us/'>Contact Us</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/resilience-cafe/'>Events</Link>
                </li>
                <li className='has-dropdown'>
                  <a href='#"'>Resources</a>
                  <ul className='submenu'>
                    <li>
                      <a href='#'>Reports</a>
                      <ul>
                        <li>
                          <Link to='/programme-learning'>
                            Programme Learning
                          </Link>
                        </li>
                        <li>
                          <Link to='/education-and-training'>
                            Education and Training
                          </Link>
                        </li>
                        <li>
                          <Link to='/research-advocacy'>
                            Research & Advocacy
                          </Link>
                        </li>
                        <li>
                          <Link to='/resources-for-children'>
                            Resources for Children
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to='/faceofdisasters/'>Face of disasters</Link>
                    </li>
                    <li>
                      <Link to='/blogs/'>Blogs</Link>
                    </li>
                    <li>
                      <Link href='#'>Media</Link>
                      <ul>
                        <li>
                          <Link to={'/seeds-in-the-media/'}>
                            SEEDS in Media
                          </Link>
                        </li>
                        <li>
                          <Link to={'/media-release/'}>Media Release</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div className='tp-side-content'>
            <div className='tp-side-content__inner-btn mb-100'>
              <Link to='/donation' className='tp-btn'>
                Donate now
              </Link>
            </div>
            <div className='tp-contact-info d-flex flex-column'></div>
            <div className='tpfooter__social tpside-social'>
              <a href='https://www.facebook.com/SEEDS.IND' target='_blank'>
                <i className='fa-brands fa-facebook-f' />
              </a>
              <a href='https://twitter.com/SeedsIndia' target='_blank'>
                <i className='fa-brands fa-twitter' />
              </a>
              <a
                href='https://www.linkedin.com/company/seeds-india'
                target='_blank'
              >
                <i className='fa-brands fa-linkedin-in' />
              </a>
              <a
                href='https://www.youtube.com/user/SEEDSLearningMedia'
                target='_blank'
              >
                <i className='fa-brands fa-youtube' />
              </a>
              <a href='https://www.instagram.com/seeds_india/' target='_blank'>
                <i className='fa-brands fa-instagram' />
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            openMenu
              ? 'offcanvas-overlay offcanvas-overlay-open'
              : "'offcanvas-overlay'"
          }
        />
      </div>
    </>
  );
}

export default Header;
