import React from 'react';
import Slider from 'react-slick';
import { BRANDS } from '../../domain/modals/brands';
function BrandSection({ noPaddingTop }) {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div
      className={
        noPaddingTop ? 'brand-area grey main-color' : 'brand-area grey pt-125'
      }
      style={{ background: '#fff' }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='tp-section-title-wrapper'>
              <h3 className='partner-title '>Our Partners</h3>
            </div>

            <div className='brand-active mb-5 '>
              <Slider {...settings} className=' '>
                {BRANDS &&
                  BRANDS.map((brand) => {
                    return (
                      <div className='single-brand grayscale text-center'>
                        <img
                          src={brand}
                          style={{
                            width: '250px',
                            height: '250px',
                            objectFit: 'contain',
                            border: '1px solid #f1f1f1',
                          }}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandSection;
