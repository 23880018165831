import React from 'react';
import Slider from 'react-slick';
import { PROJECTS } from '../../domain/modals/projects';
import { PRODUCT } from '../../domain/modals/product';
import { Link } from 'react-router-dom';
function ProjectCard() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <section
        className='volunteers-area-2 pb-235 pt-90'
        data-background='assets/img/about/home1/about-background.png'
      >
        <div className='container'>
          <div className='row'>
            <div className='row'>
              <div className='col-xl-9 col-lg-9 col-md-8'>
                <div className='volunteers-2-section__wrapper'>
                  {/* <span className='section-sub-title has-before'>
                    Our Projects
                  </span> */}

                  <h3 className='section-title'>SEEDS in Action</h3>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-4'>
                <div className='tpcard-btn d-flex justify-content-end mt-25'>
                  <Link className='custom-button' to='/seeds-in-action'>
                    All Projects
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-12 col-lg-12'>
              <div className='tp-volunteers-2  mt-40'>
                <div className='row'>
                  {PRODUCT &&
                    PRODUCT.map((item, i) => {
                      if (i < 8) {
                        return (
                          <div className='col-md-3'>
                            <div className='mb-3 single-project'>
                              <div
                                className='tp-volunteers-2__img1  wow fadeInLeft grayscale'
                                data-wow-duration='1s'
                                data-wow-delay='.5s'
                              >
                                <img src={item.img} className='project-image' />
                                <div className='hover-overlay'>
                                  <Link to={`/portfolio/${item.slug}`}>
                                    <div
                                      className='d-flex hover-content justify-content-center align-items-end'
                                      style={{ height: '100%' }}
                                    >
                                      <div className='title'> {item.name} </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className='tp-volunteers-2 d-flex mt-40'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectCard;
