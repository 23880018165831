export const BLOGS = [
  // {
  //   id: '2',
  //   title: 'Saving our `carbon sinks` for human wellbeing',
  //   date: 'March 1, 2024',
  //   slug: 'saving-our-carbon-sinks-for-human-wellbeing',
  //   featured_image: '/assets/images/blogs/2/blog_image_2.JPG',
  //   banner: '/assets/images/blogs/2/blog_image_2.JPG',
  //   author: 'Dr. Manu Gupta',
  //   content: `
  //   <p>We recently heard from Arnav Dinda, a 15-year-old student of Dakshin Kashinagar High School, Sundarbans, West Bengal, “I am excited to share my transformative experience that provided a hands-on learning approach. Understanding the Sundarbans ecosystem's importance with its rich biodiversity, has profoundly impacted me. I feel a strong sense of responsibility towards nature. The training's focus on Sustainable Practices has empowered me with practical habits to reduce my ecological footprint.”

  //   </p>
  //   <p>Arnav participated in the Green Team Training that SEEDS had organised in the Sundarbans.  “I wholeheartedly recommend it to fellow students for a holistic understanding of environmental stewardship,” he said excitedly.

  //   </p>
  //   <p>Arnav’s words highlight the need to recognise wetlands as critical to people and nature, underscoring the intrinsic value of wetland ecosystems and services, including contributions to sustainable development and human wellbeing. We have been working with communities who live in the wetlands of the Sundarbans, West Bengal, few sites in Delhi National Capital Region and in Bengaluru in the southern part of the country.  We have learned the importance of wetlands and waterbodies from our earlier work of coastal bio-shields in Pulicat, Tamilnadu and in Kabartal, Bihar.

  //   </p>
  //   <p>In 2020 Kabartal - Asia’s largest freshwater lake - in Bihar’s Begusarai district was recognised as a wetland of international importance under the Ramsar Convention. Kabartal Wetland, spans across 2,620 hectares within the Indo-Gangetic plains, it serves a vital role as a flood buffer for the region and contributes significantly to the livelihoods of local communities. This wetland is home to a diverse range of flora and fauna, with 165 plant species, 394 animal species, and an impressive 221 bird species. Moreover, it stands out as a valuable site for fish biodiversity, with 50 documented species.
  //   </p>
  //   <p>Our work here focused on integrated risk management (IRM), engaging local communities to understand their risks and collaboratively develop mitigation measures. A key element of this initiative was the Eco System Service Shared Value Assessment (ESSVA), conducted in partnership with community and government stakeholders. This assessment helped in gaining insights into the changes in Kabartal over the past 30 years, forming a foundation for future planning to protect the wetland and the livelihoods of those dependent on it. Following this an intervention supported protecting and restoring the wetland.

  //   </p>
  //   <p>The not so good news is India’s eastern coast. Once endowed with a rich biodiversity reserve is now under great peril due to the exposure to human activities and settlements. Eastern India’s revered mangroves are potentially at risk in the Sundarbans region. This includes loss of land due to increased erosion, damage to coastal infrastructure, salinisation of freshwater supplies. Agriculture in the region is also affected by ever-increasing salinity and sea water-sulfate concentrations, caused by the decreased runoffs in the eastern rivers. The decrease in freshwater run-off has also affected mangrove growth.

  //   </p>
  //   <p>Further south, the State of Tamilnadu with a 1,076 km coastline –  constitutes nearly 15 percent of the total coastal length of India - making it one of the most vulnerable regions in the world. A moderate to severe cyclone hits the region every two years. The 2004 Indian Ocean Tsunami had caused extensive loss of lives and damage in this coastal State. Poor fisher-communities along the coast have been suffering from increased frequency and intensity of climate induced hazardous impacts for a long time. Their socio-economic vulnerability pushes them further on a downward spiral with each successive cyclone and tidal surge. Establishing natural buffers are sustainable low-cost solutions to ensuring resilience of these communities.</p>
  //   <h3>Wetlands for urban resilience
  //   </h3>
  //   <p>In urban areas there is a growing challenge of wetlands turning into wastelands – a place where the community around it use it to dump household waste, construction debris, or using them to build illegal structures. Bengaluru, India’s tech city, has lost more than 65 percent of interconnectivity among its lakes, owing to encroachments and unplanned development. This has led to the decaying of lakes due to sewage inflow, as well as frequent flooding of areas across the city and more so in its peri-urban region. The interconnected lake systems, which are part of traditional water-harvesting practices, have been destroyed due to ruthless encroachments, unregulated waste disposal and large-scale unrealistic and uncontrolled development activities along wetlands and their adjoining ecosystems. ​Compared to the early 1970s, the number of wetlands in Bengaluru were reduced from 300 to 185. ​ The same story repeats in rapidly growing urban areas across the country.
  //   </p>
  //   <h3>Revitalising Cultural heritage
  //   </h3>
  //   <p>It’s not just restoring and conserving existing waterbodies, with climate change being the new normal, ancient waterbodies that are now amidst burgeoning cities are facing similar challenge. Take for example, in Delhi, an important 900-year-old archaeological monument Hauz i Shamsi (Shamsi Talab) is an ancient water reservoir that served as a source of water supply to local villages over centuries. In recent years, however, Hauz-i Shamsi has become a waste dumping site covered with invasive flora species. This has affected the reservoir’s physio-chemical condition and biological structure, silted and blocked the lower strata and reduced the ground water recharge system. This red sandstone monument is currently in a critical situation and key stakeholders are working to recover its original ecosystem standards. The Archeological Survey of India has teamed up with every possible stakeholder to revitalise this vital ecosystem. </p>
  //   <h3>Conclusion</h3>
  //   <p>Water bodies thrive when there is harmonious co-existence between people and these natural systems. That’s when biodiversity, water quality, and ecosystem services are enriched. Healthy and thriving water systems act as natural ‘sponge’ to flooding in urban areas. In rural areas and especially our coasts, they protect communities and enable sustainable livelihoods. Mangroves are the best-known carbon sinks. As such, restoration, preservation of wetlands is a direct and most effective contribution to climate change mitigation and adaptation efforts.
  //   </p>
  //   <p>Together with communities we know that the winners are those who understand the risk of letting our wetlands un-protected and its far-reaching implications. They are the ones who actively work towards building resilient communities that rely and hence protect these wetlands. With climate change now an inevitability, we need to expand such groups of passionate young people who can lead from the front.
  //   </p>
  //   <p>Arnav and his classmates belong to the generation will be ‘complete’ citizens of India as they embark on a   journey to protect, conserve and restore our wetlands back to their natural glory in diverse geographies of India. As adults, they need our guidance and support.
  //   </p>
  //   <div class="row mt-3 mb-3">
  //   <div class="col-md-6">
  //     <img src= "/assets/images/blogs/2/blog_image_1.JPG" style="width:100%;height:400px;object-fit:cover;" />
  //   </div>
  //   <div class="col-md-6">
  //     <img src= "/assets/images/blogs/2/blog_image_3.JPG" style="width:100%;height:400px;object-fit:cover;" />
  //   </div>
  //   </div>
  //   `,
  // },
  {
    id: '4',
    title: 'Restoration of school facilities, Wayanad',
    date: 'September 09, 2024',
    slug: 'restoration-of-school-facilities-wayanad',
    featured_image: '/images/blogs/4/banner.jpeg',
    banner: '/images/blogs/4/banner.jpeg',
    author: 'Sumeet Agarwal',
    content: `
   <p>The night of July 30, 2024, will forever be etched in the memory of Wayanad’s residents. A massive landslide ravaged the beautiful green landscapes of Chooralmala and Mundakkai villages of Meppadi gram panchayat, reducing homes, schools, roads, and plantations to a wasteland of mud and rocks. Hundreds of people lost their lives, and many more were left homeless, as the serene hill district turned into a scene of devastation in a matter of hours. In the immediate aftermath, the government of Kerala acted swiftly, relocating survivors to relief camps and hospitals. Rehabilitation plans were set in motion immediately.
</p>
<p>Among the many challenges, ensuring that children's education in the affected areas continued without long interruption was a key priority. Two schools—Government High School Vellarmala (GVHS) and Government Lower Primary School (GLPS) Mundakkai—were severely damaged in the landslide. To avoid a prolonged disruption in the children's education, the authorities relocated the students to Government High School (GHS), Meppadi. 
</p>
<h3>Converting Spaces for Education
 </h3>

<p> To solve the space issue, the local administration decided to repurpose the Panchayat community hall into temporary classrooms for GLPS Mundakkai, while additional teaching spaces were to be created at GHS Meppadi for the GVHS Vellarmala students. SEEDS was tasked with this responsibility of accommodating the additional students in an already functioning school.

</p>
<p >The road ahead was anything but smooth. Both from a renovation and a perception point of view. After all people now associated the community hall and the school’s classrooms as temporary mortuaries. These spaces, therefore, needed to be cleaned, repainted and prepared for classes. The work was assigned to our technical team at SEEDS by the Chief of Programs, who was overseeing the relief efforts on the ground.</p>
<p>On August 18, 2024 our team arrived in Kalpetta town after a three-hour drive from Calicut. When we visited GHS Meppadi the next day, the school was still functioning as a relief camp, with the classrooms filled with survivors. A teacher, Mr. Shahnawaz, guided us through the school and underscored the urgency of the situation.
 </p>
<p>The enormity of the task soon became clear. With the District Collector setting a firm deadline to start classes on September 2, 2024 we had less than two weeks to complete the work. The pressure was immense, but the support from local authorities, especially the Panchayat President who supported the work in every possible way, kept us going.

</p>
<h3>Witnessing the Destruction
</h3>
<p>During our time in Wayanad, we visited the landslide-affected areas. The sight was both tragic and humbling. Damaged houses stood eerily empty, with staircases leading to nowhere, silent witnesses to a once-thriving community. We also visited the remnants of the two schools that had been destroyed, their mud-covered walls bearing the scars of the disaster. Seeing this devastation firsthand helped us better understand the trauma these children had endured. It fuelled our resolve to create a positive and welcoming environment for them at GHS Meppadi.
</p>

<div class="row">
<div class="col-md-6">
    <img src="/images/blogs/4/wayanad/class.jpg" class="mb-5" />
</div>
<div class="col-md-6">
    <img src="/images/blogs/4/wayanad/glps.jpg" class="mb-5" />
</div>
<div class="col-md-6">
    <img src="/images/blogs/4/wayanad/glps3.jpg" class="mb-5" />
</div>
<div class="col-md-6">
    <img src="/images/blogs/4/wayanad/class2.jpg" class="mb-5" />
</div>
</div>
<h3>Racing Against Time</h3>
<p>By the time we had secured contractors and workers for the task, we had just one week to complete the work. In addition to repainting the classrooms and installing partitions to create teaching spaces, we received several urgent requests: repair the kitchen, develop a handwash areas, and supporting the teachers with art materials to brighten up the classrooms for the children.
</p>
<p>Despite the overwhelming workload and tight deadline, we couldn’t ignore these requests. We knew they were vital for creating an environment where the children could feel safe and start healing from the trauma they had experienced.
</p>
<p>The work was completed on September 1, 2024 a day before the children were scheduled to arrive. This achievement was made possible only through the tireless efforts of the workers, who worked late into the night to ensure the school was ready on time. Their determination to provide children with a good environment after such a tragedy was truly inspiring.
</p>
<h3>A Milestone in Recovery
</h3>
<p>Our efforts didn’t go unnoticed. The state's Education Minister praised SEEDS for its contribution to the recovery process, presenting us with a memento of appreciation. The Panchayat President, too, expressed his gratitude and promised continued support for future upgrades to the school.
</p>
<p>While this is only the first step in a long journey of recovery, completing this milestone has instilled confidence in both us and the local administration. There is still much to be done, but together, we are moving forward—one step at a time.
</p>
<p>The story of Wayanad’s recovery is one of resilience, hope, and the unwavering spirit of a community determined to rebuild what was lost. And at the heart of it all is the belief that, with the right support, even the most broken places can be restored, and life can begin anew.
</p>

<div class="row">
<div class="col-md-6">
    <img src="/images/blogs/4/image-2.jpeg" class="mb-5" />
</div>
<div class="col-md-6">
    <img src="/images/blogs/4/image1.jpeg" class="mb-5" />
</div>
</div>





    `,
  },
  {
    id: '3',
    title: 'When being prepared helps plan better at a local scale',
    date: 'September 04, 2024',
    slug: 'when-being-prepared-helps-plan-better-at-a-local-scale',
    featured_image: '/images/blogs/3/image1.jpg',
    banner: '/images/blogs/3/image1.jpg',
    author: 'Sumedha Dua',
    content: `
   <p>Did you know that your rooftop could potentially tell you a story?  What if it could tell you about your house type, its robustness, and its vulnerability to extreme weather and hazards? Our Microsoft AI for Resilient Cities Project does exactly this. With our cutting-edge Artificial Intelligence (AI) models, we can assess houses’ vulnerability scores based solely on the roof type from high-resolution satellite imagery.
</p>
<p>As India experiences the 2024 ‘emergency season’, this AI model comes in handy for communities and governments to derive information ahead of challenging incidents such as heatwaves, floods, cyclones, and earthquakes. At SEEDS, over the past three years, AI-led and community-centric interventions through ‘Beat the Heat’ and ‘Under the Umbrella’ campaigns have been initiated through the various iterations of this model. Using results from our AI models, our ground teams have extensively disseminated localised and hazard-specific advisories and warnings to vulnerable communities.
</p>
<p>This model has been applied for floods, heat waves, and earthquakes, in some of the most marginalised regions of cities such as Delhi, Ahmedabad, Bhubaneswar, Chennai, Puri, Gaya, Gangtok, and Dehradun, and is now being deployed on ground for implementation and verification. Previously tested on Cyclones Yaas and Tauktae (2021), the model was also implemented through our ‘Beat the Heat’ campaigns in 2021 and 2022. In areas such as Mayur Vihar and Yamuna Khadar in the National Capital Region, our team organised #beattheheat quizzes, issued advisories to 25000+ families, and four houses were selected to implement interventions on the basis of the AI model’s scoring. </p>
<img src="/images/blogs/3/image3.png" class="w-100"/>
<p> Model piloted in cities falling under Core Heatwave Zones (CHZs) such as East Delhi, Nagpur
</p>
<p class="pt-5">Most of this work has been evolving since 2020 when SEEDS, in collaboration with Microsoft, developed ‘Sunny Lives’, a game-changer AI model that prepared communities in advance of impending – potentially disastrous events. 
</p>
<p>Over the past years, this model has been instrumental in enabling the most marginalised populations, vulnerable to multiple hazards, for instance in Delhi, where heat waves, floods, and earthquakes can affect these houses severely. Our model identifies and analyses the risks faced by these houses and populations, mobilises stakeholders, issues advisories, and brings forward solution-based interventions that not only mitigate their risks but also offer relief to the vulnerable populations.
 </p>
<p>Hereon begins the most exciting phase of the project, with the model now being continually improvised, and the results being utilised to understand and deploy the interventions to the most marginalised houses and populations. In these alarming times of the manifestations of climate change beginning to affect even the safest of groups, the Sunny Lives model brings a welcome and assuring initiative to not only reduce the impact on underserved communities, but also to use ‘AI 4 good’.
</p>
<div class="row">
    <div class="col-md-6">
    <img src="/images/blogs/3/image2.jpg" class="w-100"/>
  <p class="font-italic">  The SEEDS team for Microsoft AI four Resilient Cities</p>

     </div>
      <div class="col-md-6">
   <img src="/images/blogs/3/image4.jpg" class="w-100"/>
   <p class="font-italic">The SEEDS team on ground with localised advisories 

     </div>
</div>




    `,
  },
  {
    id: '2',
    title: 'When the flood waters recede, there is so much to be done! ',
    date: 'July 23, 2024',
    slug: 'when-the-flood-waters-recede-there-is-so-much-to-be-done',
    featured_image: '/images/blogs/2/blog-image.jpeg',
    banner: '/images/blogs/2/blog-image.jpeg',
    author: 'Yezdani Rahman ',
    content: `
   <p>SEEDS has been working in Assam for more than a decade now. Through my regular travels, there are some changes that I have been seeing on the ground that make me wonder how to tackle the challenging complexities of this geographically vulnerable area that is now seeing more frequently recurring flooding.&nbsp;</p>
<p>One clear change that I see, is that highways get inundated after the rains subside, making travel and daily life exceedingly difficult. The Barak Pass, which usually was not badly affected due to flooding is now annually submerged, and this has been continuous for the past five-six years.&nbsp;</p>
<p>The sensitive district of Karimganj, a town approximately 76 km from Silchar Airport, has been grappling with severe flooding that has left an adverse impact on its residents and infrastructure. Bordering Bangladesh, the recent inundation in Karimganj, in the months of June and July lasted for days, causing significant disruption, particularly in low-lying areas where flood water will take close to 20-25 days to recede. Apart from Karimganj, the other severely affected districts are Nagaon and Dhubri.</p>
<p>On my recent trip in early July this year, when we drove directly to Karimganj what we witnessed was that access to villages was cut. With highway roads being built higher, the homes of people living in low-lying areas were completely submerged. People were using bamboo rafts and boats to access what remained of their homes and fields and returning to the relief camps. They put thermocol boxes in the middle of their rafts and boats to transport goods or fish and poultry. </p>
<p>A total of 16 districts and 49 revenue circles have been affected by the 2024 Assam floods that include Biswanath, Cachar, Dhemaji, Dhubri, Dibrugarh, Goalpara, Golaghat, Hailakandi, Kamrup, Kamprup Metro, Karimganj, Majuli, Morigaon, Nagoan, Nalbari and Sivsagar.&nbsp;</p>
<p>As part of the SEEDS India flood response team, it took us close to three hours from Silchar to reach the most affected area of Suprakandi Gram Panchayat in the Karimganj district of the State. This gram panchayat was critically affected and 100 percent of the population was affected. This area has very few civil society organisations on ground. With panchayat elections yet to be held, this area needs all the support it can get. SEEDS is on the ground working to identify those most vulnerable. </p>
<p>While information indicated that 75 percent of flood water levels had receded, on the ground in low-lying areas water remained knee-to-chest level high. In these places, it will take around 20-25 days for flood waters to recede from homes and agricultural fields.</p>
<p>In some places, villagers were saying, that because of the recently built national highways being raised so much and there being no sluice gate once the flood waters come there is no way for it to drain out. We also heard from community that the Barak Valley never had so much water, but in the last five to seven years, it has increased. While in 2022, it rained much more than this year, all the locations are the same as they were earlier with at least one to two feet more of water.</p>
<p>Livelihoods, shelter, and property damage assessments are crucial in understanding and addressing the community's immediate and long-term needs. The on-ground assessment identified chlorination of hand pumps and dug wells, hygiene kits, child-friendly spaces including the hygiene needs of adolescent girls and women. Safe delivery spaces for pregnant women and psycho-social support for affected community members were also highlighted.&nbsp;</p>
<p>Communities are also desperately searching for clean drinking water, rations (rice and dal), milk and infant food for children, medicines, shelter, non-food items and hygiene kits. With rainfall and humid situations continuing, the high prevalence of vector-born diseases also necessitates the need for mosquito nets. Torches or lanterns, precautions to prevent snake bites, as well as fuel for cooking are a priority for communities here.&nbsp;</p>
<p>Livelihoods too have been severely affected. Agricultural areas that are flooded have no mechanism to drain out the water. It will take up to the next summer for the flood waters to evaporate and only then the land can be ready for cultivation. Those with livestock are looking for fodder too.</p>
  <div class="row">
  <div class="col-md-4">
  <img src="/images/blogs/2/image1.png"/>
  </div>
  <div class="col-md-8">
  <p>Schools, unfortunately, have not been spared too. The floodwaters have severely affected educational facilities, disrupting learning and further complicating the community's recovery efforts. Amidst these challenges, teachers have bravely continued their work, by focusing on compiling reports detailing the economic impact of the floods.&nbsp;</p>
<p>Despite these hardships, the resilience and solidarity of the people of Karimganj shine through. Community members have come together to support one another, demonstrating remarkable strength in the face of adversity. However, the road to recovery remains long and arduous, requiring sustained efforts from both government agencies and non-governmental organizations (NGOs) to provide timely relief and rehabilitation. Alongside, given the nature of these recurring disasters preparing communities to anticipate will be important.&nbsp;</p>
  </div>
  </div>  


<p>As Karimganj navigates through these challenging times, raising awareness and garnering support for their plight becomes crucial. Initiatives focusing on flood mitigation, infrastructure improvement, and enhanced disaster preparedness are essential to safeguarding the community's future against recurring disasters.</p>
<p>For the moment efforts and attention are focused on the relief work in Karimganj. As we did in Cachar last year, building preparedness during the rehabilitation phase of our work will be critical. </p>
<div class="row">
<div class="col-md-6">
<img src="/images/blogs/2/image2.jpeg"/>
</div>
<div class="col-md-6">
<img src="/images/blogs/2/image3.jpeg"/></div>
</div>
    `,
  },
  {
    id: '1',
    title: 'Why we must never forget the Gujarat earthquake',
    date: 'January 26, 2024',
    slug: 'why-we-must-never-forget-the-gujarat-earthquake',
    featured_image: '/assets/images/blogs/1/image_1.JPG',
    banner: '/assets/images/blogs/1/banner.JPG',
    author: 'Dr. Manu Gupta',
    content: `
    <p>The year 2024 began with the tragic news of the Noto Peninsula earthquake in Japan. Even for a country as prepared as Japan, the incident brought to fore how communities experience suffering and desolation following such a disaster. It also immediately brings back painful memories of the Gujarat earthquake on India’s 52nd Republic Day, January 26, 2001.
    </p>
    <p>Why is it important to look back at learnings from 2001? Today with raising interconnectedness of poverty, climate vulnerability and hazard events, the challenge is how we ensure communities retain the agency to chart their recovery journey and be able to take proactive action for future events.  There is an urgency to build resilient and sustainable communities across boundaries towards disaster and environmental stewardship.
    </p>
    <p>For the Global South, recovery post the 2001 Kutch earthquake in Gujarat has served as an important case study. The recovery programme is great example of a ‘whole-of-society’ approach where the State leveraged partnerships with institutions, multilateral agencies, and civil society organisations to super-charge community led recovery.
    </p>
    <p>SEEDS was closely associated with the housing sector, where a house-owner led recovery programme brought speed, cultural acceptability and efficiency in the use of resources. The State, CSOs, technical certifying agencies and donors aligned themselves to agreed protocols and standards that led to coordinated response.  Not surprisingly, the success of this approach to housing recovery has been adopted in many other States in India.</p>
    <p>My five year engagement with the recovery process that started 3 days after the earthquake has been transformative in the way it has shaped my thinking and that of SEEDS in the last twenty-plus years. In situations where we are having to simultaneously having deal with scale, diversity, sensitivity of time and not at the least the trauma of suffering, the role of partnerships among multiple institutions becomes crucial.
    </p>
    <p>It comes down to basic common denominators that allow open value creation among partners and define interaction protocols. In one of our many partnerships, giving affected communities the agency to make choices and final decisions was an agreed objective that defined the nature of relationships  among the aid providers. In another intervention, putting a cap on the quantum of assistance that can go to households with similar damage profiles prevented ‘oases of prosperity’ getting created.
    </p>
    <div class="row mt-3 mb-3">
    <div class="col-md-4">
      <img src= "/assets/images/blogs/1/image_2.JPG" style="width:100%;height:300px;object-fit:cover;" />
    </div>
    <div class="col-md-4">
      <img src= "/assets/images/blogs/1/image_3.JPG" style="width:100%;height:300px;object-fit:cover;" />
    </div>
    <div class="col-md-4">
      <img src= "/assets/images/blogs/1/image_4.JPG" style="width:100%;height:300px;object-fit:cover;" />
    </div>
    </div>
    <p>Often, just as disasters are forgotten, so are these partnerships. SEEDS has, however, stayed with them. In fact, over the years we have nurtured and redefined some of these partnerships in response to changing challenges. These partnerships have taken a life of their own becoming strong influential networks that have brought about policy shifts while unceasingly learning from new experiences.
    </p>
    <p>In Japan, India and in many other parts of Asia – devastating catastrophes such as the Noto and the Kutch Earthquake in the same month 23 years apart are sad reminders of the work that needs to be done pro-actively in areas prone to disasters. We need to go beyond just structural interventions, to investing in  lasting partnerships and networks that potentially have far greater capabilities in alleviating suffering when nature isn’t kind. 
    </p>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/UsifZ6vgfVI?si=Tgu0by2QNqQf5Xrb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    `,
  },
];
