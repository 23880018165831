import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import TestimonialSection from '../../components/Home/TestimonialSection';

function Testimonials() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Testimonials'}
        sub_title={'Testimonials'}
        image={'/uploads/2021/02/Testimonials.jpg'}
      />
      <section className='pt-30'>
        <TestimonialSection />
      </section>

      <Footer />
    </div>
  );
}

export default Testimonials;
