import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function SeedsInMedia() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'SEEDS in Media'}
        sub_title={'What We Do'}
        image={'/uploads/2020/08/SEEDS-in-the-media.jpg'}
      />
      <section
        className='tpblog-area awards pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h3>Spokesperson and Organisation Profiling</h3>
            </div>
          </div>
          <div className='row'>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>DownToEarth</strong> <br />
                <strong>Aug 26, 2024</strong>
              </p>
              <a
                href='https://www.downtoearth.org.in/environment/remembering-malpa-landslide-memorial-day-indias-ongoing-battle-with-natures-fury'
                target='_blank'
              >
                <img src='/images/media/2/down-to-earth.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Straits Times </strong> <br />
                <strong>Aug 08, 2024</strong>
              </p>
              <a
                href='https://www.straitstimes.com/asia/south-asia/updated-law-for-disaster-management-in-india-in-the-works-as-rains-wreak-havoc'
                target='_blank'
              >
                <img src='/images/media/2/st-news.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>India CSR</strong> <br />
                <strong>June 21, 2024</strong>
              </p>
              <a
                href='https://indiacsr.in/wisdom-modern-hauz-i-shamsi-urban-water-stress/'
                target='_blank'
              >
                <img src='/assets/images/media/june/indian-csr.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Business News This Week</strong> <br />
                <strong>June 10, 2024</strong>
              </p>
              <a
                href='https://businessnewsthisweek.com/business/facing-the-heatwave-crisis-kishan-kunjs-struggle-and-seeds-intervention/'
                target='_blank'
              >
                <img src='/assets/images/media/june/business-week.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Hindu Business Line </strong> <br />
                <strong>June 9, 2024</strong>
              </p>
              <a
                href='https://www.thehindubusinessline.com/specials/clean-tech/reviving-urban-water-bodies-with-people-participation/article68255420.ece'
                target='_blank'
              >
                <img src='/assets/images/media/june/bl-premium.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Business News This Week </strong> <br />
                <strong>June 5, 2024</strong>
              </p>
              <a
                href='https://businessnewsthisweek.com/news/creating-green-spaces-seeds-impact-on-urban-biodiversity-and-resilience-through-water-body-restoration/'
                target='_blank'
              >
                <img src='/assets/images/media/june/green-space.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Indian Water Portal </strong> <br />
                <strong>May 31, 2024</strong>
              </p>
              <a
                href='https://www.indiawaterportal.org/articles/climate-action-urban-spaces-seeds-cool-roof-solutions-delhi-slums'
                target='_blank'
              >
                <img src='/assets/images/media/june/waterportal.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Hans India</strong> <br />
                <strong>May 27, 2024</strong>
              </p>
              <a
                href='https://www.thehansindia.com/news/national/nurturing-nature-with-seeds-sustainable-water-solutions-in-huvinayakanahalli-881205'
                target='_blank'
              >
                <img src='/assets/images/media/june/hans-india.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>United News of India</strong> <br />
                <strong>May 20, 2024</strong>
              </p>
              <a
                href='https://uniindia.com/from-devastation-to-dignity-seeds-humanitarian-response-to-cyclone-amphan/pnn/news/3203089.html'
                target='_blank'
              >
                <img src='/assets/images/media/june/united-news.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Deccan Herald </strong> <br />
                <strong>May 08, 2024</strong>
              </p>
              <a
                href='https://www.deccanherald.com/brandspot/featured/a-cleaner-future-the-impact-of-indias-new-environmental-ruling-3013707'
                target='_blank'
              >
                <img src='/assets/images/media/june/dh.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Atom News </strong> <br />
                <strong>April 24, 2024</strong>
              </p>
              <a
                href='https://atomnews.in/latest/seeds-empowering-communities/'
                target='_blank'
              >
                <img src='/assets/images/media/june/atom.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Passionate in Marketing </strong> <br />
                <strong>April 15, 2024</strong>
              </p>
              <a
                href='https://www.passionateinmarketing.com/indias-heatwave-crisis-a-call-for-action-on-world-health-day/'
                target='_blank'
              >
                <img src='/assets/images/media/june/passionate-in-marketing.png' />
              </a>
            </div>

            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>CSR Times</strong> <br />
                <strong>March 27, 2024</strong>
              </p>
              <a
                href='https://csrtimes.org/building-a-climate-resilient-future-celebrating-world-meteorological-day-with-seeds-india/'
                target='_blank'
              >
                <img src='/images/news/1/image-5.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>APN News</strong> <br />
                <strong>March 26, 2024</strong>
              </p>
              <a
                href='https://www.apnnews.com/building-a-climate-resilient-future-celebrating-world-meteorological-day-with-seeds-india/'
                target='_blank'
              >
                <img src='/images/news/1/image-4.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>CSR Universe</strong> <br />
                <strong>March 22, 2024</strong>
              </p>
              <a
                href='https://thecsruniverse.com/articles/clean-water-for-all-how-seeds-india-s-community-projects-are-making-a-difference'
                target='_blank'
              >
                <img src='/images/news/1/image-3.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Enviro Annotations</strong> <br />
                <strong>March 22, 2024</strong>
              </p>
              <a
                href='https://enviroannotations.substack.com/p/news-seeds-indias-community-projects'
                target='_blank'
              >
                <img src='/images/news/1/image-2.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>India Water portal</strong> <br />
                <strong>March 22, 2024</strong>
              </p>
              <a
                href='https://www.indiawaterportal.org/articles/clean-water-all'
                target='_blank'
              >
                <img src='/images/news/1/image-1.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Hindustan Times</strong> <br />
                <strong>Feb 19, 2024</strong>
              </p>
              <a href='/images/media/1/ht.jpeg' target='_blank'>
                <img src='/images/media/1/ht.jpeg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Hindustan Times</strong> <br />
                <strong>Feb 19, 2024</strong>
              </p>
              <a
                href='https://www.hindustantimes.com/cities/delhi-news/threestage-restoration-planned-for-delhi-sultanate-era-reservoir-101708329042839.html'
                target='_blank'
              >
                <img src='/images/media/1/image_4.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Down To Earth</strong> <br />
                <strong>Feb 14, 2024</strong>
              </p>
              <a
                href='https://www.downtoearth.org.in/news/natural-disasters/bedevilled-barak-how-a-phc-in-katigorah-rebuilt-itself-after-the-2022-assam-floods-94440'
                target='_blank'
              >
                <img src='/images/media/1/image_3.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Down To Earth</strong> <br />
                <strong>Feb 13, 2024</strong>
              </p>
              <a
                href='https://www.downtoearth.org.in/news/natural-disasters/bedevilled-barak-how-a-katigorah-school-is-building-resilience-after-2022-assam-floods-94422'
                target='_blank'
              >
                <img src='/images/media/1/image_2.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Down To Earth</strong> <br />
                <strong>Feb 12, 2024</strong>
              </p>
              <a
                href='https://www.downtoearth.org.in/news/natural-disasters/bedevilled-barak-dte-reconstructs-what-went-wrong-in-katigorah-during-2022-assam-floods-94393'
                target='_blank'
              >
                <img src='/images/media/1/image_1.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Better India</strong> <br />
                <strong>Jan 01, 2024</strong>
              </p>
              <a
                href='https://www.thebetterindia.com/307729/climate-resilient-goat-shed-model-by-seeds-india-prevents-livestock-loss-during-flood-earthquake-bihar/'
                target='_blank'
              >
                <img src='/images/media/1/image_5.png' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Digital media - The Quint</strong> <br />
                <strong>Sept 09, 2023</strong>
              </p>
              <a
                href='https://www.thequint.com/opinion/libya-floods-2023-disaster-management-climate-action-cyclone-biparjoy-india'
                target='_blank'
              >
                <img src='/assets/img/media-coverage/2023/media-1.webp' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Pioneer</strong> <br />
                <strong>Oct 25, 2023</strong>
              </p>
              <a
                href='/assets/images/media-coverage/pioneer.jpg'
                target='_blank'
              >
                <img src='/assets/images/media-coverage/pioneer.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Digital media - Pioneers Post</strong> <br />
                <strong>Nov 07, 2023</strong>
              </p>
              <a
                href='https://www.pioneerspost.com/news-views/20231107/opinion-innovations-disaster-resilience-exist-now-we-must-help-them-scale'
                target='_blank'
              >
                <img src='/assets/img/media-coverage/2023/media-2.webp' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong> Digital media - The Quint</strong> <br />
                <strong>Dec 06, 2023</strong>
              </p>
              <a
                href='https://www.thequint.com/opinion/climate-change-cop-28-loss-and-damage-fund-operationalised-data-monitoring'
                target='_blank'
              >
                <img src='/assets/img/media-coverage/2023/media-3.webp' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Digital media - Gaon Connection</strong> <br />
                <strong>Dec 07, 2023</strong>
              </p>
              <a
                href='https://www.gaonconnection.com/TheChangemakersProject/school-safety-elfa-jammu-and-kashmir-education-disaster-management-mental-health-gender-violence-ladi-shah-53125'
                target='_blank'
              >
                <img src='/assets/img/media-coverage/2023/media-4.webp' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong> The Statesman</strong> <br />
                <strong>Dec 13, 2023</strong>
              </p>
              <a
                href='/assets/images/media-coverage/statesman.jpg'
                target='_blank'
              >
                <img src='/assets/images/media-coverage/statesman.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Digital media - Gaon Connection</strong> <br />
                <strong>Dec 14, 2023</strong>
              </p>
              <a
                href='https://www.gaonconnection.com/climateconnection/artificial-glaciers-ladakh-leh-jammu-kashmir-irrigation-agriculture-farmers-climate-resilience-53159'
                target='_blank'
              >
                <img src='/assets/img/media-coverage/2023/media-5.webp' />
              </a>
            </div>
            {/* <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Statesman</strong> <br />
                <strong>Dec 13, 2023</strong>
              </p>
              <a
                href='/assets/images/media-coverage/statesman.jpg'
                target='_blank'
              >
                <img src='/assets/images/media-coverage/statesman.jpg' />
              </a>
            </div> */}
            {/* <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Pioneer</strong> <br />
                <strong>Oct 25, 2023</strong>
              </p>
              <a
                href='/assets/images/media-coverage/pioneer.jpg'
                target='_blank'
              >
                <img src='/assets/images/media-coverage/pioneer.jpg' />
              </a>
            </div> */}
            {/* <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Deshbandhu</strong> <br />
                <strong>Oct 20, 2023</strong>
              </p>
              <a
                href='/assets/images/media-coverage/deshbandhu.png'
                target='_blank'
              >
                <img src='/assets/images/media-coverage/deshbandhu.png' />
              </a>
            </div> */}
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The HANS India</strong> <br />
                <strong>Jan 26, 2022</strong>
              </p>
              <a href='/assets/images/media/The-HANS-India.jpg' target='_blank'>
                <img src='/assets/images/media/image1.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <a
                href='https://www.indiatoday.in/mail-today/story/they-prepare-people-against-nature-s-fury-1634036-2020-01-04'
                target='_blank'
              >
                <p>
                  <strong>India Today</strong> <br />
                  <strong>Jan 5, 2020</strong>
                </p>
              </a>
              <a
                href='https://www.indiatoday.in/mail-today/story/they-prepare-people-against-nature-s-fury-1634036-2020-01-04'
                target='_blank'
              >
                <img src='/assets/images/media/image2.jpg' />
              </a>
            </div>

            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <a
                href='/uploads/2018/02/Business-Standard-September-18-2019.jpg'
                target='_blank'
              >
                <p>
                  <strong>Business Standard</strong> <br />
                  <strong>September 18, 2019</strong>
                </p>
              </a>
              <a
                href='/uploads/2018/02/Business-Standard-September-18-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Business-Standard-September-18-2019-180x180.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Hindu BusinessLine</strong> <br />
                <strong>January 19, 2019</strong>
              </p>
              <a
                href='/uploads/2018/02/The-Hindu-BusinessLine-January-19-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/The-Hindu-BusinessLine-January-19-2019-500x500.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Business Standard</strong> <br />
                <strong>October 28, 2018</strong>
              </p>
              <a
                href='/uploads/2018/02/Business-Standard-October-28-2018.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Business-Standard-October-28-2018-180x180.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>The Statesman</strong> <br />
                <strong>September 8, 2019</strong>
              </p>
              <a
                href='/uploads/2018/02/The-Statesman-September-8-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/The-Statesman-September-8-2019-180x180.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Business Standard</strong> <br />
                <strong>July 15, 2019</strong>
              </p>
              <a
                href='/uploads/2018/02/Business-Standard-July-15-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Business-Standard-July-15-2019-180x180.jpg' />
              </a>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <h3>Project Coverage</h3>
            </div>
          </div>
          <div className='row'>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  The Statesman
                  <br />
                  March 3, 2022
                </strong>
              </p>
              <a
                href='https://www.thestatesman.com/cities/delhi/climate-friendly-community-school-built-flyover-without-brick-mortar-1503049635.html'
                target='_blank'
              >
                <img src='/assets/images/media/image3.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  Your Story
                  <br />
                  Feb 2, 2022
                </strong>
              </p>
              <a
                href='https://yourstory.com/socialstory/2022/02/seeds-kavach-covid-19-mass-vaccination-marginalised-communities-india'
                target='_blank'
              >
                <img src='/assets/images/media/image4.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  Gaon Connection
                  <br />
                  April 26, 2022
                </strong>
              </p>
              <a
                href='https://www.gaonconnection.com/read/heat-wave-rural-india-bihar-villages-summer-temperature-weather-dehydration-prevention-tips-climate-news-health-50709'
                target='_blank'
              >
                <img src='/assets/images/media/image5.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  Business Standard Pune Edition
                  <br />
                  August 25, 2018
                </strong>
              </p>
              <a
                href='/uploads/2018/02/Business-Standard-Pune-Edition-August-25-2018.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Business-Standard-Pune-Edition-August-25-2018-180x180.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  Eastern Chronicle
                  <br />
                  September 19, 2018
                </strong>
              </p>
              <a
                href='/uploads/2018/02/Eastern-Chronicle-September-19-2018.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Eastern-Chronicle-September-19-2018-180x180.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  The Statesman
                  <br />
                  July 24, 2019
                </strong>
              </p>
              <a
                href='/uploads/2018/02/The-Statesman-July-24-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/The-Statesman-July-24-2019-500x500.jpg' />
              </a>
            </div>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>
                  The Political Business Daily
                  <br />
                  July 24, 2019
                </strong>
              </p>
              <a
                href='/uploads/2018/02/The-Political-Business-Daily-July-24-2019.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/The-Political-Business-Daily-July-24-2019-180x180.jpg' />
              </a>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <h3>Regional Coverage</h3>
            </div>
          </div>
          <div className='row'>
            <div
              className='col-md-3'
              style={{
                borderBottom: '1px solid #f1f1f1',
                marginBottom: '20px',
                paddingBottom: '20px',
              }}
            >
              <p>
                <strong>Odisha Post</strong>
                <br />
                July 24, 2019
              </p>
              <a
                href='/uploads/2018/02/Odisha-Post-July-24-2019-1.jpg'
                target='_blank'
              >
                <img src='/uploads/2018/02/Odisha-Post-July-24-2019-1-500x500.jpg' />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SeedsInMedia;
