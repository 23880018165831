import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function CertificateAlliances() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Certifications & Alliances'}
        sub_title={'Certifications & Alliances'}
        image={'/uploads/2020/08/Ways-of-working-Header-Image.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h3>SEEDS is certified by and is a signatory to:</h3>
              <div className='certificate-wrapper'>
                <ul>
                  <li>
                    The Code of Conduct for The International Red Cross and Red
                    Crescent Movement
                  </li>
                  <li>
                    <a
                      href='https://www.climate-charter.org/signatures/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      The Climate and Environment Charter for Humanitarian
                      Organisations
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      href='https://validation.cafamerica.org/0fa35e45-1f42-4c29-b648-8d8e2d34448d#gs.5slumt'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Charities Aid Foundation (CAF)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://guidestarindia.org.in/Summary.aspx?CCReg=302'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      GuideStar India Gold Certification
                    </a>
                  </li>
                  <li>Credibility Alliance</li>
                  <li>Give India</li>
                </ul>
                <h4>We are members of and allied to:</h4>

                <ul>
                  <li>
                    Alliance for Adaptation and Disaster Risk Reduction (AADRR)
                  </li>
                  <li>
                    <a
                      href='https://www.adrrn.net/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Asian Disaster Reduction and Response Network (ADRRN)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.alnap.org/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Active Learning Network for Accountability and Performance
                      (ALNAP)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://catalyst2030.net/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Catalyst 2030
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://cansouthasia.net/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Climate Action Network (CAN) South Asia
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://www.gndr.org/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      The Global Network of Civil Society Organisations for
                      Disaster Reduction (GNDR)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://startnetwork.org/org/seeds'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Start Network and also a member of India Hub of the Start
                      Network
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.sphereindia.org.in/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Sphere India
                    </a>
                  </li>
                  <li>Owner Driven Rehabilitation Collaborative (ODRC)</li>
                  <li>
                    <a
                      href='https://www.vaniindia.org/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Voluntary Action Network India (VANI)
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.chsalliance.org/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      India’s first agency to be verified against the Core
                      Humanitarian Standard (CHS)
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CertificateAlliances;
