import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
function VolunteerSection() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    fade: true,
    arrows: false,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="far fa-arrow-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="far fa-arrow-right"></i></button>',
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className='volunteers-area-3 pt-105 pb-90'>
        <div
          className='volunteers-bg'
          data-background='assets/img/about/home1/volunteers-bg.png'
        />
        <div className='contact-us-box'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='volunteers-2-section__wrapper'>
                  {/* <span className='section-sub-title has-before'>
                    Our Projects
                  </span> */}
                  <h3 className='section-title'>Join our Mission</h3>
                </div>
              </div>
              <div className='col-lg-4 col-md-4'>
                <Link to='/collaborate-with-us'>
                  <div className='contact-us-wrapper p-relative mb-30 text-center'>
                    <h3>Partner</h3>
                    <p>Become our partner by joining forces with us.</p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-4'>
                <Link to='/work-with-us'>
                  <div className='contact-us-wrapper p-relative mb-30 text-center'>
                    <h3>SEEDER</h3>
                    <p>Become a part of our team</p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-4'>
                <Link to='/volunteer'>
                  <div className='contact-us-wrapper p-relative mb-30 text-center'>
                    <h3>Volunteer</h3>
                    <p>
                      Become a part of our volunteer group and support our cause
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Slider {...settings}>
        <div style={{ width: '100%' }}>
          <section className='volunteers-area-3 pt-105 pb-90'>
            <div
              className='volunteers-bg'
              data-background='assets/img/about/home1/volunteers-bg.png'
            />
            <div className='container'>
              <div className='row'>
                <div className='col-xl-5 col-lg-5 col-md-12'>
                  <div
                    className='volunteers-section-3__wrapper mt-30 wow fadeInLeft'
                    data-wow-duration='1s'
                    data-wow-delay='.3s'
                  >
                    <span className='section-sub-title has-before'>
                      {' '}
                      Volunteers
                    </span>
                    <h3 className='section-title'>VOLUNTEER WITH US!</h3>
                    <p>
                      At SEEDS, we believe that every one of us has the ability
                      to make a meaningful contribution.
                    </p>

                    <div className='tpcta-3-btn'>
                      <a
                        className='tp-btn tp-btn-shine-effect'
                        href='contact-us.html'
                      >
                        Become a volunteer
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-xl-7 col-lg-7 col-md-12'>
                  <div
                    className='tpvolunteers__thumb p-relative wow fadeInRight'
                    data-wow-duration='1s'
                    data-wow-delay='.5s'
                  >
                    <div className='tpvolunteers-3__img-1 d-none d-md-block'>
                      <img src='assets/img/about/home1/volunteers1.jpg' alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div style={{ width: '100%' }}>
          <section className='volunteers-area-3 pt-105 pb-90'>
            <div
              className='volunteers-bg'
              data-background='assets/img/about/home1/volunteers-bg.png'
            />
            <div className='container'>
              <div className='row'>
                <div className='col-xl-5 col-lg-5 col-md-12'>
                  <div
                    className='volunteers-section-3__wrapper mt-30 wow fadeInLeft'
                    data-wow-duration='1s'
                    data-wow-delay='.3s'
                  >
                    <span className='section-sub-title has-before'>
                      {' '}
                      Volunteers
                    </span>
                    <h3 className='section-title'>COLLABORATE WITH US!</h3>
                    <p>
                      As SEEDS endeavours to make an impact on the lives of the
                      bottom most 1% in India, we seek supporters from across
                      the globe to help us make the change.
                    </p>

                    <div className='tpcta-3-btn'>
                      <a
                        className='tp-btn tp-btn-shine-effect'
                        href='contact-us.html'
                      >
                        Collaborate With Us
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-xl-7 col-lg-7 col-md-12'>
                  <div
                    className='tpvolunteers__thumb p-relative wow fadeInRight'
                    data-wow-duration='1s'
                    data-wow-delay='.5s'
                  >
                    <div className='tpvolunteers-3__img-1 d-none d-md-block'>
                      <img src='assets/img/about/home1/volunteers1.jpg' alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div style={{ width: '100%' }}>
          <section className='volunteers-area-3 pt-105 pb-90'>
            <div
              className='volunteers-bg'
              data-background='assets/img/about/home1/volunteers-bg.png'
            />
            <div className='container'>
              <div className='row'>
                <div className='col-xl-5 col-lg-5 col-md-12'>
                  <div
                    className='volunteers-section-3__wrapper mt-30 wow fadeInLeft'
                    data-wow-duration='1s'
                    data-wow-delay='.3s'
                  >
                    <span className='section-sub-title has-before'>
                      {' '}
                      Volunteers
                    </span>
                    <h3 className='section-title'>WORK WITH US!</h3>
                    <p>
                      Being a SEEDER brings new experiences every day. From
                      helping save lives in the aftermath of an emergency to
                      working with communities across India to reduce their
                      disaster risk.
                    </p>

                    <div className='tpcta-3-btn'>
                      <a
                        className='tp-btn tp-btn-shine-effect'
                        href='contact-us.html'
                      >
                        Work With Us
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-xl-7 col-lg-7 col-md-12'>
                  <div
                    className='tpvolunteers__thumb p-relative wow fadeInRight'
                    data-wow-duration='1s'
                    data-wow-delay='.5s'
                  >
                    <div className='tpvolunteers-3__img-1 d-none d-md-block'>
                      <img src='assets/img/about/home1/volunteers1.jpg' alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Slider> */}
    </>
  );
}

export default VolunteerSection;
