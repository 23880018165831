import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function Volunteer() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Volunteer'}
        sub_title={'Volunteer'}
        image={'/uploads/2020/07/Covid-Avenger.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <p>
                At SEEDS, we believe that every one of us can use their skills
                to make a meaningful contribution. We encourage all those
                interested in committing time and energy to our cause to check
                openings or get in touch. If you have the enthusiasm, we would
                be happy to help find the right fit.
              </p>
              <p>
                Write to us at{' '}
                <a href='mailto:volunteer@seedsindia.org'>
                  volunteer@seedsindia.org
                </a>{' '}
                to lend your valuable support.
              </p>
              <h3>PARTNER WITH US!</h3>
              <p>
                At SEEDS, we believe that every one of us has the ability to
                make a meaningful contribution. It doesn’t matter which walk of
                life one comes from, by leveraging one’s unique skills and
                talents, everyone with the will can help effect change. By
                contributing a fraction of your time and effort, you are able to
                make a large impact in our mission to help communities build
                their resilience and overcome the Covid-19 crisis together. Such
                is the power of volunteering!
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='volunteer-card'>
                <h4>For Individuals</h4>
                <p>
                  {' '}
                  <strong>Do you want to make a difference?</strong>{' '}
                </p>
                <p>
                  Now is the time! We would like to match your talent and
                  experience with a need. Quickly fill a short form and you are
                  a click away from making an impact!
                </p>
                <div>
                  <a
                    target='_blank'
                    href='https://docs.google.com/forms/d/e/1FAIpQLSewj7bkAC67O-G1YXbGH7o8NcZ2ZqugQUnJZ57OJIVERm_20A/viewform'
                  >
                    {' '}
                    CLICK HERE{' '}
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='volunteer-card'>
                <h4>For organisations</h4>
                <p>
                  {' '}
                  <strong>
                    Is your organisation seeking an impact partner?
                  </strong>{' '}
                </p>
                <p>
                  Your organisation (an NGO / subject matter expert) can partner
                  with us to provide a solution either at the door-step of the
                  end-user or through virtual support. Please click below to
                  tell us how we can collaborate.
                </p>
                <div>
                  <a
                    target='_blank'
                    href='https://forms.office.com/Pages/ResponsePage.aspx?id=7MYsc5wb7keq_F1qDJYBI5QRGR2jmLhEgIgU25W6NWNUN1E0U0JRN09CMEFTRVlZMUM3VjRJQlpCUS4u'
                  >
                    {' '}
                    CLICK HERE{' '}
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='volunteer-card'>
                <h4>For Employee Engagement</h4>
                <p>
                  {' '}
                  <strong>
                    Do you have a highly talented and enthused team that would
                    like to make a difference?
                  </strong>{' '}
                </p>
                <p>
                  Your (Corporate / MNC) employees can support us in developing
                  solutions for under-served and un-served people. Please click
                  below to tell us how we can collaborate.
                </p>
                <div>
                  <a
                    target='_blank'
                    href='https://forms.office.com/Pages/ResponsePage.aspx?id=7MYsc5wb7keq_F1qDJYBI5QRGR2jmLhEgIgU25W6NWNUQUNQMkFETVRSOVcyQzMwSFcyWDBKOTVHQS4u'
                  >
                    {' '}
                    CLICK HERE{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <h4>Contact Us</h4>
              <p>
                For volunteering opportunity and queries email{' '}
                <a href='mailto:volunteer@seedsindia.org'>
                  volunteer@seedsindia.org
                </a>{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Volunteer;
