import 'react-loading-skeleton/dist/skeleton.css';
// Import css files

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { Home } from './containers/Home/Home';
import OurStory from './containers/about/OurStory';
import Strategy2030 from './containers/about/Strategy2030';
import OurBoard from './containers/about/OurBoard';
import AllCampaigns from './containers/campaigns/AllCampaigns';
import SinglePortfolio from './containers/portfolios/SinglePortfolio';
import Founders from './containers/about/Founders';
import Awards from './containers/about/Awards';
import LeadershipTeam from './containers/about/LeadershipTeam';
import WhatWeDo from './containers/our-work/WhatWeDo';
import SeedsInAction from './containers/our-work/SeedsInAction';
import CertificateAlliances from './containers/accountability/CertificationAlliances';
import WaysOfWorking from './containers/accountability/WaysOfWorking';
import AnnualReports from './containers/accountability/AnnualReports';
import Financials from './containers/accountability/Financials';
import Testimonials from './containers/accountability/Testimonials';
import Policies from './containers/accountability/Policies';
import Volunteer from './containers/get-involved/Volunteer';
import CollaborateWithUs from './containers/get-involved/CollaborateWithUs';
import WorkWithUs from './containers/get-involved/WorkWithUs';
import ContactUs from './containers/get-involved/ContactUs';
import Events from './containers/events/Events';
import FaceOfDisaster from './containers/resources/FaceOfDisaster';
import SingleCampaign from './containers/campaigns/SingleCampaign';
import ProgrammeLearning from './containers/resources/ProgrammeLearning';
import ResearchAdvocacy from './containers/resources/ResearchAdvocay';
import ResourceForChildren from './containers/resources/ResourceForChildren';
import SeedsInMedia from './containers/resources/SeedsInMedia';
import MediaRelease from './containers/resources/MediaRelease';
import Sasakawaaward from './containers/about/Sasakawaaward';
import EducationLearning from './containers/resources/EducationTraning';
import BankTransfer from './containers/donation/BankTransfer';
import Donation from './containers/donation/Donation';
import NetajiSubhashBose from './containers/awards/NetajiSubhashBose';
import BusinessWorld from './containers/awards/BusinessWorld';
import Category from './containers/portfolio-category/Category';
import AIForHumantarian from './containers/portfolios/AIForHumantarian';

import { Accessibility } from 'accessibility/src/main';
import { useEffect } from 'react';
import { SeedsReflections } from './containers/newsletters/SeedsReflections';
import Blogs from './containers/blogs/Blogs';
import SingleBlog from './containers/blogs/SingleBlog';
import GujratEarthquake from './containers/20years-gujarat-earthquake/GujratEarthquake';

function App() {
  useEffect(() => {
    window.addEventListener(
      'load',
      function () {
        var options = {
          session: {
            persistent: false,
          },
          icon: {
            position: {
              bottom: { size: 50, units: 'px' },

              left: { size: 0, units: 'px' },

              type: 'fixed',
            },
          },
        };
        new Accessibility(options);
      },
      false
    );
  }, []);
  return (
    <Provider store={store}>
      <Routes>
        <Route path={'/'} element={<Home />} />
        {/* About Section Starts */}
        <Route path={'/our-story'} element={<OurStory />} />
        <Route path={'/campaigns'} element={<AllCampaigns />} />
        <Route
          path={'/portfolio/ai-for-humanitarian-action-with-microsoft/'}
          element={<AIForHumantarian />}
        />

        <Route path={'/portfolio/:slug'} element={<SinglePortfolio />} />
        <Route path={'/strategy-2030'} element={<Strategy2030 />} />
        <Route path={'/our-board'} element={<OurBoard />} />
        <Route path={'/founders'} element={<Founders />} />
        <Route path={'/awards'} element={<Awards />} />
        <Route path={'/leadership-team'} element={<LeadershipTeam />} />
        {/* About Section Ends */}

        {/* Our Work Starts */}
        <Route path={'/what-we-do'} element={<WhatWeDo />} />
        <Route path={'/seeds-in-action'} element={<SeedsInAction />} />
        {/* Our Work Ends */}
        {/* Accountability Starts */}
        <Route
          path={'/certifications-alliances'}
          element={<CertificateAlliances />}
        />
        <Route path={'/ways-of-working'} element={<WaysOfWorking />} />
        <Route path={'/banktransfers'} element={<BankTransfer />} />
        <Route path={'/donation'} element={<Donation />} />
        <Route path={'/annual-reports'} element={<AnnualReports />} />
        <Route path={'/financials'} element={<Financials />} />
        <Route path={'/testimonials'} element={<Testimonials />} />
        <Route path={'/policies'} element={<Policies />} />
        {/* Accountability Ends */}
        {/* Get Involved Starts Here */}
        <Route path={'/volunteer'} element={<Volunteer />} />
        <Route path={'/collaborate-with-us'} element={<CollaborateWithUs />} />
        <Route path={'/work-with-us'} element={<WorkWithUs />} />
        <Route path={'/contact-us'} element={<ContactUs />} />

        {/* Get Involved Ends Here */}
        {/* Event */}
        <Route path={'/resilience-cafe'} element={<Events />} />
        <Route path={'/blogs'} element={<Blogs />} />
        <Route path={'/blogs/:slug'} element={<SingleBlog />} />
        <Route
          path={'/newsletters/seeds-reflection'}
          element={<SeedsReflections />}
        />

        <Route path={'/faceofdisasters'} element={<FaceOfDisaster />} />
        <Route path={'/programme-learning'} element={<ProgrammeLearning />} />
        <Route
          path={'/education-and-training'}
          element={<EducationLearning />}
        />
        <Route path={'/research-advocacy'} element={<ResearchAdvocacy />} />
        <Route
          path={'/resources-for-children'}
          element={<ResourceForChildren />}
        />
        <Route path={'/seeds-in-the-media'} element={<SeedsInMedia />} />
        <Route path={'/media-release'} element={<MediaRelease />} />
        <Route path={'/sasakawaaward2022'} element={<Sasakawaaward />} />
        <Route path={'/portfolio-categories/:slug'} element={<Category />} />
        <Route
          path={'/20years-gujarat-earthquake'}
          element={<GujratEarthquake />}
        />
        <Route
          path={'/awards/netajisubhashchandraboseaward'}
          element={<NetajiSubhashBose />}
        />
        <Route
          path={'/awards/businessworld-future-of-design-awards-2021/'}
          element={<BusinessWorld />}
        />

        <Route path={'/:slug'} element={<SingleCampaign />} />
      </Routes>
    </Provider>
  );
}

export default App;
