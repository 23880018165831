import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function WaysOfWorking() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Ways Of Working'}
        sub_title={'Ways Of Working'}
        image={'/assets/images/breadcrumb/What-we-do-Header-Image.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='ways-box'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='ways-box-image'>
                  <img src='/assets/images/ways/Commitment-to-quality.jpg' />
                </div>
              </div>
              <div className='col-md-8'>
                <h4>Commitment to quality</h4>
                <p>
                  SEEDS has developed strong capacity in handling large scale
                  interventions while upholding the highest global standards of
                  quality and accountability. Whether in terms of relief,
                  construction, design or community processes, we have pioneered
                  cutting edge approaches. We support community innovations,
                  creating spaces on the interface of high-end technology and
                  traditional wisdom.
                </p>
              </div>
            </div>
          </div>
          <div className='ways-box'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='ways-box-image'>
                  <img
                    src='/assets/images/ways/People-at-the-centre.jpg'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <h4>People at the centre</h4>
                <p>
                  Everyone has the right to dignity, well-being and control over
                  their own lives. All of our initiatives are driven by that
                  particular community, with particular focus on involving women
                  and putting chidren first. Affected people themselves are the
                  core of each programme – leveraging their strengths, their
                  traditions and their aspirations. All relevant stakeholders,
                  including the local government, are actively involved in the
                  process, ensuring shared responsibility. Adopting such an
                  approach helps make the process as meangingful as the outcome.
                </p>
              </div>
            </div>
          </div>
          <div className='ways-box'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='ways-box-image'>
                  <img
                    src='/assets/images/ways/Leaving-no-one-behind.jpg'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <h4>Leaving no one behind</h4>
                <p>
                  A community is safe only when its most vulnerable members are
                  safe. Our programmes focus on areas with little access to
                  other assistance, those on the margins and the socially
                  excluded. We actively seek out the silent voices and follow
                  comprehensive triangulation methods to identify where to
                  invest limited resources.
                </p>
              </div>
            </div>
          </div>
          <div className='ways-box'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='ways-box-image'>
                  <img
                    src='/assets/images/ways/Sustainability-Matters-1.jpg'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <h4>Sustainability Matters</h4>
                <p>
                  Across our range of initiatives, we seek to leave the lowest
                  carbon footprint possible while fulfilling the need. From
                  designs and materials to planning and procurement processes,
                  this is an underlying goal.
                </p>
              </div>
            </div>
          </div>
          <div className='ways-box'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='ways-box-image'>
                  <img src='/assets/images/ways/local-action.jpg' alt='' />
                </div>
              </div>
              <div className='col-md-8'>
                <h4>Local action, regional collaboration, global influence</h4>
                <p>
                  SEEDS is a strong proponent of localisation and the power of
                  local action. Both our programme content and ways of working
                  refect this view, partnering with grassroot organisations and
                  strengthening local capabilities.
                </p>
                <p>
                  At the same time, we play a leading role in prominent regional
                  and global networks to advocate resilience approaches and
                  influence the policy environment. Informed by our extensive
                  work on the ground, we have advised inter-governmental
                  agencies, the UN, national governements and civil society.
                </p>
              </div>
            </div>
          </div>
          <h4>A commitment to global frameworks</h4>
          <p>
            Achieving the goals of global frameworks – the Sendai Framework for
            Disaster Risk Reduction (SFDRR), Sustainable Development Goals
            (SDGs) and the Paris Agreement – can only happen with work done on
            the ground. SEEDS programmes and ways of working help further this.
          </p>
          <p>
            Our initiatives align across the seven targets of the SFDRR. They
            contribute directly to SDG 1 on reducing poverty; SDG 5 on gender
            equality; SDG 6 on clean water and sanitation; SDG 11 on sustainable
            cities and communities; SDG 13 on climate action; and SDG 15 on life
            on land. We also bring community voices and adaptation action to
            furthering goals of the Paris Agreement.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default WaysOfWorking;
