import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import BrandSection from '../../components/Home/BrandSection';

function CollaborateWithUs() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Collaborate With Us'}
        sub_title={'Collaborate With Us'}
        image={'/uploads/2020/07/MG_1074.jpg'}
      />

      <section
        className='tpblog-area collaborate pt-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='wpb_wrapper'>
                <p>
                  SEEDS’ way of working is highly collaborative. Our belief is
                  that we can do more together.
                </p>
                <p>
                  <strong>Local CBOs and NGOs:</strong> We work with
                  community-based organisations and local NGOs across India,
                  combining skillsets to deliver programmes more effectively.
                </p>
                <p>
                  <strong>Start-ups:</strong> We collaborate with start-ups to
                  find entrepreneurial solutions and innovative approaches to
                  new challenges.
                </p>
                <p>
                  <strong>Technology companies: </strong>We learn from
                  technology companies to help strengthen our offering and adopt
                  tools to widen our reach and impact.
                </p>
                <p>
                  <strong>Academia:</strong> We put our heads together with
                  academic institutions to base our initiatives on robust
                  research.
                </p>
                <p>
                  Interested in collaborating on an area of our work?&nbsp; Get
                  in touch with us at{' '}
                  <a
                    href='mailto:partnership@seedsindia.org'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    partnership@seedsindia.org
                  </a>{' '}
                  to see how we can join forces.
                </p>

                <h3>Support Us</h3>
                <div className='title-desc'>
                  <h4>Corporate and Institutional Supporters</h4>
                  <p>
                    As SEEDS endeavours to make an impact on the lives of the
                    bottom most 1% in India, we seek supporters from across the
                    globe to help us make the change.
                  </p>
                  <p>
                    SEEDS’ corporate and institutional supporters have helped us
                    make significant strides over the years. &nbsp;They share
                    our commitment to standing with vulnerable communities and
                    the belief that everyone has the right to dignity, well-
                    being and control over their own lives.
                  </p>
                  <p>
                    These partnerships help catalyse solutions to meet the needs
                    of specific communities. Each of our supporters has been
                    actively involved. They help co-design programmes with us on
                    ground, ensure programmes achieve measurable outcomes and
                    help bring lasting impact. To know more about our ongoing
                    efforts{' '}
                    <a
                      href='/seeds-in-action/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      click here
                    </a>
                  </p>
                  <p>
                    <strong>
                      Support our programmes through Corporate Social
                      Responsibility
                    </strong>
                    : We would be happy to have you on board, finding areas
                    where your contribution would have maximum impact. Our broad
                    range of initiatives within disaster risk reduction include
                    urban and rural infrastructure; water, sanitation and
                    hygiene; public health; environment and ecology; sustainable
                    livelihoods; and children’s safety.
                  </p>
                  <p>
                    <strong>Special Collaborations:</strong>&nbsp;Are there
                    areas where our work aligns to your core areas or
                    geographical presence? Together, we can bring broader impact
                    to those communities.
                  </p>
                  <p>
                    <strong>Employee Giving</strong>: Get your staff to
                    contribute for a cause and together bring about a larger
                    change.
                    <br /> Your employees can get their donations matched by the
                    employer. We can design and implement a customised employee
                    giving campaign to match your organisation’s employee giving
                    campaign. To know more, please write to us at{' '}
                    <a href='mailto:partnership@seedsindia.org'>
                      partnership@seedsindia.org
                    </a>
                  </p>
                  <p>
                    <strong>Disaster Relief:</strong> Support our ongoing
                    humanitarian response and recovery programmes to help us
                    reach affected communities in a timely and dignified manner.{' '}
                    <a href='/donate/'>Know how to support</a>
                  </p>
                  <p>
                    <strong>
                      For corporate and partnership related queries, please
                      contact:
                    </strong>
                    <br />{' '}
                    <a href='mailto:partnership@seedsindia.org'>
                      partnership@seedsindia.org
                    </a>
                  </p>
                  <h3>Support us as an Individual</h3>
                  <p>
                    Our team works with the most marginalised communities in
                    India to bring about a sustainable change in their lives.
                    Your donation helps ensure that these communities are having
                    a better future. You can choose to donate either to an
                    emergency response or a general donation to support our
                    ongoing risk reduction programmes.
                  </p>
                  <p>
                    We endeavour to provide you with regular updates on our work
                    to showcase the effect you’ve helped create.
                  </p>
                  <p>
                    Any donation made to SEEDS is tax deductible under Section
                    80G. You will receive a receipt in order to avail benefits
                    as per the applicable laws.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrandSection noPaddingTop />

      <Footer />
    </div>
  );
}

export default CollaborateWithUs;
