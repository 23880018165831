import React from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { Board } from '../../shared/models/Board';

function OurBoard() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Our Board'}
        sub_title={'Our Board'}
        image={'/uploads/2020/08/OUR-BOARD-Header-Image.jpg'}
      />
      <section className='tf-section section-team s1 background-section pt-50 pb-50'>
        <div className='container'>
          {Board &&
            Board.map((item) => {
              return (
                <div className='team-section'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='team-image'>
                        <div className='img-box'>
                          <img src={item.profile} className='img-fluid' />
                        </div>
                        <div className='team-wrapper'>
                          <h5 className='name'>{item.name}</h5>
                          <p className='category'>{item.position}</p>
                          <div className='d-flex social-links-gap'>
                            {item.linkedin && (
                              <div className='linkedin-url'>
                                <a
                                  className='link-main'
                                  href={item.linkedin}
                                  target='_blank'
                                >
                                  {' '}
                                  Linkedin{' '}
                                </a>
                              </div>
                            )}
                            {item.twitter && (
                              <div className='linkedin-url'>
                                <a
                                  className='link-main'
                                  href={item.twitter}
                                  target='_blank'
                                >
                                  {' '}
                                  Twitter{' '}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='team-content'>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.details }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default OurBoard;
