import React from 'react';

export const QuoteCard = ({ quotes, author, designation, video, link }) => {
  return (
    <div style={{ borderBottom: '1px solid #f1f1f1', padding: '10px 0px' }}>
      <div className='row'>
        <div className='col-md-4'>
          <iframe
            width={'100%'}
            height={200}
            src={`https://www.youtube.com/embed/${video}`}
            title='YouTube video player'
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
        <div className='col-md-8'>
          <div>
            {quotes.map((quote) => {
              return <p>{quote}</p>;
            })}
          </div>

          <p>
            <strong style={{ color: '#ed9e36' }}>{author}, </strong>{' '}
            {designation}
          </p>
        </div>
      </div>
    </div>
  );
};
