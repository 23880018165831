import React from 'react';
import { useField } from 'formik';
export const SelectBox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='form-group'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        id={props.name}
        className='form-control custom-select'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='form-group'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input id={props.name} className='form-control' {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const TextInputHorizontal = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='form-group'>
      <div className='col-md-2'>
        <label htmlFor={props.id || props.name}>{label}</label>
      </div>
      <div className='col-md-10'>
        <input className='form-control' {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className='text-danger'>{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export const TextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='form-group'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea
        id={props.name}
        className='form-control'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </div>
  );
};
export const RadioButton = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <input type='radio' className='form-check-input' {...field} {...props} />
      <label htmlFor={props.id || props.name}> {label} </label>
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </>
  );
};

export const CheckBox = ({ children, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className='form-group'>
      <div className='pt-3'>
        <label className='checkbox'>
          <input type='checkbox' {...field} {...props} />
          {'  '} {children}
        </label>
        {meta.touched && meta.error ? (
          <div className='error'>{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};
