import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { PRODUCT, TAGS, TAGS_SLUG } from '../../domain/modals/product';
import { Link, useParams } from 'react-router-dom';
function Category() {
  const [activeTag, setActiveTag] = useState('ALL');
  const params = useParams();

  useEffect(() => {
    if (params.slug) {
      const position = TAGS_SLUG.indexOf(params.slug);
      setActiveTag(TAGS[position]);
    }
  }, [params.slug]);
  return (
    <div>
      <Header />
      <BreadCrumb title={activeTag} sub_title={'Our Story'} />
      <div className='tf-section section-about style-2 pt-50 pb-50'>
        <div className='container'>
          <div className='row'>
            {activeTag &&
              PRODUCT &&
              PRODUCT.map((item) => {
                if (
                  activeTag !== 'ALL' &&
                  item.tags &&
                  item.tags.includes(activeTag)
                ) {
                  return (
                    <div className='col-md-3'>
                      <div className='mb-3 single-project'>
                        <div
                          className='tp-volunteers-2__img1  wow fadeInLeft grayscale'
                          data-wow-duration='1s'
                          data-wow-delay='.5s'
                        >
                          <img src={item.img} className='project-image' />
                          <div className='hover-overlay'>
                            <Link to={`/portfolio/${item.slug}`}>
                              <div
                                className='d-flex hover-content justify-content-center align-items-end'
                                style={{ height: '100%' }}
                              >
                                <div className='title'> {item.name} </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Category;
