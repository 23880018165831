import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function LeadershipTeam() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Leadership Team'}
        sub_title={'Leadership Team'}
        image={'/uploads/2017/07/staff2-540x500.jpg'}
      />
      <section className='tf-section section-team s1 background-section pt-50 pb-50'>
        <div className='container'>
          {/* <div className='team-section'>
            <div>
              <h4> Mihir Joshi, Chief Convener</h4>
              <p>
                Email :
                <a href='mailto:mihir@seedsindia.org'> mihir@seedsindia.org </a>
              </p>
              <p>
                Mihir has almost two decades of experience in disaster
                management and recovery. It began after the Gujarat earthquake
                of 2001 where he worked on reconstruction and led a team
                engineers and construction workers to implement mason training
                certification courses. Over the years, Mihir has led SEEDS’
                technical teams on various reconstruction works. He’s imparted
                trainings nationally and internationally. He has also served as
                the Coordinator for the Asian Disaster Reduction and Response
                Network, forging partnerships across the region. Mihir has a
                Bachelors in Engineering with a specialization in environment
                engineering and a post graduate diploma on earthquake resistance
                designing of RC buildings.
              </p>
            </div>
          </div> */}
          <h3 className='pt-3'>Programme</h3>
          <div className='team-section'>
            <div>
              <h4> Yezdani Rahman, Chief of Programmes</h4>
              <p>
                Email :
                <a href='mailto:rahman@seedsindia.org'>
                  {' '}
                  rahman@seedsindia.org{' '}
                </a>
              </p>
              <p>
                Rahman has been growing with SEEDS since 2005. He has led on a
                vast array of initiatives, covering humanitarian emergency
                response; recovery planning and execution; and capacity building
                of key stakeholders. Rahman has worked on programmes in India,
                Nepal, Bangladesh, Indonesia, the Maldives and Afghanistan. He
                is trained on search & rescue, first aid and fire safety; and
                also serves as a master trainer on these skills. Rahman holds a
                Bachelor in Commerce.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4>
                Sumeet Agarwal, Senior Director – Project Management and Control
              </h4>

              <p>
                Sumeet manages the timely delivery and budget adherence of our
                programmes, with a view to problem solving early.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4>Parag Talankar, Director – Planning and Mobilisation</h4>

              <p>
                Parag builds our base of ambassadors, changemakers and
                partnerships with community organisations across India. This
                helps ensure programme visions can be implemented in areas
                across the country.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4> Aakash Vishwakarma, Lead – Architecture and Innovations</h4>

              <p>
                Aakash leads on designing and translating building visions on
                the ground. Done in partnership with the specific community, he
                brings the sense of innovation to all our reconstruction
                programmes.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4> Shafat Mir, Lead – Construction Management</h4>

              <p>
                Shafat works with construction teams to oversee daily issues,
                ensure quality and timely completion, especially for our larger
                scale buildings.
              </p>
            </div>
          </div>
          <h3>Operations and Partnerships</h3>
          <div className='team-section'>
            <div>
              <h4> Arijit Das, Senior Director Fund Raising & Advocacy</h4>

              <p>
                Arijit leads on fundraising and donor relationships, working on
                a symbiotic system where donors are also partners in our work.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4> Salek Gehlot, Lead – Administration</h4>

              <p>
                Salek manages recruitments, day-to-day administration and the
                smooth running of SEEDS’ head office.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4> Arvind Sharma, Digital Specialist</h4>

              <p>
                Arvind helps keep SEEDS staff connected, exploring new
                technologies and online systems to power our work.
              </p>
            </div>
          </div>
          <h3 className='mt-3'>Communications</h3>
          <div className='team-section'>
            <div>
              <h4> Meghna Chawla, Chief Communications Officer</h4>
              <p>
                Email :
                <a href='mailto:meghna@seedsindia.org'>
                  {' '}
                  meghna@seedsindia.org
                </a>
              </p>
              <p>
                Meghna has over 18 years of experience in the communications
                profession. She’s run social sector campaigns, developed
                innovative behaviour change communication materials and
                collaborated on training and advocacy components for research
                uptake. With a blend of advertising and social sector
                experience, Meghna brings vast knowledge of a range of
                communication strategies and the ability to deliver to context.
                Meghna has a Bachelor in Fine Arts from College of Art, Delhi,
                and a Masters in Business Administration.
              </p>
            </div>
          </div>
          {/* <div className='team-section'>
            <div>
              <h4> Sabiha Ansari, Lead – Communications</h4>

              <p>
                In order to further SEEDS’s mission and vision, Sabiha assists
                in developing and implementing communication strategies to
                emphasise brand visibility on the website and social media
                platforms; and to strengthen the media presence and visual
                documentation.
              </p>
            </div>
          </div> */}
          <h3 className='pt-3'>Finance</h3>
          <div className='team-section'>
            <div>
              <h4> Bittu Malik, Chief Finance Officer</h4>
              <p>
                Email :
                <a href='mailto:bittu@seedsindia.org'> bittu@seedsindia.org</a>
              </p>
              <p>
                Bittu has 20 years of experience in finance and accounting, the
                majority in the social sector. Over the years, he has led the
                setting up of branch office systems; managed liquidity and
                payroll; worked on donor financial reporting; and balanced both
                project and overall annual budgets. Bittu also oversees all
                income tax & FCRA compliance. He holds a Masters in Commerce.
              </p>
            </div>
          </div>
          <div className='team-section'>
            <div>
              <h4> Rajesh Kumar, Lead – Finance and Accounts</h4>

              <p>
                Rajesh assists with all aspects of finance and accounts for
                SEEDS, with a focus on payroll and financial reporting.
              </p>
            </div>
          </div>
          {/* <h3 className='pt-3'>Regional Team</h3>

          <p>
            <strong>
              Anurodh Saxena, Regional Manager, North-Western Region{' '}
            </strong>
          </p>

          <p>
            <strong>
              Kamal Kumar Tripathi, Regional Manager, East Region{' '}
            </strong>
          </p>
          <p>
            <strong>Rozina Rupani, Regional Manager, Western Region</strong>{' '}
          </p> */}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default LeadershipTeam;
