export const TESTIMONIALS = [
  {
    testimonial: `We are getting updates on the Kosi river data update
    through a WhatsApp Group from SEEDS and Task Force
    Members. This information is helpful for us. As per
    the water level, we are taking action during monsoon
    time. Really this is a great initiative taken by
    SEEDS. I also forward these messages to hundreds of
    people who live between the river and the embankment.`,
    name: 'Rajendra Yadav',
    image: '/assets/img/testimonial/quote-01.jpg',
    designation: ` a retired teacher from the Govt. school Tegara,
    Mahesaraho about our early warning initiatives in
    Saharsa, Bihar (June 2019)`,
  },
  {
    testimonial: `The initiative taken by SEEDS to conduct task force trainings in schools under Honeywell School Safety Program is highly appreciable. I really like how trainers have facilitated the training and explained every aspect related to first aid. They encouraged the participants to ask questions. The trainings’ impact certainly goes beyond the boundaries of the schools and would enable communities in building resilience.`,
    name: 'Mann Singh Yadav',
    image: '/assets/img/testimonial/quote-02.jpg',
    designation: `Teacher at R.S.B.V Rani Garden about our Honeywell Safe Schools Programme in Delhi (July 2019)`,
  },
  {
    testimonial: `<div>Developing a 360-degree understanding of disaster management is very important in our city, especially in unauthorised colonies and slum areas. The need to educate children about disaster, not just limiting to their schools, but even at homes, and in their daily lives is very important.</div>

<div>    We should enlighten the kids and sensitise them towards safety hazards with respect to architectural and structural faults of their homes, schools and other buildings. If we teach our children about disaster management, I am sure they will evolve as thinking adults who will strive to abide by the safety parameters in their homes, schools and nearby buildings. We have to empower our young minds to question the serious safety hazards they are exposed to in their day-to-day life.</div>`,
    name: 'Shri Manish Sisodia',
    image: '/assets/img/testimonial/quote-03.jpg',
    designation: `Deputy Chief Minister and Education Minister, Delhi`,
  },
];
