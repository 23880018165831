import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { Link } from 'react-router-dom';

function Awards() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Awards & Honours'}
        sub_title={'Awards & Honours'}
        image={'/uploads/2022/04/awardsimg.jpg'}
      />
      <section
        className='tpblog-area award-page pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='award-section'>
                <div className='main-image'>
                  <img src='/assets/images/awards/awardsimg.jpg' />
                </div>

                <p>
                  Through the years, SEEDS’s work has been recognised by the
                  government as well as national and international institutions.
                  We are thankful for their faith and belief in our endeavours
                  by recognising our efforts. It encourages us and strengthens
                  our belief that we are on the right path whilst reminding us
                  of the great responsibility we carry towards empowering the
                  marginalised for a disaster-ready future.
                </p>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/thesasakawa.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4>
                    SEEDS wins United Nations Sasakawa Award 2022 for Disaster
                    Risk Reduction
                  </h4>
                  <p>
                    SEEDS has been awarded the prestigious UNDRR prize that is
                    bestowed upon individuals or institutions who have taken
                    active efforts in reducing disaster risk in their
                    communities and advocated for disaster risk reduction. The
                    ceremony was held at the Global Platform for Disaster Risk
                    Reduction 2022 in Bali, Indonesia.
                  </p>
                  <div>
                    <a href='/sasakawaaward2022/'> Read More </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/awardthumbail.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    SEEDS Conferred with the Highest National Honour for
                    Excellence in the Field of Disaster Management
                  </h4>
                  <p>
                    SEEDS was awarded the Subhash Chandra Bose Aapda Prabandhan
                    Award 2021 in Disaster Management on 23 January 2022, by our
                    Honourable Prime Minister Shri Narendra Modi for excellence
                    in the field of disaster management. The award recognises
                    and honours the contribution and service rendered by
                    individuals and organisations in India in the field of
                    Disaster Management by Government of India every year on 23
                    January, the birth anniversary of Netaji Subhash Chandra
                    Bose.
                  </p>
                  <div>
                    <a href='/awards/netajisubhashchandraboseaward/'>
                      {' '}
                      Read More{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/designawardhorizontal.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    Silver Prize in BW Businessworld Future of Design Awards
                    2021 Presented to SEEDS{' '}
                  </h4>
                  <p>
                    Our focus of creating and designing a sustainable world was
                    recognised with a prize in the third edition of Business
                    World’s Future of Design Award in the ‘Space Category’. The
                    award highlighted our work in developing disaster-resilient
                    construction techniques in housing for the most vulnerable
                    communities in Assam that are hit by floods almost every
                    year.
                  </p>
                  <div>
                    <a href='/awards/businessworld-future-of-design-awards-2021/'>
                      {' '}
                      Read More{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/Worldbankcreative.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    Special Mention by the Jury of ‘Resilient Homes Design
                    Challenge’ 2018
                  </h4>
                  <p>
                    SEEDS received a special mention in the Resilient Homes
                    Design Challenge hosted by The World Bank, Build Academy,
                    Airbnb, the Global Facility for Disaster Reduction and
                    Recovery (GFDRR), and UN-Habitat for our sustainable and
                    rehabilitative design solutions in the Himalayas.
                  </p>
                  <div>
                    <a href='https://buildacademy.com/project-seeds/'>
                      {' '}
                      Read More{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/WEF.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    World Economic Forum (WEF) Recognition as One of India’s Top
                    50 COVID-19 Responders
                  </h4>
                  <p>
                    World Economic Forum and COVID Response Alliance For Social
                    Entrepreneurs recognised SEEDS as one of India’s Top 50
                    #COVID19 Last Mile Responders. At a time when mainstream
                    support systems had been stretched, we stepped up to fill
                    the gaps, especially in hard-to-reach poor or rural
                    communities.
                  </p>
                  <div>
                    <a href='https://www.weforum.org/agenda/2021/08/50-ways-india-s-last-mile-covid-19-responders-are-leading-the-way-to-a-just-recovery/'>
                      {' '}
                      Learn more about our work here.{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/UNISDR.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    UNDRR recognised SEEDS as a Sendai Target Champion for
                    reducing disaster mortality at Asia Ministerial Conference
                    for Disaster Risk Reduction (AMCDRR) 2016
                  </h4>
                  <p>
                    The International Day for Disaster Reduction marked the
                    beginning of the “Sendai Seven Campaign: Seven Targets,
                    Seven Years” by the UNISDR. This campaign references the
                    seven targets for reducing disaster losses in the Sendai
                    Framework for Disaster Risk Reduction adopted by UN Member
                    States in March, 2015. Mr Glasser named SEEDS as one of the
                    “Sendai Target Champions for Reducing Disaster Mortality”.
                  </p>
                  <div>
                    <a href='/unisdr-recognises-seeds-efforts-towards-reducing-disaster-mortality/'>
                      {' '}
                      Read More{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='award-card'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='award-image'>
                  <img
                    src='/assets/images/awards/cnbaward.jpg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div>
                  <h4 className='award-title'>
                    SEEDS was adjudged “Indian of the year 2010” in the Public
                    Service Section Category by CNN-IBN and awarded by Hon’ble
                    President Shri Pranab Mukherjee.
                  </h4>
                  <p>
                    In 2010 SEEDS constructed locally appropriate houses post
                    cloudburst & flash floods that swept the Leh, Ladakh region.
                    SEEDS work in extreme terrain, geography and relentless
                    spirit won the organisation CNN-IBN Indian of the Year for
                    Public Service award.
                  </p>
                  <div>
                    <a href='https://www.youtube.com/watch?v=BR2BVQKlFEU'>
                      {' '}
                      Watch{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The Gyalwang Drukpa Award 2011 for rehabilitation work carried out
            in Ladakh
          </p>
          <p>
            <a href='/seeds-film-catalysts-of-change-ladakh-awarded-as-the-best-film-on-climate-change-adaptation-at-amcdrr-2016'>
              ‘Catalysts of Change – Ladakh’ awarded best film in the Climate
              Change Adaptation category at Asia Ministerial Conference for
              Disaster Risk Reduction 2016
            </a>
          </p>
          <p>
            <a href='https://www.abu.org.my/2017/05/19/winners-of-the-2nd-abu-awards-on-climate-change-and-drr/'>
              Asian Broadcasting Union (ABU) award for ‘Catalysts of Change
              Ladakh’ in 2017{' '}
            </a>
          </p>
          <p>
            <a>
              Best Civil Society Innovator in Environment and Sustainability
              category for eNGO Challenge Award 2020
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Awards;
