import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';
import { PROGRAMME_LEARNING } from '../../domain/modals/resources';

function ProgrammeLearning() {
  return (
    <div>
      <Header />
      <BreadCrumb
        title={'Programme Learning'}
        image={'/uploads/2017/07/IMG_0417-1-1.jpg'}
      />
      <section
        className='tpblog-area pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div>
                <a href='/seeds_selco-foundation_project-documentation-report_16-01-2021-compressed/'>
                  <img src='/uploads/2021/01/sustainable_energy_report.jpg' />
                </a>
              </div>
            </div>
            <div className='col-md-8'>
              <h3>
                Sustainable Energy for Disaster Resilience – Cyclone Fani
                Response 2019
              </h3>
              <p>
                The report document discusses the relevance of converging
                humanitarian action and sustainable energy access to facilitate
                faster recovery after a disaster and build long-term resilience.
                It provides an overview of our work in partnership with SELCO
                Foundation in the Cyclone Fani affected region of Puri district,
                Odisha.
              </p>
              <div>
                <a href='/assets/images/seeds_selco-foundation_project-documentation-report_16-01-2021-compressed.pdf'>
                  Download
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className='row'>
            {PROGRAMME_LEARNING &&
              PROGRAMME_LEARNING.map((item) => {
                return (
                  <div
                    className='col-md-4'
                    style={{
                      borderBottom: '1px solid #f1f1f1',
                      marginBottom: '20px',
                      paddingBottom: '10px',
                    }}
                  >
                    <div className='simple-card'>
                      <div>
                        <a href={item.download_link}>
                          <img src={item.image} />
                        </a>
                      </div>
                      <div>
                        <h4 className='mt-10'>
                          {' '}
                          <strong> {item.name} </strong>
                        </h4>
                        <p> {item.content} </p>
                        <div>
                          <a href={item.download_link}> Download </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ProgrammeLearning;
