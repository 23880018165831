import React from 'react';
import { Link } from 'react-router-dom';

function VideoSection() {
  return (
    <div>
      <section className='volunteers-area-3 pt-105 pb-90'>
        <div
          className='volunteers-bg'
          data-background='assets/img/about/home1/volunteers-bg.png'
        />
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-5 col-md-5'>
              <div
                className='volunteers-section-3__wrapper mt-30 wow fadeInLeft'
                data-wow-duration='1s'
                data-wow-delay='.3s'
              >
                <h3 className='section-title'> Our Purpose</h3>
                <p>
                  SEEDS is committed to enabling the bottom one percent of
                  communities vulnerable to the climate emergency to adapt,
                  anticipate and act to secure their future and of the larger
                  community. Through our strategy we aim to strengthen the
                  resilience of 300 million disaster-affected people in the
                  country by the year 2030.
                </p>
                <div className='tpvolunteers-2-btn'>
                  <Link
                    className='tp-btn tp-btn-shine-effect'
                    to='/strategy-2030'
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              <iframe
                src='https://www.youtube.com/embed/u8okcsxfgY0'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; '
                allowFullScreen
                className='video-section-video'
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className='about-area-2'>
        <div className='about-bg' />
        <div className='container'>
          <div className='tp-about-box tp-about-box-translate'>
            <div className='row align-items-center'>
              <div className='col-xl-8 col-lg-8 col-md-12'>
                <div className='tp-about__left'>
                  <span className='section-sub-title has-before'>
                    Our Target{' '}
                  </span>
                  <h3 className='section-title'>
                    The mission and goals of our organization
                  </h3>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='tp-about__right d-flex justify-content-lg-end'>
                  <div
                    className='tp-about__btn wow fadeInRight'
                    data-wow-duration='1s'
                    data-wow-delay='.3s'
                  >
                    <a className='tp-btn-black tp-btn-shine-effect' href='#'>
                      About SEEDS
                    </a>
                  </div>
                  <div
                    className='about__play-btn ml-30 wow fadeInRight'
                    data-wow-duration='1s'
                    data-wow-delay='.5s'
                  >
                    <a
                      className='play-video popup-video'
                      href='https://www.youtube.com/watch?v=hdw1dThZzT0'
                      target='_blank'
                    >
                      <i className='fa-solid fa-play' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default VideoSection;
