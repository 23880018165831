import React from 'react';

function BreadCrumb({ title, sub_title, image }) {
  return (
    <div className='wrapper-box p-relative'>
      <div
        className='breadcrumb-bg grayscale'
        data-background='/assets/img/breadcrumb/breadcrumb-bg.png'
        style={{
          backgroundImage: `url(${
            image ? image : '/uploads/2020/06/Covidinnercover.jpg'
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className='breadcrumb-area pt-265 pb-265'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='breadcrumb__content text-center'>
                <h1 className='breadcrumb__title'>{title}</h1>
                {/* <div className='breadcrumb__list'>
                  <span>
                    <a href='index.html'>Home</a>
                  </span>
                  <span className='dvdr'>/</span>
                  <span>{sub_title}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;
