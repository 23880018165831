import { Formik, Form } from 'formik';
import React, { useState } from 'react';

import {
  CheckBox,
  RadioButton,
  SelectBox,
  TextInput,
} from '../components/form/Form';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { CURRENCY } from '../domain/modals/currency';

function DonationForm() {
  const location = useLocation();
  console.log('Location', location);
  const params = new URLSearchParams(location.search);
  const source = params.get('source');
  console.log('Source', source);
  const phoneRegExp =
    '/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/';

  return (
    <div>
      <div className='sidebar__widget donation-details mb-40 box-shadow'>
        <div className='donation-title'>
          <h3>Donate</h3>
          <hr />
        </div>
        <Formik
          initialValues={{
            name: '',
            amount: '',
            phone: '',
            email: '',
            citizen_type: null,
            user_pan: '',
            currency: 'INR',
            user_country: '',
            user_state: '',
            user_city: '',
            user_pincode: '',
            user_address1: '',
            user_address: '',
            user_passport: '',
            payment_method: 'razorpay',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            amount: Yup.string().required('Required'),
            phone: Yup.string()
              .required('required')
              .min(10, 'Invalid Phone')
              .max(10, 'Invalid Phone'),
            email: Yup.string().required('Required'),

            user_pan: Yup.string().when(
              ['citizen_type', 'pan_chackbox', 'amount'],
              {
                is: (citizen_type, pan_chackbox, amount) => {
                  return (
                    (citizen_type === 'INDIAN' && pan_chackbox) ||
                    (citizen_type === 'INDIAN' && amount > 5000)
                  );
                },
                then: (schema) =>
                  schema
                    .required('Required')
                    .matches(
                      '[A-Z]{5}[0-9]{4}[A-Z]{1}',
                      'Please Enter Valid Pan Card'
                    ),
              }
            ),
            user_passport: Yup.string().when(['citizen_type'], {
              is: (citizen_type) => {
                return citizen_type === 'FOREIGN';
              },
              then: (schema) => schema.required('Required'),
            }),
            // user_pan: Yup.string().required('Required'),
            // user_pan: Yup.string()
            //   .required('Required')
            //   .matches(
            //     '[A-Z]{5}[0-9]{4}[A-Z]{1}',
            //     'Please Enter Valid Pan Card'
            //   ),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            let url = `https://seedsindia.org/donations/`;
            if (values.citizen_type === 'FOREIGN') {
              url = url + 'international.php';
            }
            if (values.citizen_type === 'INDIAN') {
              url = url + 'payment.php';
            }

            window.location.href = `${url}?donor_type=${
              values.citizen_type
            }&donation_amount=${values.amount}&payment_method=${
              values.payment_method
            }&full_name=${values.name}&user_phone=${values.phone}&user_email=${
              values.email
            }&user_address=${values.user_address}&user_address1=${
              values.user_address1
            }&user_country=${values.user_country}&user_state=${
              values.user_state
            }&user_city=${values.user_city}&user_pincode=${
              values.user_pincode
            }&user_passport=${values.user_passport}&user_pan=${
              values.user_pan
            }&source=${source}&currency=${
              values.citizen_type === 'INDIAN' ? 'INR' : values.currency
            }`;
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log(formik);
            return (
              <Form method='post' action='/submit.php'>
                <div>
                  {' '}
                  <p> Select Options </p>{' '}
                </div>
                <div className='row'>
                  <div className='col-md-6 radio-button'>
                    <RadioButton
                      id='citizen_type_indian'
                      label='Indian Citizen'
                      name='citizen_type'
                      value='INDIAN'
                      checked={formik.values.citizen_type === 'INDIAN'}
                    />
                  </div>
                  <div className='col-md-6 radio-button'>
                    <RadioButton
                      id='citizen_type_foreign'
                      label='Foreign Citizen'
                      name='citizen_type'
                      value='FOREIGN'
                      checked={formik.values.citizen_type === 'FOREIGN'}
                    />
                  </div>
                </div>

                {formik.values.citizen_type === 'INDIAN' && (
                  <div className='col-md-12'>
                    <label htmlFor='Amount'>Amount</label>
                    <div className='donation-payment-method d-flex flex-wrap '>
                      <button
                        className={formik.values.amount === 800 && 'active'}
                        onClick={() => {
                          formik.setFieldValue('amount', 800);
                          formik.setFieldValue('other_type', false);
                        }}
                        type='button'
                      >
                        ₹800
                      </button>
                      <button
                        className={formik.values.amount === 2000 && 'active'}
                        onClick={() => {
                          formik.setFieldValue('amount', 2000);
                          formik.setFieldValue('other_type', false);
                        }}
                        type='button'
                      >
                        ₹2000
                      </button>
                      <button
                        className={formik.values.amount === 5000 && 'active'}
                        onClick={() => {
                          formik.setFieldValue('amount', 5000);
                          formik.setFieldValue('other_type', false);
                        }}
                        type='button'
                      >
                        ₹5000
                      </button>
                      <button
                        className={formik.values.other_type && 'active'}
                        type='button'
                        onClick={() => {
                          formik.setFieldValue('other_type', true);
                          formik.setFieldValue('amount', null);
                        }}
                      >
                        Other{' '}
                      </button>
                    </div>
                  </div>
                )}
                {formik.values.citizen_type === 'FOREIGN' && (
                  <div className='col-md-12'>
                    <SelectBox label='Currency' name='currency'>
                      <option value=''> Select Currency </option>
                      {CURRENCY &&
                        CURRENCY.map((item) => {
                          return <option value={item.cc}> {item.name} </option>;
                        })}
                    </SelectBox>
                  </div>
                )}
                {formik.values.citizen_type === 'FOREIGN' &&
                  formik.values.currency && (
                    <div className='col-md-12'>
                      <label htmlFor='Amount'>Amount</label>
                      <div className='donation-payment-method d-flex flex-wrap mb-30'>
                        <button
                          className={formik.values.amount === 800 && 'active'}
                          type='button'
                          onClick={() => {
                            formik.setFieldValue('amount', 800);
                            formik.setFieldValue('other_type', false);
                          }}
                        >
                          {formik.values.currency}800
                        </button>
                        <button
                          className={formik.values.amount === 2000 && 'active'}
                          onClick={() => {
                            formik.setFieldValue('amount', 2000);
                            formik.setFieldValue('other_type', false);
                          }}
                          type='button'
                        >
                          {formik.values.currency}2000
                        </button>
                        <button
                          type='button'
                          className={formik.values.amount === 5000 && 'active'}
                          onClick={() => {
                            formik.setFieldValue('amount', 5000);
                            formik.setFieldValue('other_type', false);
                          }}
                        >
                          {formik.values.currency}5000
                        </button>
                        <button
                          className={formik.values.other_type && 'active'}
                          type='button'
                          onClick={() => {
                            formik.setFieldValue('other_type', true);
                            formik.setFieldValue('amount', null);
                          }}
                        >
                          Other{' '}
                        </button>
                      </div>
                    </div>
                  )}
                {formik.values.other_type && (
                  <div className='col-md-12'>
                    <TextInput
                      placeholder='Amount'
                      name='amount'
                      type='number'
                      min={1}
                    />
                  </div>
                )}
                {formik.values.citizen_type === 'INDIAN' && (
                  <div className='row'>
                    <div className='col-md-6'>
                      <TextInput placeholder='Full Name' name='name' />
                    </div>
                    <div className='col-md-6'>
                      <TextInput
                        placeholder='Phone'
                        name='phone'
                        type='number'
                      />
                    </div>
                    <div className='col-md-6'>
                      <TextInput placeholder='Email' name='email' />
                    </div>

                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Flat/ House no./ Floor/ Tower/ Block/ Building'
                        name='user_address'
                      />
                    </div> */}
                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter Street/ Society/ Locality/ Colony'
                        name='user_address1'
                      />
                    </div> */}
                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter Pincode'
                        name='user_pincode'
                      />
                    </div> */}
                    <div className='col-md-6'>
                      <TextInput placeholder='Enter City' name='user_city' />
                    </div>
                    {/* <div className='col-md-6'>
                      <TextInput placeholder='Enter State' name='user_state' />
                    </div> */}

                    <div className='col-md-6'>
                      <SelectBox placeholder='Country' name='user_country'>
                        <option value=''>Select Country</option>
                        <option value='Afganistan'>Afghanistan</option>
                        <option value='Albania'>Albania</option>
                        <option value='Algeria'>Algeria</option>
                        <option value='American Samoa'>American Samoa</option>
                        <option value='Andorra'>Andorra</option>
                        <option value='Angola'>Angola</option>
                        <option value='Anguilla'>Anguilla</option>
                        <option value='Antigua &amp; Barbuda'>
                          Antigua &amp; Barbuda
                        </option>
                        <option value='Argentina'>Argentina</option>
                        <option value='Armenia'>Armenia</option>
                        <option value='Aruba'>Aruba</option>
                        <option value='Australia'>Australia</option>
                        <option value='Austria'>Austria</option>
                        <option value='Azerbaijan'>Azerbaijan</option>
                        <option value='Bahamas'>Bahamas</option>
                        <option value='Bahrain'>Bahrain</option>
                        <option value='Bangladesh'>Bangladesh</option>
                        <option value='Barbados'>Barbados</option>
                        <option value='Belarus'>Belarus</option>
                        <option value='Belgium'>Belgium</option>
                        <option value='Belize'>Belize</option>
                        <option value='Benin'>Benin</option>
                        <option value='Bermuda'>Bermuda</option>
                        <option value='Bhutan'>Bhutan</option>
                        <option value='Bolivia'>Bolivia</option>
                        <option value='Bonaire'>Bonaire</option>
                        <option value='Bosnia &amp; Herzegovina'>
                          Bosnia &amp; Herzegovina
                        </option>
                        <option value='Botswana'>Botswana</option>
                        <option value='Brazil'>Brazil</option>
                        <option value='British Indian Ocean Ter'>
                          British Indian Ocean Ter
                        </option>
                        <option value='Brunei'>Brunei</option>
                        <option value='Bulgaria'>Bulgaria</option>
                        <option value='Burkina Faso'>Burkina Faso</option>
                        <option value='Burundi'>Burundi</option>
                        <option value='Cambodia'>Cambodia</option>
                        <option value='Cameroon'>Cameroon</option>
                        <option value='Canada'>Canada</option>
                        <option value='Canary Islands'>Canary Islands</option>
                        <option value='Cape Verde'>Cape Verde</option>
                        <option value='Cayman Islands'>Cayman Islands</option>
                        <option value='Central African Republic'>
                          Central African Republic
                        </option>
                        <option value='Chad'>Chad</option>
                        <option value='Channel Islands'>Channel Islands</option>
                        <option value='Chile'>Chile</option>
                        <option value='China'>China</option>
                        <option value='Christmas Island'>
                          Christmas Island
                        </option>
                        <option value='Cocos Island'>Cocos Island</option>
                        <option value='Colombia'>Colombia</option>
                        <option value='Comoros'>Comoros</option>
                        <option value='Congo'>Congo</option>
                        <option value='Cook Islands'>Cook Islands</option>
                        <option value='Costa Rica'>Costa Rica</option>
                        <option value='Cote DIvoire'>Cote DIvoire</option>
                        <option value='Croatia'>Croatia</option>
                        <option value='Cuba'>Cuba</option>
                        <option value='Curaco'>Curacao</option>
                        <option value='Cyprus'>Cyprus</option>
                        <option value='Czech Republic'>Czech Republic</option>
                        <option value='Denmark'>Denmark</option>
                        <option value='Djibouti'>Djibouti</option>
                        <option value='Dominica'>Dominica</option>
                        <option value='Dominican Republic'>
                          Dominican Republic
                        </option>
                        <option value='East Timor'>East Timor</option>
                        <option value='Ecuador'>Ecuador</option>
                        <option value='Egypt'>Egypt</option>
                        <option value='El Salvador'>El Salvador</option>
                        <option value='Equatorial Guinea'>
                          Equatorial Guinea
                        </option>
                        <option value='Eritrea'>Eritrea</option>
                        <option value='Estonia'>Estonia</option>
                        <option value='Ethiopia'>Ethiopia</option>
                        <option value='Falkland Islands'>
                          Falkland Islands
                        </option>
                        <option value='Faroe Islands'>Faroe Islands</option>
                        <option value='Fiji'>Fiji</option>
                        <option value='Finland'>Finland</option>
                        <option value='France'>France</option>
                        <option value='French Guiana'>French Guiana</option>
                        <option value='French Polynesia'>
                          French Polynesia
                        </option>
                        <option value='French Southern Ter'>
                          French Southern Ter
                        </option>
                        <option value='Gabon'>Gabon</option>
                        <option value='Gambia'>Gambia</option>
                        <option value='Georgia'>Georgia</option>
                        <option value='Germany'>Germany</option>
                        <option value='Ghana'>Ghana</option>
                        <option value='Gibraltar'>Gibraltar</option>
                        <option value='Great Britain'>Great Britain</option>
                        <option value='Greece'>Greece</option>
                        <option value='Greenland'>Greenland</option>
                        <option value='Grenada'>Grenada</option>
                        <option value='Guadeloupe'>Guadeloupe</option>
                        <option value='Guam'>Guam</option>
                        <option value='Guatemala'>Guatemala</option>
                        <option value='Guinea'>Guinea</option>
                        <option value='Guyana'>Guyana</option>
                        <option value='Haiti'>Haiti</option>
                        <option value='Hawaii'>Hawaii</option>
                        <option value='Honduras'>Honduras</option>
                        <option value='Hong Kong'>Hong Kong</option>
                        <option value='Hungary'>Hungary</option>
                        <option value='Iceland'>Iceland</option>
                        <option value='Indonesia'>Indonesia</option>
                        <option value='India'>India</option>
                        <option value='Iran'>Iran</option>
                        <option value='Iraq'>Iraq</option>
                        <option value='Ireland'>Ireland</option>
                        <option value='Isle of Man'>Isle of Man</option>
                        <option value='Israel'>Israel</option>
                        <option value='Italy'>Italy</option>
                        <option value='Jamaica'>Jamaica</option>
                        <option value='Japan'>Japan</option>
                        <option value='Jordan'>Jordan</option>
                        <option value='Kazakhstan'>Kazakhstan</option>
                        <option value='Kenya'>Kenya</option>
                        <option value='Kiribati'>Kiribati</option>
                        <option value='Korea North'>Korea North</option>
                        <option value='Korea Sout'>Korea South</option>
                        <option value='Kuwait'>Kuwait</option>
                        <option value='Kyrgyzstan'>Kyrgyzstan</option>
                        <option value='Laos'>Laos</option>
                        <option value='Latvia'>Latvia</option>
                        <option value='Lebanon'>Lebanon</option>
                        <option value='Lesotho'>Lesotho</option>
                        <option value='Liberia'>Liberia</option>
                        <option value='Libya'>Libya</option>
                        <option value='Liechtenstein'>Liechtenstein</option>
                        <option value='Lithuania'>Lithuania</option>
                        <option value='Luxembourg'>Luxembourg</option>
                        <option value='Macau'>Macau</option>
                        <option value='Macedonia'>Macedonia</option>
                        <option value='Madagascar'>Madagascar</option>
                        <option value='Malaysia'>Malaysia</option>
                        <option value='Malawi'>Malawi</option>
                        <option value='Maldives'>Maldives</option>
                        <option value='Mali'>Mali</option>
                        <option value='Malta'>Malta</option>
                        <option value='Marshall Islands'>
                          Marshall Islands
                        </option>
                        <option value='Martinique'>Martinique</option>
                        <option value='Mauritania'>Mauritania</option>
                        <option value='Mauritius'>Mauritius</option>
                        <option value='Mayotte'>Mayotte</option>
                        <option value='Mexico'>Mexico</option>
                        <option value='Midway Islands'>Midway Islands</option>
                        <option value='Moldova'>Moldova</option>
                        <option value='Monaco'>Monaco</option>
                        <option value='Mongolia'>Mongolia</option>
                        <option value='Montserrat'>Montserrat</option>
                        <option value='Morocco'>Morocco</option>
                        <option value='Mozambique'>Mozambique</option>
                        <option value='Myanmar'>Myanmar</option>
                        <option value='Nambia'>Nambia</option>
                        <option value='Nauru'>Nauru</option>
                        <option value='Nepal'>Nepal</option>
                        <option value='Netherland Antilles'>
                          Netherland Antilles
                        </option>
                        <option value='Netherlands'>
                          Netherlands (Holland, Europe)
                        </option>
                        <option value='Nevis'>Nevis</option>
                        <option value='New Caledonia'>New Caledonia</option>
                        <option value='New Zealand'>New Zealand</option>
                        <option value='Nicaragua'>Nicaragua</option>
                        <option value='Niger'>Niger</option>
                        <option value='Nigeria'>Nigeria</option>
                        <option value='Niue'>Niue</option>
                        <option value='Norfolk Island'>Norfolk Island</option>
                        <option value='Norway'>Norway</option>
                        <option value='Oman'>Oman</option>
                        <option value='Pakistan'>Pakistan</option>
                        <option value='Palau Island'>Palau Island</option>
                        <option value='Palestine'>Palestine</option>
                        <option value='Panama'>Panama</option>
                        <option value='Papua New Guinea'>
                          Papua New Guinea
                        </option>
                        <option value='Paraguay'>Paraguay</option>
                        <option value='Peru'>Peru</option>
                        <option value='Phillipines'>Philippines</option>
                        <option value='Pitcairn Island'>Pitcairn Island</option>
                        <option value='Poland'>Poland</option>
                        <option value='Portugal'>Portugal</option>
                        <option value='Puerto Rico'>Puerto Rico</option>
                        <option value='Qatar'>Qatar</option>
                        <option value='Republic of Montenegro'>
                          Republic of Montenegro
                        </option>
                        <option value='Republic of Serbia'>
                          Republic of Serbia
                        </option>
                        <option value='Reunion'>Reunion</option>
                        <option value='Romania'>Romania</option>
                        <option value='Russia'>Russia</option>
                        <option value='Rwanda'>Rwanda</option>
                        <option value='St Barthelemy'>St Barthelemy</option>
                        <option value='St Eustatius'>St Eustatius</option>
                        <option value='St Helena'>St Helena</option>
                        <option value='St Kitts-Nevis'>St Kitts-Nevis</option>
                        <option value='St Lucia'>St Lucia</option>
                        <option value='St Maarten'>St Maarten</option>
                        <option value='St Pierre &amp; Miquelon'>
                          St Pierre &amp; Miquelon
                        </option>
                        <option value='St Vincent &amp; Grenadines'>
                          St Vincent &amp; Grenadines
                        </option>
                        <option value='Saipan'>Saipan</option>
                        <option value='Samoa'>Samoa</option>
                        <option value='Samoa American'>Samoa American</option>
                        <option value='San Marino'>San Marino</option>
                        <option value='Sao Tome &amp; Principe'>
                          Sao Tome &amp; Principe
                        </option>
                        <option value='Saudi Arabia'>Saudi Arabia</option>
                        <option value='Senegal'>Senegal</option>
                        <option value='Seychelles'>Seychelles</option>
                        <option value='Sierra Leone'>Sierra Leone</option>
                        <option value='Singapore'>Singapore</option>
                        <option value='Slovakia'>Slovakia</option>
                        <option value='Slovenia'>Slovenia</option>
                        <option value='Solomon Islands'>Solomon Islands</option>
                        <option value='Somalia'>Somalia</option>
                        <option value='South Africa'>South Africa</option>
                        <option value='Spain'>Spain</option>
                        <option value='Sri Lanka'>Sri Lanka</option>
                        <option value='Sudan'>Sudan</option>
                        <option value='Suriname'>Suriname</option>
                        <option value='Swaziland'>Swaziland</option>
                        <option value='Sweden'>Sweden</option>
                        <option value='Switzerland'>Switzerland</option>
                        <option value='Syria'>Syria</option>
                        <option value='Tahiti'>Tahiti</option>
                        <option value='Taiwan'>Taiwan</option>
                        <option value='Tajikistan'>Tajikistan</option>
                        <option value='Tanzania'>Tanzania</option>
                        <option value='Thailand'>Thailand</option>
                        <option value='Togo'>Togo</option>
                        <option value='Tokelau'>Tokelau</option>
                        <option value='Tonga'>Tonga</option>
                        <option value='Trinidad &amp; Tobago'>
                          Trinidad &amp; Tobago
                        </option>
                        <option value='Tunisia'>Tunisia</option>
                        <option value='Turkey'>Turkey</option>
                        <option value='Turkmenistan'>Turkmenistan</option>
                        <option value='Turks &amp; Caicos Is'>
                          Turks &amp; Caicos Is
                        </option>
                        <option value='Tuvalu'>Tuvalu</option>
                        <option value='Uganda'>Uganda</option>
                        <option value='United Kingdom'>United Kingdom</option>
                        <option value='Ukraine'>Ukraine</option>
                        <option value='United Arab Erimates'>
                          United Arab Emirates
                        </option>
                        <option value='United States of America'>
                          United States of America
                        </option>
                        <option value='Uraguay'>Uruguay</option>
                        <option value='Uzbekistan'>Uzbekistan</option>
                        <option value='Vanuatu'>Vanuatu</option>
                        <option value='Vatican City State'>
                          Vatican City State
                        </option>
                        <option value='Venezuela'>Venezuela</option>
                        <option value='Vietnam'>Vietnam</option>
                        <option value='Virgin Islands (Brit)'>
                          Virgin Islands (Brit)
                        </option>
                        <option value='Virgin Islands (USA)'>
                          Virgin Islands (USA)
                        </option>
                        <option value='Wake Island'>Wake Island</option>
                        <option value='Wallis &amp; Futana Is'>
                          Wallis &amp; Futana Is
                        </option>
                        <option value='Yemen'>Yemen</option>
                        <option value='Zaire'>Zaire</option>
                        <option value='Zambia'>Zambia</option>
                        <option value='Zimbabwe'>Zimbabwe</option>
                      </SelectBox>
                    </div>
                    <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter PAN number'
                        name='user_pan'
                        type='text'
                      />
                    </div>
                    {formik.values.citizen_type === 'INDIAN' && (
                      <div className='col-md-6'>
                        <CheckBox label='pan_chackbox' name='pan_chackbox'>
                          I wish to claim tax exemption against this donation.
                        </CheckBox>
                      </div>
                    )}
                    {formik.values.citizen_type === 'FOREIGN' && (
                      <div className='col-md-6'>
                        <TextInput
                          placeholder='Enter Passport Number'
                          name='user_passport'
                          type='text'
                        />
                      </div>
                    )}
                  </div>
                )}
                {formik.values.citizen_type === 'FOREIGN' && (
                  <div className='row'>
                    <div className='col-md-6'>
                      <TextInput placeholder='Full Name' name='name' />
                    </div>
                    <div className='col-md-6'>
                      <TextInput
                        placeholder='Phone'
                        name='phone'
                        type='number'
                      />
                    </div>
                    <div className='col-md-6'>
                      <TextInput placeholder='Email' name='email' />
                    </div>

                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Flat/ House no./ Floor/ Tower/ Block/ Building'
                        name='user_address'
                      />
                    </div> */}
                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter Street/ Society/ Locality/ Colony'
                        name='user_address1'
                      />
                    </div> */}
                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter Pincode'
                        name='user_pincode'
                      />
                    </div> */}
                    <div className='col-md-6'>
                      <TextInput placeholder='Enter City' name='user_city' />
                    </div>
                    {/* <div className='col-md-6'>
                      <TextInput placeholder='Enter State' name='user_state' />
                    </div> */}

                    <div className='col-md-6'>
                      <SelectBox placeholder='Country' name='user_country'>
                        <option value=''>Select Country</option>
                        <option value='Afganistan'>Afghanistan</option>
                        <option value='Albania'>Albania</option>
                        <option value='Algeria'>Algeria</option>
                        <option value='American Samoa'>American Samoa</option>
                        <option value='Andorra'>Andorra</option>
                        <option value='Angola'>Angola</option>
                        <option value='Anguilla'>Anguilla</option>
                        <option value='Antigua &amp; Barbuda'>
                          Antigua &amp; Barbuda
                        </option>
                        <option value='Argentina'>Argentina</option>
                        <option value='Armenia'>Armenia</option>
                        <option value='Aruba'>Aruba</option>
                        <option value='Australia'>Australia</option>
                        <option value='Austria'>Austria</option>
                        <option value='Azerbaijan'>Azerbaijan</option>
                        <option value='Bahamas'>Bahamas</option>
                        <option value='Bahrain'>Bahrain</option>
                        <option value='Bangladesh'>Bangladesh</option>
                        <option value='Barbados'>Barbados</option>
                        <option value='Belarus'>Belarus</option>
                        <option value='Belgium'>Belgium</option>
                        <option value='Belize'>Belize</option>
                        <option value='Benin'>Benin</option>
                        <option value='Bermuda'>Bermuda</option>
                        <option value='Bhutan'>Bhutan</option>
                        <option value='Bolivia'>Bolivia</option>
                        <option value='Bonaire'>Bonaire</option>
                        <option value='Bosnia &amp; Herzegovina'>
                          Bosnia &amp; Herzegovina
                        </option>
                        <option value='Botswana'>Botswana</option>
                        <option value='Brazil'>Brazil</option>
                        <option value='British Indian Ocean Ter'>
                          British Indian Ocean Ter
                        </option>
                        <option value='Brunei'>Brunei</option>
                        <option value='Bulgaria'>Bulgaria</option>
                        <option value='Burkina Faso'>Burkina Faso</option>
                        <option value='Burundi'>Burundi</option>
                        <option value='Cambodia'>Cambodia</option>
                        <option value='Cameroon'>Cameroon</option>
                        <option value='Canada'>Canada</option>
                        <option value='Canary Islands'>Canary Islands</option>
                        <option value='Cape Verde'>Cape Verde</option>
                        <option value='Cayman Islands'>Cayman Islands</option>
                        <option value='Central African Republic'>
                          Central African Republic
                        </option>
                        <option value='Chad'>Chad</option>
                        <option value='Channel Islands'>Channel Islands</option>
                        <option value='Chile'>Chile</option>
                        <option value='China'>China</option>
                        <option value='Christmas Island'>
                          Christmas Island
                        </option>
                        <option value='Cocos Island'>Cocos Island</option>
                        <option value='Colombia'>Colombia</option>
                        <option value='Comoros'>Comoros</option>
                        <option value='Congo'>Congo</option>
                        <option value='Cook Islands'>Cook Islands</option>
                        <option value='Costa Rica'>Costa Rica</option>
                        <option value='Cote DIvoire'>Cote DIvoire</option>
                        <option value='Croatia'>Croatia</option>
                        <option value='Cuba'>Cuba</option>
                        <option value='Curaco'>Curacao</option>
                        <option value='Cyprus'>Cyprus</option>
                        <option value='Czech Republic'>Czech Republic</option>
                        <option value='Denmark'>Denmark</option>
                        <option value='Djibouti'>Djibouti</option>
                        <option value='Dominica'>Dominica</option>
                        <option value='Dominican Republic'>
                          Dominican Republic
                        </option>
                        <option value='East Timor'>East Timor</option>
                        <option value='Ecuador'>Ecuador</option>
                        <option value='Egypt'>Egypt</option>
                        <option value='El Salvador'>El Salvador</option>
                        <option value='Equatorial Guinea'>
                          Equatorial Guinea
                        </option>
                        <option value='Eritrea'>Eritrea</option>
                        <option value='Estonia'>Estonia</option>
                        <option value='Ethiopia'>Ethiopia</option>
                        <option value='Falkland Islands'>
                          Falkland Islands
                        </option>
                        <option value='Faroe Islands'>Faroe Islands</option>
                        <option value='Fiji'>Fiji</option>
                        <option value='Finland'>Finland</option>
                        <option value='France'>France</option>
                        <option value='French Guiana'>French Guiana</option>
                        <option value='French Polynesia'>
                          French Polynesia
                        </option>
                        <option value='French Southern Ter'>
                          French Southern Ter
                        </option>
                        <option value='Gabon'>Gabon</option>
                        <option value='Gambia'>Gambia</option>
                        <option value='Georgia'>Georgia</option>
                        <option value='Germany'>Germany</option>
                        <option value='Ghana'>Ghana</option>
                        <option value='Gibraltar'>Gibraltar</option>
                        <option value='Great Britain'>Great Britain</option>
                        <option value='Greece'>Greece</option>
                        <option value='Greenland'>Greenland</option>
                        <option value='Grenada'>Grenada</option>
                        <option value='Guadeloupe'>Guadeloupe</option>
                        <option value='Guam'>Guam</option>
                        <option value='Guatemala'>Guatemala</option>
                        <option value='Guinea'>Guinea</option>
                        <option value='Guyana'>Guyana</option>
                        <option value='Haiti'>Haiti</option>
                        <option value='Hawaii'>Hawaii</option>
                        <option value='Honduras'>Honduras</option>
                        <option value='Hong Kong'>Hong Kong</option>
                        <option value='Hungary'>Hungary</option>
                        <option value='Iceland'>Iceland</option>
                        <option value='Indonesia'>Indonesia</option>
                        <option value='India'>India</option>
                        <option value='Iran'>Iran</option>
                        <option value='Iraq'>Iraq</option>
                        <option value='Ireland'>Ireland</option>
                        <option value='Isle of Man'>Isle of Man</option>
                        <option value='Israel'>Israel</option>
                        <option value='Italy'>Italy</option>
                        <option value='Jamaica'>Jamaica</option>
                        <option value='Japan'>Japan</option>
                        <option value='Jordan'>Jordan</option>
                        <option value='Kazakhstan'>Kazakhstan</option>
                        <option value='Kenya'>Kenya</option>
                        <option value='Kiribati'>Kiribati</option>
                        <option value='Korea North'>Korea North</option>
                        <option value='Korea Sout'>Korea South</option>
                        <option value='Kuwait'>Kuwait</option>
                        <option value='Kyrgyzstan'>Kyrgyzstan</option>
                        <option value='Laos'>Laos</option>
                        <option value='Latvia'>Latvia</option>
                        <option value='Lebanon'>Lebanon</option>
                        <option value='Lesotho'>Lesotho</option>
                        <option value='Liberia'>Liberia</option>
                        <option value='Libya'>Libya</option>
                        <option value='Liechtenstein'>Liechtenstein</option>
                        <option value='Lithuania'>Lithuania</option>
                        <option value='Luxembourg'>Luxembourg</option>
                        <option value='Macau'>Macau</option>
                        <option value='Macedonia'>Macedonia</option>
                        <option value='Madagascar'>Madagascar</option>
                        <option value='Malaysia'>Malaysia</option>
                        <option value='Malawi'>Malawi</option>
                        <option value='Maldives'>Maldives</option>
                        <option value='Mali'>Mali</option>
                        <option value='Malta'>Malta</option>
                        <option value='Marshall Islands'>
                          Marshall Islands
                        </option>
                        <option value='Martinique'>Martinique</option>
                        <option value='Mauritania'>Mauritania</option>
                        <option value='Mauritius'>Mauritius</option>
                        <option value='Mayotte'>Mayotte</option>
                        <option value='Mexico'>Mexico</option>
                        <option value='Midway Islands'>Midway Islands</option>
                        <option value='Moldova'>Moldova</option>
                        <option value='Monaco'>Monaco</option>
                        <option value='Mongolia'>Mongolia</option>
                        <option value='Montserrat'>Montserrat</option>
                        <option value='Morocco'>Morocco</option>
                        <option value='Mozambique'>Mozambique</option>
                        <option value='Myanmar'>Myanmar</option>
                        <option value='Nambia'>Nambia</option>
                        <option value='Nauru'>Nauru</option>
                        <option value='Nepal'>Nepal</option>
                        <option value='Netherland Antilles'>
                          Netherland Antilles
                        </option>
                        <option value='Netherlands'>
                          Netherlands (Holland, Europe)
                        </option>
                        <option value='Nevis'>Nevis</option>
                        <option value='New Caledonia'>New Caledonia</option>
                        <option value='New Zealand'>New Zealand</option>
                        <option value='Nicaragua'>Nicaragua</option>
                        <option value='Niger'>Niger</option>
                        <option value='Nigeria'>Nigeria</option>
                        <option value='Niue'>Niue</option>
                        <option value='Norfolk Island'>Norfolk Island</option>
                        <option value='Norway'>Norway</option>
                        <option value='Oman'>Oman</option>
                        <option value='Pakistan'>Pakistan</option>
                        <option value='Palau Island'>Palau Island</option>
                        <option value='Palestine'>Palestine</option>
                        <option value='Panama'>Panama</option>
                        <option value='Papua New Guinea'>
                          Papua New Guinea
                        </option>
                        <option value='Paraguay'>Paraguay</option>
                        <option value='Peru'>Peru</option>
                        <option value='Phillipines'>Philippines</option>
                        <option value='Pitcairn Island'>Pitcairn Island</option>
                        <option value='Poland'>Poland</option>
                        <option value='Portugal'>Portugal</option>
                        <option value='Puerto Rico'>Puerto Rico</option>
                        <option value='Qatar'>Qatar</option>
                        <option value='Republic of Montenegro'>
                          Republic of Montenegro
                        </option>
                        <option value='Republic of Serbia'>
                          Republic of Serbia
                        </option>
                        <option value='Reunion'>Reunion</option>
                        <option value='Romania'>Romania</option>
                        <option value='Russia'>Russia</option>
                        <option value='Rwanda'>Rwanda</option>
                        <option value='St Barthelemy'>St Barthelemy</option>
                        <option value='St Eustatius'>St Eustatius</option>
                        <option value='St Helena'>St Helena</option>
                        <option value='St Kitts-Nevis'>St Kitts-Nevis</option>
                        <option value='St Lucia'>St Lucia</option>
                        <option value='St Maarten'>St Maarten</option>
                        <option value='St Pierre &amp; Miquelon'>
                          St Pierre &amp; Miquelon
                        </option>
                        <option value='St Vincent &amp; Grenadines'>
                          St Vincent &amp; Grenadines
                        </option>
                        <option value='Saipan'>Saipan</option>
                        <option value='Samoa'>Samoa</option>
                        <option value='Samoa American'>Samoa American</option>
                        <option value='San Marino'>San Marino</option>
                        <option value='Sao Tome &amp; Principe'>
                          Sao Tome &amp; Principe
                        </option>
                        <option value='Saudi Arabia'>Saudi Arabia</option>
                        <option value='Senegal'>Senegal</option>
                        <option value='Seychelles'>Seychelles</option>
                        <option value='Sierra Leone'>Sierra Leone</option>
                        <option value='Singapore'>Singapore</option>
                        <option value='Slovakia'>Slovakia</option>
                        <option value='Slovenia'>Slovenia</option>
                        <option value='Solomon Islands'>Solomon Islands</option>
                        <option value='Somalia'>Somalia</option>
                        <option value='South Africa'>South Africa</option>
                        <option value='Spain'>Spain</option>
                        <option value='Sri Lanka'>Sri Lanka</option>
                        <option value='Sudan'>Sudan</option>
                        <option value='Suriname'>Suriname</option>
                        <option value='Swaziland'>Swaziland</option>
                        <option value='Sweden'>Sweden</option>
                        <option value='Switzerland'>Switzerland</option>
                        <option value='Syria'>Syria</option>
                        <option value='Tahiti'>Tahiti</option>
                        <option value='Taiwan'>Taiwan</option>
                        <option value='Tajikistan'>Tajikistan</option>
                        <option value='Tanzania'>Tanzania</option>
                        <option value='Thailand'>Thailand</option>
                        <option value='Togo'>Togo</option>
                        <option value='Tokelau'>Tokelau</option>
                        <option value='Tonga'>Tonga</option>
                        <option value='Trinidad &amp; Tobago'>
                          Trinidad &amp; Tobago
                        </option>
                        <option value='Tunisia'>Tunisia</option>
                        <option value='Turkey'>Turkey</option>
                        <option value='Turkmenistan'>Turkmenistan</option>
                        <option value='Turks &amp; Caicos Is'>
                          Turks &amp; Caicos Is
                        </option>
                        <option value='Tuvalu'>Tuvalu</option>
                        <option value='Uganda'>Uganda</option>
                        <option value='United Kingdom'>United Kingdom</option>
                        <option value='Ukraine'>Ukraine</option>
                        <option value='United Arab Erimates'>
                          United Arab Emirates
                        </option>
                        <option value='United States of America'>
                          United States of America
                        </option>
                        <option value='Uraguay'>Uruguay</option>
                        <option value='Uzbekistan'>Uzbekistan</option>
                        <option value='Vanuatu'>Vanuatu</option>
                        <option value='Vatican City State'>
                          Vatican City State
                        </option>
                        <option value='Venezuela'>Venezuela</option>
                        <option value='Vietnam'>Vietnam</option>
                        <option value='Virgin Islands (Brit)'>
                          Virgin Islands (Brit)
                        </option>
                        <option value='Virgin Islands (USA)'>
                          Virgin Islands (USA)
                        </option>
                        <option value='Wake Island'>Wake Island</option>
                        <option value='Wallis &amp; Futana Is'>
                          Wallis &amp; Futana Is
                        </option>
                        <option value='Yemen'>Yemen</option>
                        <option value='Zaire'>Zaire</option>
                        <option value='Zambia'>Zambia</option>
                        <option value='Zimbabwe'>Zimbabwe</option>
                      </SelectBox>
                    </div>
                    {/* <div className='col-md-6'>
                      <TextInput
                        placeholder='Enter PAN number'
                        name='user_pan'
                        type='text'
                      />
                    </div> */}
                    {formik.values.citizen_type === 'INDIAN' && (
                      <div className='col-md-6'>
                        <CheckBox label='pan_chackbox' name='pan_chackbox'>
                          I wish to claim tax exemption against this donation.
                        </CheckBox>
                      </div>
                    )}
                    {formik.values.citizen_type === 'FOREIGN' && (
                      <div className='col-md-6'>
                        <TextInput
                          placeholder='Enter Passport Number'
                          name='user_passport'
                          type='text'
                        />
                      </div>
                    )}
                  </div>
                )}
                {formik.values.citizen_type === 'INDIAN' && (
                  <div className='row'>
                    <div className='col-md-12 mt-5'>
                      <div className='flex-wrapper' style={{ gap: '30px' }}>
                        <div>
                          <button
                            className='tp-btn tp-btn-shine-effect'
                            type='submit'
                            onClick={() => {
                              formik.setFieldValue(
                                'payment_method',
                                'razorpay'
                              );
                              formik.handleSubmit();
                            }}
                          >
                            Secure Online Payment
                          </button>
                          <p style={{ fontSize: '14px' }}>
                            Online payment like Credit Card/ Debit <br />
                            Card/Netbanking/ <br /> Wallets/UPI & More
                          </p>
                        </div>
                        {formik.values.citizen_type === 'INDIAN' && (
                          <div>
                            <button
                              className='tp-btn tp-btn-shine-effect'
                              type='submit'
                              onClick={() => {
                                formik.setFieldValue('payment_method', 'gpay');
                                formik.handleSubmit();
                              }}
                            >
                              Google Pay
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {formik.values.citizen_type === 'FOREIGN' && (
                  <div>
                    <button
                      type='submit'
                      // href='https://www.every.org/seeds#/donate/card '
                      className='tp-btn tp-btn-shine-effect'
                    >
                      Secure Online Payment
                    </button>
                    <br />
                    <button
                      type='submit'
                      // href='https://www.every.org/seeds#/donate/card'
                    >
                      <img
                        src='/images/every_org.jpg'
                        style={{
                          width: '200px',
                          height: '70px',
                          objectFit: 'contain',
                          marginTop: '20px',
                        }}
                      />
                    </button>
                  </div>
                )}
                {formik.values.amount && (
                  <div>
                    <p>
                      {' '}
                      You are donating{' '}
                      {formik.values.citizen_type === 'INDIAN'
                        ? '₹'
                        : formik.values.currency}{' '}
                      {formik.values.amount}{' '}
                    </p>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>{' '}
      </div>
      <div>
        <p className='terms'>
          SEEDS is registered under Societies Registration Act XXI of 1860. All
          donations made to SEEDS are eligible for Tax Deduction under section
          80G of the India Income Tax Act 1961.
        </p>
        <p className='terms'>
          As per Income Tax guidelines, organisations will have to mandatorily
          submit, contributors must provide their valid complete address and
          Permanent Account Number (PAN) number.
        </p>
      </div>
    </div>
  );
}

export default DonationForm;
