import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import BreadCrumb from '../../components/common/BreadCrumb';

function ResourceForChildren() {
  return (
    <div>
      <Header />
      <BreadCrumb title={'Resource For Children'} sub_title={'What We Do'} />
      <section
        className='tpblog-area  children-resource pt-50 pb-50'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <a
                href='/uploads/2020/08/Small-acts-Big-Impacts.jpg'
                target='_blank'
              >
                <img src='/uploads/2020/08/Small-acts-Big-Impacts.jpg' />
              </a>

              <h3>Small Act, Big Impacts booklet</h3>
              <p>
                This comic book looks at climate change through the eyes of
                animal characters- camel and yak.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/07/comic2.jpg' target='_blank'>
                <img src='/uploads/2020/07/comic2.jpg' />
              </a>
              <h3>Risk Ready champion</h3>
              <p>
                A complete learning kit for children, the set of guide comprises
                of fun activity learning items for children like hazard hunt
                card games, 3R’s Junction- Reduce, Reuse, Recycle, children’s
                charter booklet and a fun learning poster to educate children on
                environmental safety and hazards around them.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/08/cardgames.jpg' target='_blank'>
                <img src='/uploads/2020/08/cardgames.jpg' />
              </a>
              <h3>Card Games</h3>
              <p>
                A vital part of Risk Ready Champion kit, the card games are the
                best way of teaching your children about earthquake, road safety
                and fire safety in the best possible and fun learning way! More
                card games based on flood safety, climate change and Tsunami is
                also included.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/08/comic.jpg' target='_blank'>
                <img src='/uploads/2020/08/comic.jpg' />
              </a>
              <h3>Safety Junctions</h3>
              <p>
                Making children aware about hazards and potential risks and
                threats around them at an early age might be difficult! Safety
                junctions based on Water and Sanitation Hygiene (WaSH), road
                safety, school safety, earthquake, tsunami, landslide etc are
                some of the detailed and creative learning modules for children
                that can be read anytime, anywhere.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/08/monoploly.jpg' target='_blank'>
                <img src='/uploads/2020/08/monoploly.jpg' />
              </a>
              <h3>Play Safe Board Game</h3>
              <p>
                A healthy and family fun loving board game in which children can
                engage in simulated financial dealings- purchasing safety items,
                investing in safety kits, securing finances during emergency
                etc, using imitation money.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/08/whoisthechamp.jpg' target='_blank'>
                <img src='/uploads/2020/08/whoisthechamp.jpg' />
              </a>
              <h3>Who’s the Champ</h3>
              <p>
                Spin the wheel and discover the ecostar in you. Learn more about
                the ecological components- waste, water and energy and how to
                develop a sustainable environment around you.
              </p>
            </div>
            <div className='col-md-4'>
              <a href='/uploads/2020/08/comicbook.jpg' target='_blank'>
                <img src='/uploads/2020/08/comicbook.jpg' />
              </a>
              <h3>Comic Book</h3>
              <p>
                A complete graphic journey for children and their interaction
                with nature.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ResourceForChildren;
